import React, { useCallback, useMemo } from 'react'
import { Form, Field } from 'react-final-form'

import { isPlaysomeUserId } from 'functions/validation'
import TextInput from 'components/ui/TextInput'
import { LoginIcon } from 'components/ui/icons'
import Text from '../ui/Text'

const PlaysomeLoginForm = props => {
  const { lastUserId, onLogin, tabIndex = 1, ...otherProps } = props

  const initialValues = useMemo(
    () => ({
      userId: lastUserId || '',
    }),
    []
  )

  const validate = useCallback(({ userId }) => {
    const errors = {}
    if (!userId || !isPlaysomeUserId(userId)) errors.userId = 'playsomeUserIdExpectedError'
    return errors
  }, [])

  return (
    <Form
      onSubmit={onLogin}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <form {...otherProps} onSubmit={handleSubmit}>
          <Field
            name="userId"
            render={({ input, meta }) => (
              <TextInput
                {...input}
                className="input--large"
                label={<Text>general.log_in_anonymously_with_a_nickname</Text>}
                autoFocus={true}
                tabIndex={tabIndex}
                disabled={submitting}
                error={meta.dirty && meta.invalid ? `validation.${meta.error}` : null}
                button={
                  <button
                    type="submit"
                    className="button button--primary button--icon"
                    disabled={meta.invalid || submitting}
                    tabIndex={tabIndex}
                  >
                    <LoginIcon className="Icon" data-test="joinPartySubmitButton" />
                  </button>
                }
              />
            )}
          />
        </form>
      )}
    />
  )
}

export default PlaysomeLoginForm
