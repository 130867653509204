import React from 'react'

import { DependencyContext } from './Context'
import Routes from './Routes'
import ErrorBoundary from './common/ErrorBoundary'
import ErrorMessage from './common/ErrorMessage'

const App = props => {
  const { analytics, history, logger } = props

  const onError = error => {
    logger.log('ErrorBoundary.onError', error)
    analytics.exception(error)
  }

  return (
    <DependencyContext.Provider value={props}>
      <ErrorBoundary onError={onError} fallback={<ErrorMessage goBack={() => history.replace('/party')} />}>
        <Routes history={history} />
      </ErrorBoundary>
    </DependencyContext.Provider>
  )
}

export default App
