import md5 from 'blueimp-md5'
import isObject from 'lodash/isObject'

export default class Cache {
  STORE_PREFIX = 'cache.'

  constructor(store) {
    this.store = store
  }

  get(key) {
    const storeKey = this.getStoreKey(key)
    const cache = this.store.getItem(storeKey)
    if (cache === null) return null
    if (cache.timeout < Date.now()) {
      this.clear(storeKey)
      return null
    }
    return cache.data
  }

  put(key, time, data) {
    this.store.setItem(this.getStoreKey(key), {
      timeout: Date.now() + time,
      data,
    })
    return this
  }

  clear(key) {
    if (key === undefined) {
      this.store
        .getKeys()
        .filter(key => key.startsWith(this.STORE_PREFIX))
        .forEach(key => this.store.removeItem(key))
    } else {
      this.store.removeItem(this.getStoreKey(key))
    }
    return this
  }

  clearExpired(key) {
    let keys
    if (key === undefined) {
      keys = this.store.getKeys()
    } else if (Array.isArray(key)) {
      keys = key
    } else {
      keys = [key]
    }
    const now = Date.now()
    keys
      .filter(key => key.startsWith(this.STORE_PREFIX))
      .forEach(key => {
        const cache = this.store.getItem(key)
        if (cache.timeout < now) {
          this.store.removeItem(key)
        }
      })
    return this
  }

  getStoreKey(key) {
    let keyString
    if (isObject(key)) {
      keyString = JSON.stringify(key)
    } else {
      keyString = key.toString()
    }
    return this.STORE_PREFIX + md5(keyString)
  }
}
