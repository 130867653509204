import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isPlainObject } from 'lodash/lang'

import { ChevronUpIcon, ChevronDownIcon } from 'components/ui/icons'

const SELECT_POSITIONS = {
  ABOVE: 'ABOVE',
  BELOW: 'BELOW',
}

const Select = props => {
  const {
    className,
    containerId,
    disabled,
    inputClassName,
    name,
    onChange,
    options,
    placeholder,
    position,
    style,
    tabIndex,
    value,
    visibleItems,
  } = props
  const [formattedOptions, setFormattedOptions] = useState(formatOptions(options))
  const [highlighted, setHighlighted] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState({
    value: value && formattedOptions.has(value) ? value : '',
    label: value && formattedOptions.has(value) ? formattedOptions.get(value) : '',
  })
  const inputElementRef = useRef()
  const listElementRef = useRef()

  useEffect(() => {
    const newFormattedOptions = formatOptions(options)
    const hasValue = value && newFormattedOptions.has(value)
    setFormattedOptions(newFormattedOptions)
    setSelected({
      value: hasValue ? value : '',
      label: hasValue ? newFormattedOptions.get(value) : '',
    })
  }, [options])

  const highlightOption = useCallback((index, scroll = true) => {
    setHighlighted(index)
    if (scroll) {
      /** @type HTMLElement */
      const listElement = listElementRef.current
      const listItemElement = listElement.children[index]
      if (listItemElement.offsetTop + listItemElement.offsetHeight > listElement.scrollTop + listElement.clientHeight) {
        listElement.scrollTop = listItemElement.offsetTop + listItemElement.offsetHeight - listItemElement.offsetHeight
      }
      if (listElement.scrollTop > listItemElement.offsetTop) {
        listElement.scrollTop = listItemElement.offsetTop
      }
    }
  }, [])

  const selectOption = useCallback(
    optionValue => {
      /** @type HTMLElement */
      const inputElement = inputElementRef.current
      const hasValue = optionValue && formattedOptions.has(optionValue)
      setSelected({
        value: hasValue ? optionValue : '',
        label: hasValue ? formattedOptions.get(optionValue) : '',
      })
      if (onChange) {
        onChange({
          type: 'change',
          target: {
            name,
            value: optionValue,
          },
        })
      }
      inputElement.blur()
    },
    [onChange]
  )

  const getListHeight = useCallback(count => {
    /** @type HTMLElement */
    const listElement = listElementRef.current
    if (!listElement || count === undefined || parseInt(count, 10) <= 0) return 'auto'
    let height = 0
    for (let c = 0, cl = Math.min(count - 1, listElement.children.length - 1); c <= cl; c++) {
      height += listElement.children[c].clientHeight
    }
    return height
  }, [])

  const handleKey = useCallback(
    event => {
      /** @type HTMLElement */
      const inputElement = inputElementRef.current
      switch (event.key) {
        case 'ArrowDown':
          event.stopPropagation()
          highlightOption(highlighted < formattedOptions.size - 1 ? highlighted + 1 : 0)
          break
        case 'ArrowUp':
          event.stopPropagation()
          highlightOption(highlighted > 0 ? highlighted - 1 : formattedOptions.size - 1)
          break
        case 'Enter':
          event.stopPropagation()
          selectOption(Array.from(formattedOptions.keys())[highlighted])
          inputElement.blur()
          break
        case 'Escape':
          event.stopPropagation()
          inputElement.blur()
          break
      }
    },
    [formattedOptions, highlighted]
  )

  const handleFocusBlur = useCallback(event => {
    setIsOpen(event.type === 'focus')
  }, [])

  const containerClassname = classNames([
    'Select',
    position === SELECT_POSITIONS.ABOVE ? 'Select--above' : null,
    disabled ? 'Select--disabled' : null,
    className,
  ])
  const listStyle = { height: getListHeight(visibleItems) }

  return (
    <div id={containerId} className={containerClassname}>
      <input type="hidden" name={name} disabled={disabled} value={selected.value} onChange={noop} />
      {isOpen ? <ChevronUpIcon className="SelectInput__icon" /> : <ChevronDownIcon className="SelectInput__icon" />}
      <input
        type="text"
        readOnly
        disabled={disabled}
        placeholder={placeholder}
        tabIndex={tabIndex}
        className={classNames('SelectInput', inputClassName)}
        style={style}
        value={selected.label}
        onChange={noop}
        onKeyDown={handleKey}
        onFocus={handleFocusBlur}
        onBlur={handleFocusBlur}
        ref={inputElementRef}
      />
      <ul tabIndex="-1" className="SelectList" style={listStyle} onMouseDown={preventDefault} ref={listElementRef}>
        {Array.from(formattedOptions.entries()).map(([key, option], index) => (
          <li
            key={key}
            tabIndex="-1"
            className={classNames({
              SelectList__item: true,
              'SelectList__item--highlighted': highlighted === index,
              'SelectList__item--selected': selected.value === key,
            })}
            onMouseOver={() => highlightOption(index, false)}
            onMouseDown={() => selectOption(key)}
            data-option={option}
          >
            <span>{option}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  containerId: PropTypes.string,
  inputClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.instanceOf(Map)]).isRequired,
  placeholder: PropTypes.string,
  position: PropTypes.oneOf(Object.keys(SELECT_POSITIONS)),
  style: PropTypes.object,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.string,
  visibleItems: PropTypes.number,
}
Select.defaultProps = {
  className: '',
  disabled: false,
  inputClassName: '',
  options: [],
  placeholder: '',
  position: SELECT_POSITIONS.BELOW,
}

const formatOptions = options => {
  if (options instanceof Map) {
    return options
  } else if (Array.isArray(options)) {
    return new Map(options.map(value => [value, value]))
  } else if (isPlainObject(options)) {
    return new Map(Object.entries(options))
  }
}

const noop = () => {}

const preventDefault = event => event.preventDefault()

export { Select as default, SELECT_POSITIONS }
