import { makeAutoObservable, observable } from 'mobx'

export default class Popup {
  visible
  size
  component
  currentId

  constructor() {
    makeAutoObservable(this, {
      component: observable.ref,
    })
  }

  show = opts => {
    const options = {
      size: null,
      scrolling: true,
      component: undefined,
      id: null,
      ...opts,
    }
    this.visible = true
    this.size = options.size
    this.scrolling = options.scrolling
    this.component = options.component
    this.currentId = options.id
    return this
  }

  hide = () => {
    this.visible = false
    this.size = null
    this.scrolling = true
    this.component = null
    this.currentId = null
    return this
  }
}
