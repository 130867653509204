import React, { Fragment, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { popupSizes } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import Page from 'components/common/Page'
import TrackList from 'components/common/PlaylistDisplay'
import {
  EmptyMasterPlaylistBackdrop,
  WaitingToHostPartyBackdrop,
  WaitingToJoinPartyBackdrop,
} from 'components/common/PageBackdrops'
import PartyJoinPopup from 'components/common/popups/PartyJoinPopup'
import PartyHostPopup from 'components/common/popups/PartyHostPopup'
import PlaylistExportPopup from 'components/common/popups/PlaylistExportPopup'
import TrackPopup from 'components/common/popups/TrackPopup'
import PartyToolbar from 'components/common/PartyToolbar'
import { CloudUploadIcon } from 'components/ui/icons'

const PartyPlaylist = props => {
  const { app, auth, masterPlaylist, party, popup } = props

  const showJoinPartyPopup = () => {
    popup.show({
      component: <PartyJoinPopup />,
      size: popupSizes.small,
    })
  }

  const showHostPartyPopup = () => {
    popup.show({
      component: <PartyHostPopup />,
    })
  }

  const showTrackPopup = useCallback(
    track => {
      popup.show({
        component: <TrackPopup track={track} playlist={masterPlaylist} isHost={party.isHosted} />,
      })
    },
    [masterPlaylist, party.isHosted, popup]
  )

  const showExportPlaylistPopup = () => {
    popup.show({
      component: <PlaylistExportPopup />,
    })
  }

  return (
    <Page contentClassName="l-rows" toolbar={<PartyToolbar />} data-test="Page_party">
      {party.isJoined ? (
        <Fragment>
          {masterPlaylist.length > 0 ? (
            <Fragment>
              <TrackList hasControls={false} onClick={showTrackPopup} playlist={masterPlaylist} />
              {auth.canImportExportPlaylist && (
                <button
                  className={classNames(
                    'animated animated--zoomIn animated--pauseOnFocus button-action button--secondary',
                    app.showPlayerControls && 'button-action--withControls'
                  )}
                  onClick={showExportPlaylistPopup}
                >
                  <CloudUploadIcon className="Icon" />
                </button>
              )}
            </Fragment>
          ) : (
            <EmptyMasterPlaylistBackdrop />
          )}
        </Fragment>
      ) : (
        <div className="l-flex l-rows l-columns--landscape--sm">
          {auth.canHostParty && <WaitingToHostPartyBackdrop onClick={showHostPartyPopup} />}
          <WaitingToJoinPartyBackdrop onClick={showJoinPartyPopup} />
        </div>
      )}
    </Page>
  )
}

export default withDependencies(
  dependencies.app,
  dependencies.auth,
  dependencies.masterPlaylist,
  dependencies.party,
  dependencies.popup
)(observer(PartyPlaylist))
