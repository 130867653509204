import React, { Fragment, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Field, Form } from 'react-final-form'

import { alerts, serverEvents } from 'config/enums'
import logger from 'services/logger'
import { isPartyId, isPartyPassword } from 'functions/validation'
import { withDependencies, dependencies } from 'components/Context'
import Text from 'components/ui/Text'
import TextInput from 'components/ui/TextInput'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'

const LAST_PARTY_ID_KEY = 'party.lastPartyId'
const LAST_PARTY_PASSWORD_KEY = 'party.lastPartyPassword'

const PartyJoinPopup = props => {
  const { alert, analytics, app, popup, store } = props

  const initialValues = useMemo(
    () => ({
      partyId: store.getItem(LAST_PARTY_ID_KEY) || '',
      partyPassword: store.getItem(LAST_PARTY_PASSWORD_KEY) || '',
    }),
    []
  )

  const validate = useCallback(({ partyId, partyPassword }) => {
    const errors = {}
    if (!partyId || !isPartyId(partyId)) errors.partyId = 'partyIdExpectedError'
    if (partyPassword && !isPartyPassword(partyPassword)) errors.partyPassword = 'partyPasswordExpectedError'
    return errors
  }, [])

  const onJoinParty = values => {
    logger.log('PartyJoinPopup.handleJoinParty', values)

    return app
      .joinParty(values.partyId, values.partyPassword)
      .then(() => {
        popup.hide()
      })
      .then(() => {
        store.setItem(LAST_PARTY_ID_KEY, values.partyId)
        store.setItem(LAST_PARTY_PASSWORD_KEY, values.partyPassword)
      })
      .then(() => {
        analytics.events.joinParty()
      })
      .catch(error => {
        if (error === serverEvents.userNotFoundError) {
          popup.hide()
          app.logout()
        }
        alert.show(alerts.error, 'alert.' + error)
      })
  }

  return (
    <PopupContainer data-test="JoinPartyPopup">
      <Form
        onSubmit={onJoinParty}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitting, valid }) => (
          <Fragment>
            <PopupHeader onHide={popup.hide}>
              <Text className="l-margins--h">general.join_party</Text>
            </PopupHeader>
            <PopupContent>
              <form className="l-rows l-margins" onSubmit={handleSubmit}>
                <Field
                  name="partyId"
                  render={({ input, meta }) => (
                    <TextInput
                      containerClassName="l-margin--b"
                      label={<Text>general.party_name</Text>}
                      {...input}
                      autoFocus={true}
                      tabIndex={2}
                      error={meta.dirty && meta.invalid ? `validation.${meta.error}` : null}
                    />
                  )}
                />
                <Field
                  name="partyPassword"
                  render={({ input, meta }) => (
                    <TextInput
                      label={<Text>general.party_password_optional</Text>}
                      {...input}
                      tabIndex={3}
                      error={meta.dirty && meta.invalid ? `validation.${meta.error}` : null}
                    />
                  )}
                />
                <input type="submit" style={{ visibility: 'hidden' }} />
              </form>
            </PopupContent>
            <PopupFooter className="l-columns">
              <button
                type="submit"
                className="l-flex button--hollow button--primary"
                tabIndex={4}
                disabled={!valid || submitting}
                onClick={handleSubmit}
                data-test="JoinPartyButton"
              >
                <Text>general.join</Text>
              </button>
              <button
                className="l-flex button--hollow button--secondary"
                tabIndex={5}
                onClick={popup.hide}
                disabled={submitting}
              >
                <Text>general.close</Text>
              </button>
            </PopupFooter>
          </Fragment>
        )}
      />
    </PopupContainer>
  )
}

export default withDependencies(
  dependencies.alert,
  dependencies.analytics,
  dependencies.app,
  dependencies.locale,
  dependencies.popup,
  dependencies.store
)(observer(PartyJoinPopup))
