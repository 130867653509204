import { reaction } from 'mobx'

function persistObservable(observable, store, storageKey) {
  const restoredValue = store.getItem(storageKey)
  if (restoredValue) {
    observable.replace(restoredValue)
  }
  return reaction(
    () => {
      return observable.toJSON()
    },
    value => {
      store.setItem(storageKey, value)
    },
    {
      name: 'persistObservable.' + storageKey,
    }
  )
}

export default persistObservable
