import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { initials } from 'functions/format'
import { useBackgroundImageLoader } from 'hooks'

const AVATAR_LABEL_POSITIONS = {
  AVATAR_LABEL_LEFT: 'AVATAR_LABEL_LEFT',
  AVATAR_LABEL_LEFT_MD: 'AVATAR_LABEL_LEFT_MD',
  AVATAR_LABEL_RIGHT: 'AVATAR_LABEL_RIGHT',
}

const Avatar = props => {
  let {
    children,
    className,
    style,
    src,
    color,
    backgroundColor,
    size,
    sizeCSSUnit,
    rounded,
    label,
    labelPosition,
    ...otherProps
  } = props
  let updatedStyle = {}
  const [isBackgroundImageLoaded] = useBackgroundImageLoader(src)

  if (src) {
    if (isBackgroundImageLoaded) {
      updatedStyle.backgroundImage = `url("${src}")`
    } else {
      updatedStyle.backgroundImage = 'none'
    }
  }

  if (color) updatedStyle.color = color
  if (backgroundColor) updatedStyle.backgroundColor = backgroundColor
  if (size) {
    updatedStyle.width = size + sizeCSSUnit
    updatedStyle.height = size + sizeCSSUnit
  }
  const combinedStyle = { ...style, ...updatedStyle }

  const classes = classNames(
    'Avatar',
    src && isBackgroundImageLoaded && 'Avatar--withImage',
    !rounded && 'Avatar--square',
    label && 'Avatar--labeled',
    label && labelPosition === AVATAR_LABEL_POSITIONS.AVATAR_LABEL_LEFT && 'Avatar--labeled--left',
    label &&
      labelPosition === AVATAR_LABEL_POSITIONS.AVATAR_LABEL_LEFT_MD &&
      'Avatar--labeled--left Avatar--unlabeled--md',
    label && labelPosition === AVATAR_LABEL_POSITIONS.AVATAR_LABEL_RIGHT && 'Avatar--labeled--right',
    className
  )
  return (
    <div style={combinedStyle} className={classes} data-label={label} {...otherProps}>
      {typeof children === 'string' ? <span className="Avatar__text">{initials(children)}</span> : children}
    </div>
  )
}
Avatar.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf([...Object.values(AVATAR_LABEL_POSITIONS)]),
  rounded: PropTypes.bool,
  size: PropTypes.number,
  sizeCSSUnit: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
}

Avatar.defaultProps = {
  backgroundColor: '',
  className: '',
  color: '',
  labelPosition: AVATAR_LABEL_POSITIONS.AVATAR_LABEL_LEFT,
  rounded: true,
  size: 3,
  sizeCSSUnit: 'rem',
  src: '',
  style: {},
}

export default Avatar
export { AVATAR_LABEL_POSITIONS }
