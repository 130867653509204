/* global APP_VERSION */

const clientIds = {
  spotify: '524aea63044043ff848d58346ddc9144',
  google: '907305942707-a15159f40ahbg92t74a69bf7l9d0hovi.apps.googleusercontent.com',
  facebook: '1134667546652925',
  playsome: 'XXX',
}

const urlDefaults = {
  app: 'https://playsome.net',
  // API
  api: 'https://playsome.net:4080',
  websocket: 'ws://playsome.net:4080',
  // Share
  joinParty: 'https://playsome.net/join/',
}

const getConfig = (url = urlDefaults) => {
  return {
    url,
    app: {
      name: 'Playsome',
      version: APP_VERSION,
    },
    language: {
      default: 'en',
    },
    routes: {
      logIn: '/party',
      loggedIn: '/party/party',
    },
    auth: {
      // Time after auth expiry to refresh (s)
      refreshMargin: 10,
      hostProvider: 'spotify',
      anonymousGuestProvider: 'playsome',
      defaultGuestProvider: 'spotify',
      url: {
        validate: url.api + '/validate/{userId}',
      },
      providers: {
        spotify: {
          providerId: 'spotify',
          client_id: clientIds.spotify,
          anonymous: false,
          url: {
            login: url.api + '/login/spotify',
            auth: url.api + '/auth/spotify',
            logout: url.api + '/logout/spotify',
          },
        },
        playsome: {
          providerId: 'playsome',
          client_id: clientIds.playsome,
          anonymous: true,
          url: {
            login: null,
            auth: url.api + '/auth/playsome',
            logout: url.api + '/logout/playsome',
          },
        },
        google: {
          providerId: 'google',
          client_id: clientIds.google,
          anonymous: false,
          redirect_path: '/auth/google',
          url: {
            login: url.api + '/login/google',
            auth: url.api + '/auth/google',
            logout: url.api + '/logout/google',
          },
        },
        facebook: {
          providerId: 'facebook',
          client_id: clientIds.facebook,
          anonymous: false,
          redirect_path: '/auth/facebook',
          url: {
            login: url.api + '/login/facebook',
            auth: url.api + '/auth/facebook',
            logout: url.api + '/logout/facebook',
          },
        },
      },
    },
    spotify: {
      url: {
        webPlayer: 'https://sdk.scdn.co/spotify-player.js',
        search: 'https://api.spotify.com/v1/search',
        playlists: 'https://api.spotify.com/v1/me/playlists',
        playlistTracks: 'https://api.spotify.com/v1/users/{user_id}/playlists/{playlist_id}/tracks',
        createPlaylist: 'https://api.spotify.com/v1/users/{user_id}/playlists',
        addTracksToPlaylist: 'https://api.spotify.com/v1/playlists/{playlist_id}/tracks',
        devices: 'https://api.spotify.com/v1/me/player/devices',
        player: 'https://api.spotify.com/v1/me/player',
        setVolume: 'https://api.spotify.com/v1/me/player/volume',
        playTrack: 'https://api.spotify.com/v1/me/player/play',
        pauseTrack: 'https://api.spotify.com/v1/me/player/pause',
        seekTrack: 'https://api.spotify.com/v1/me/player/seek',
      },
      // Delay for initial poll to catch updated play state shortly after play request sent
      playerInitialPollDelay: 200,
      // Subsequent polling delay
      playerPollingInterval: 10000,
      //
      webPlayerName: 'Spotify Web Player for Playsome',
    },
    alert: {
      defaultTimeoutShort: 2500,
      defaultTimeout: 5000,
      defaultTimeoutLong: 10000,
    },
    analytics: {
      exceptionSampleRate: 0.5,
      ga_id: 'UA-148058373-1',
    },
    connection: {
      // Currently playing sync heartbeat to clients in ms
      heartbeat: 10000,
    },
    blackList: {
      types: {
        artist: 'artist',
        album: 'album',
      },
    },
    docs: {
      path: '/docs',
    },
    musicSearch: {
      minQueryLength: 1,
      limit: 20,
    },
    playlist: {
      // Minimum interval between playlist updates to server (ms)
      serverRefreshInterval: 1000,
    },
    share: {
      url: '/share.html',
      providers: {
        telegram: {
          desktop: {
            url: (url, message, title) =>
              `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(
                title + '\n' + message
              )}`,
            mode: 'popup',
          },
          mobile: {
            url: (url, message, title) =>
              `tg://msg?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title + '\n' + message)}`,
            mode: 'popup',
          },
        },
        messenger: {
          desktop: {
            url:
              'http://www.facebook.com/dialog/send?app_id=' +
              clientIds.facebook +
              '&redirect_uri=http://playsome.net/party/people&display=popup&link={url}',
            mode: 'popup',
          },
          mobile: {
            url: 'fb-messenger://share?link={url}',
            mode: 'link',
          },
        },
        whatsapp: {
          mobile: {
            url: (url, message, title) => `https://wa.me/?text=${encodeURIComponent([url, title, message].join('n'))}`,
            mode: 'popup',
          },
        },
        email: {
          desktop: {
            url: (url, message, title) =>
              'mailto:?subject=' +
              encodeURIComponent(title) +
              '&body=' +
              encodeURIComponent(url + '\r\n\r\n' + message),
            mode: 'link',
          },
          mobile: {
            url: (url, message, title) =>
              'mailto:?subject=' +
              encodeURIComponent(title) +
              '&body=' +
              encodeURIComponent(url + '\r\n\r\n' + message),
            mode: 'link',
          },
        },
      },
    },
    users: {
      // BG color values (SLA) for user avatars, H provided by userId
      avatarColorValues: { s: 37.5, l: 37.5, a: 1 },
    },
  }
}

export default getConfig
