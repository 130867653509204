/* global PRODUCTION */
import React from 'react'
import ReactDOM from 'react-dom'
import { observable } from 'mobx'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import axios from 'axios'
import { createBrowserHistory } from 'history'

import * as lang from './lang'
import getConfig from 'config/config.default'
import { dependencies as dependencyList } from 'config/enums'
import { getNamedAlerts } from './config/alerts'
import { getBaseConfig as getApiClientBaseConfig, endpoints as apiClientEndpoints } from 'config/api'
import { handleError } from 'functions/axiosUtils'

import { Alert, Auth, BlackList, Party, Player, Playlist, Popup } from './models'
import {
  Analytics,
  ApiClient,
  Browser,
  Cache,
  Connection,
  Locale,
  logger,
  persistObservable,
  Share,
  Spotify,
  SpotifyDeviceHandler,
  Store,
  WakeLock,
} from './services'
import App from './app'

import { default as AppComponent } from './components/App.jsx'

import 'normalize.css'
import 'styles/main.scss'

OfflinePluginRuntime.install()

axios('/config.json', {
  // Let 404s through for default config
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 404
  },
})
  .then(response => {
    if (response.status === 404) {
      return {}
    } else {
      return response.data
    }
  })
  .then(async loadedConfig => {
    const config = getConfig(loadedConfig.url)

    const connection = new Connection(config.url.websocket, WebSocket)
    const store = new Store(localStorage)
    const locale = new Locale(config.language.default, lang[config.language.default])
    const cache = new Cache(store)
    cache.clearExpired()
    const browser = new Browser(window.navigator.userAgent)

    const auth = new Auth(config)
    await auth.persist(store)

    const apiClient = new ApiClient(getApiClientBaseConfig(config))
    apiClient.endpoints(apiClientEndpoints).handleErrors(handleError)

    const spotify = new Spotify(config, store, cache)
    spotify.setAuth(auth)
    const spotifyDeviceHandler = new SpotifyDeviceHandler(config, store, browser, spotify, auth)

    const party = new Party()
    party.persist(store)
    const playlist = new Playlist()
    playlist.persist(store)
    if (auth.isLoggedIn) {
      playlist.updateTrackIds(auth.userId)
    }
    const masterPlaylist = new Playlist()
    const historyPlaylist = new Playlist()
    historyPlaylist.persist(store, 'historyPlaylist')
    const tracksFiltered = observable.map(null, { deep: false })
    const blackList = new BlackList()
    blackList.persist(store, 'blackList')
    const userList = observable.map(null, { deep: false })
    const banList = new observable.map()
    persistObservable(banList, store, 'banList')
    const player = new Player(masterPlaylist, historyPlaylist)

    const alert = new Alert(config.alert, getNamedAlerts(config, locale))
    const popup = new Popup()
    const share = new Share(config, browser)

    // TODO: Update Google Analytics from v3 and reactivate
    const analytics = new Analytics(
      // eslint-disable-next-line no-constant-condition
      false && PRODUCTION && 'ga' in window && window.ga.loaded ? window.gtag : null,
      { ...config.analytics, app_name: config.app.name, app_version: config.app.version },
      logger
    )

    const history = createBrowserHistory()
    history.listen(location => {
      analytics.page(location.pathname)
    })

    const wakeLock = browser.hasWakeLock ? new WakeLock(logger) : null

    const dependencies = {
      [dependencyList.alert]: alert,
      [dependencyList.analytics]: analytics,
      [dependencyList.apiClient]: apiClient,
      [dependencyList.auth]: auth,
      [dependencyList.banList]: banList,
      [dependencyList.blackList]: blackList,
      [dependencyList.browser]: browser,
      [dependencyList.cache]: cache,
      [dependencyList.config]: config,
      [dependencyList.history]: history,
      [dependencyList.historyPlaylist]: historyPlaylist,
      [dependencyList.locale]: locale,
      [dependencyList.logger]: logger,
      [dependencyList.masterPlaylist]: masterPlaylist,
      [dependencyList.party]: party,
      [dependencyList.player]: player,
      [dependencyList.playlist]: playlist,
      [dependencyList.popup]: popup,
      [dependencyList.share]: share,
      [dependencyList.spotify]: spotify,
      [dependencyList.spotifyDeviceHandler]: spotifyDeviceHandler,
      [dependencyList.store]: store,
      [dependencyList.tracksFiltered]: tracksFiltered,
      [dependencyList.userList]: userList,
      [dependencyList.wakeLock]: wakeLock,
    }

    const app = new App({
      ...dependencies,
      connection,
    })

    dependencies[dependencyList.app] = app

    // Check if login is expired
    if (auth.isLoggedIn && (await auth.validatePlaysomeToken()) === false) {
      auth.clear()
    }

    // Reestablish party & related dependencies
    try {
      await app.initialize()
    } catch (error) {
      logger.error('main.app.initialize.error', error)
    }

    return dependencies
  })
  .then(dependencies => {
    ReactDOM.render(React.createElement(AppComponent, { ...dependencies }), document.getElementById('app'))
  })
  .catch(error => logger.error('main.error', error))
