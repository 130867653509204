import React from 'react'
import PropTypes from 'prop-types'

import { alertLevels } from 'config/enums'
import AlertIcon from 'components/ui/AlertIcon'
import Text from 'components/ui/Text'

const ErrorMessage = ({ goBack }) => {
  return (
    <div className="l-height--100% l-flex--centered-child">
      <div className="l-align--center">
        <AlertIcon level={alertLevels.ERROR} size={'huge'} />
        <h1>
          <Text>general.error</Text>
        </h1>
        {goBack && (
          <button onClick={goBack}>
            <Text>general.back</Text>
          </button>
        )}
      </div>
    </div>
  )
}

ErrorMessage.propTypes = {
  goBack: PropTypes.func,
  level: PropTypes.oneOf(Object.values(alertLevels)),
}

ErrorMessage.defaultProps = {
  level: alertLevels.ERROR,
}

export default ErrorMessage
