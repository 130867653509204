import React from 'react'
import { VolumeHighIcon, VolumeMediumIcon, VolumeLowIcon, VolumeOffIcon } from 'components/ui/icons'

const VolumeControl = props => {
  const { min = 0, max = 100, step = 1, value, onChange, onClick, ...otherProps } = props
  return (
    <div className="VolumeControl">
      <div className="VolumeControl__button" tabIndex={-1} onClick={onClick}>
        {value >= 66 && <VolumeHighIcon className="Icon" />}
        {value < 66 && value >= 33 && <VolumeMediumIcon className="Icon" />}
        {value < 33 && value > 0 && <VolumeLowIcon className="Icon" />}
        {value === 0 && <VolumeOffIcon className="Icon" />}
      </div>
      <div className="VolumeControl__slider">
        <input type="range" min={min} max={max} step={step} value={value} onChange={onChange} {...otherProps} />
      </div>
    </div>
  )
}

export default VolumeControl
