import React from 'react'
import { observer } from 'mobx-react-lite'

import { alerts } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import UserAvatar, { USER_AVATAR_LABEL_POSITIONS } from 'components/common/UserAvatar'
import Avatar from 'components/ui/Avatar'
import { PopupContainer, PopupHeader, PopupContent } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import ProviderIcon from 'components/common/ProviderIcon'
import { BanIcon } from 'components/ui/icons'
import * as colors from 'styles/export/colors'

const UserPopup = props => {
  const { alert, app, auth, config, party, popup, user } = props

  const handleProfileClick = () => {
    window.open(user.url)
    popup.hide()
  }

  const handleBanClick = () => {
    app
      .banUser(user.userId)
      .then(() => {
        alert.show('alert.userBanned')
      })
      .catch(error => {
        alert.show(alerts.error, `alert.${error}`)
      })
    popup.hide()
  }

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <span className="l-margins--h">{user.name}</span>
      </PopupHeader>
      <PopupContent scrolling={true}>
        <div className="l-flex--centered-child l-paddings">
          <UserAvatar user={user} size={8} labelPosition={USER_AVATAR_LABEL_POSITIONS.AVATAR_LABEL_NONE} />
        </div>
        <ul className="List">
          {user.url && (
            <li className="ListItem ListItem--clickable" onClick={handleProfileClick}>
              <Avatar className="ListItem__icon">
                <ProviderIcon colored={true} providerId={user.providerId} className="Icon" />
              </Avatar>
              <Text className="ListItem__content">general.user_profile</Text>
            </li>
          )}
          {party.isHosted && auth.identity.userId !== user.userId && (
            <li className="ListItem ListItem--clickable" onClick={handleBanClick}>
              <Avatar className="ListItem__icon" backgroundColor={colors.secondary}>
                <BanIcon className="Icon" />
              </Avatar>
              <span className="ListItem__content">
                <Text>general.ban_user</Text>
                {config.auth.providers[user.providerId].anonymous && (
                  <Text className="t-ghosted">general.ban_by_ip_address</Text>
                )}
              </span>
            </li>
          )}
        </ul>
      </PopupContent>
    </PopupContainer>
  )
}

export default withDependencies(
  dependencies.alert,
  dependencies.app,
  dependencies.auth,
  dependencies.config,
  dependencies.party,
  dependencies.popup
)(observer(UserPopup))
