/**
 * Filter playlist or list of playlists by filter function, returning filtered playlists and tracks
 *
 * @param {Object[] | Object[][]} playlists - Single playlist or list of playlists
 * @param {function} trackFilter
 * @return {{playlists: Array, filtered: {}}}
 */
export function filterPlaylists(playlists, trackFilter) {
  if (playlists && playlists.length > 0) {
    if (!Array.isArray(playlists[0])) {
      playlists = [playlists]
    }
    const accepted = new Set()
    const filtered = {}
    // Iterate nth track in each playlist
    // Allows duplicate track filtering to keep early track in late playlist
    const playlistLengths = playlists.map(playlist => playlist.length)
    const maxPlaylistLength = Math.max(...playlistLengths)
    const filteredPlaylists = playlists.map(() => [])
    for (let t = 0; t < maxPlaylistLength; t++) {
      for (let p = 0; p < playlists.length; p++) {
        // Skip if playlist's end already reached
        if (t > playlistLengths[p] - 1) continue
        const track = playlists[p][t]
        const trackFilterResult = trackFilter(track, accepted)
        if (trackFilterResult) {
          filtered[track.trackId] = trackFilterResult
        } else {
          filteredPlaylists[p].push(track)
          accepted.add(track.providerTrackId)
        }
      }
    }
    return {
      playlists: filteredPlaylists,
      filtered,
    }
  }
  return {
    playlists: [],
    filtered: {},
  }
}

/**
 * Combine and sort playlists
 *
 * @param {Playlist[]} playlists
 * @param {int} playlistOffset - Which playlist to start from
 * @return {object[]}
 */
export function mergePlaylists(playlists, playlistOffset = 0) {
  if (playlists && playlists.length > 0) {
    // Order playlists so the offset playlist is first
    const offsetPlaylists =
      playlistOffset > 0 ? [...playlists.slice(playlistOffset), ...playlists.slice(0, playlistOffset)] : playlists
    // Find highest playlist index
    const maxIndex = Math.max(...offsetPlaylists.map(playlist => playlist.length - 1))
    // Zip merge all tracks by slot from all playlists
    const mergedPlaylist = []
    for (let slot = 0; slot <= maxIndex; slot++) {
      offsetPlaylists.forEach(playlist => {
        if (slot < playlist.length) {
          mergedPlaylist.push(playlist[slot])
        }
      })
    }
    return mergedPlaylist
  } else {
    return []
  }
}
