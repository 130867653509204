import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { dependencies, popupSizes } from 'config/enums'
import { withDependencies } from 'components/Context'
import logger from 'services/logger'
import Text from 'components/ui/Text.jsx'
import HeaderView from './HeaderView'
import DocPopup from 'components/common/popups/DocPopup'
import BanListPopup from 'components/common/popups/BanListPopup'
import BlackListPopup from 'components/common/popups/BlackListPopup'
import Confirmation from 'components/common/popups/Confirmation'
import FeedbackPopup from 'components/common/popups/FeedbackPopup'
import PartyInfoPopup from 'components/common/popups/PartyInfoPopup'
import PartyJoinPopup from 'components/common/popups/PartyJoinPopup'
import PartyHostPopup from 'components/common/popups/PartyHostPopup'

const Header = props => {
  const { alert, analytics, app, auth, banList, blackList, party, popup, userList } = props
  const [sideMenuOpen, setSideMenuOpen] = useState(false)

  const toggleSideMenu = useCallback(toggle => {
    setSideMenuOpen(previousSideMenuOpen => (toggle === undefined ? !previousSideMenuOpen : toggle))
  }, [])

  const closeSideMenu = useCallback(() => {
    setSideMenuOpen(false)
  }, [])

  const showPartyHostPopup = useCallback(() => {
    popup.show({
      component: <PartyHostPopup />,
    })
    toggleSideMenu(false)
    analytics.events.showPopup('party_host')
  }, [analytics, popup, toggleSideMenu])

  const showPartyJoinPopup = useCallback(() => {
    popup.show({
      component: <PartyJoinPopup />,
      size: 'small',
    })
    toggleSideMenu(false)
    analytics.events.showPopup('party_join')
  }, [analytics, popup, toggleSideMenu])

  const showPartyInfoPopup = useCallback(() => {
    popup.show({
      component: <PartyInfoPopup />,
      size: popupSizes.small,
    })
    toggleSideMenu(false)
    analytics.events.showPopup('party_info')
  }, [analytics, popup, toggleSideMenu])

  const showBlackListPopup = useCallback(() => {
    popup.show({
      component: <BlackListPopup />,
      size: popupSizes.tall,
    })
    toggleSideMenu(false)
    analytics.events.showPopup('blacklist')
  }, [analytics, popup, toggleSideMenu])

  const showBanListPopup = useCallback(() => {
    popup.show({
      component: <BanListPopup />,
    })
    toggleSideMenu(false)
    analytics.events.showPopup('banlist')
  }, [analytics, popup, toggleSideMenu])

  const showEndOrLeaveConfirmation = useCallback(() => {
    popup.show({
      component: (
        <Confirmation
          message={
            party.isHosted ? (
              <p>
                <Text>label.end_party_confirmation_text</Text>
              </p>
            ) : (
              <p>
                <Text>label.leave_party_confirmation_text</Text>
              </p>
            )
          }
          actionLabel={<Text>{party.isHosted ? 'general.end' : 'general.leave'}</Text>}
          cancelLabel={<Text>general.cancel</Text>}
          onActionClick={() => {
            popup.hide()
            handleEndOrLeaveParty()
          }}
          onCancelClick={popup.hide}
        />
      ),
      size: popupSizes.minimal,
    })
    toggleSideMenu(false)
  }, [popup, toggleSideMenu])

  const handleEndOrLeaveParty = useCallback(() => {
    if (party.isHosted) {
      analytics.events.endParty()
      app
        .endParty()
        .then(() => {
          alert.show('alert.partyEnded')
        })
        .catch(error => logger.error('Header.handleEndOrLeaveParty', error))
    } else {
      analytics.events.leaveParty()
      app.leaveParty().then(() => {
        alert.show('alert.partyLeft')
      })
    }
    toggleSideMenu(false)
  }, [analytics, party, popup, toggleSideMenu])

  const showFeedbackPopup = useCallback(
    event => {
      if (event) event.preventDefault()
      popup.show({
        component: <FeedbackPopup />,
        size: popupSizes.tall,
      })
      toggleSideMenu(false)
      analytics.events.showPopup('feedback')
    },
    [analytics, popup, toggleSideMenu]
  )

  const showHelpPopup = useCallback(
    event => {
      if (event) event.preventDefault()
      props.popup.show({
        component: <DocPopup docId={'help/index'} />,
        size: popupSizes.tall,
      })
      toggleSideMenu(false)
    },
    [popup, toggleSideMenu]
  )

  const showAboutPopup = useCallback(() => {
    popup.show({
      component: <DocPopup docId={'about'} />,
    })
    toggleSideMenu(false)
  }, [popup, toggleSideMenu])

  const showLogoutConfirmation = useCallback(() => {
    if (party.isJoined && userList.size > 1) {
      popup.show({
        component: (
          <Confirmation
            message={
              party.isHosted ? (
                <p>
                  <Text>label.logout_host_confirmation</Text>
                </p>
              ) : (
                <p>
                  <Text>label.logout_client_confirmation</Text>
                </p>
              )
            }
            actionLabel={<Text>general.log_out</Text>}
            cancelLabel={<Text>general.cancel</Text>}
            onActionClick={() => {
              popup.hide()
              analytics.events.logout()
              app.logout()
            }}
            onCancelClick={popup.hide}
          />
        ),
        size: popupSizes.minimal,
      })
    } else {
      app.logout()
    }
    toggleSideMenu(false)
  }, [analytics, app, party, popup, toggleSideMenu])

  return (
    <HeaderView
      auth={auth}
      banList={banList}
      blackList={blackList}
      closeSideMenu={closeSideMenu}
      party={party}
      showAboutPopup={showAboutPopup}
      showBanListPopup={showBanListPopup}
      showBlackListPopup={showBlackListPopup}
      showEndOrLeaveConfirmation={showEndOrLeaveConfirmation}
      showFeedbackPopup={showFeedbackPopup}
      showHelpPopup={showHelpPopup}
      showLogoutConfirmation={showLogoutConfirmation}
      showPartyHostPopup={showPartyHostPopup}
      showPartyInfoPopup={showPartyInfoPopup}
      showPartyJoinPopup={showPartyJoinPopup}
      sideMenuOpen={sideMenuOpen}
      toggleSideMenu={toggleSideMenu}
    />
  )
}

export default withDependencies(
  dependencies.alert,
  dependencies.analytics,
  dependencies.app,
  dependencies.auth,
  dependencies.banList,
  dependencies.blackList,
  dependencies.party,
  dependencies.popup,
  dependencies.userList
)(observer(Header))
