import Axios from 'axios'

class ApiClient {
  baseConfig
  errorHandler

  constructor(baseConfig, endpoints) {
    this.baseConfig = baseConfig
    if (endpoints) {
      this.endpoints(endpoints)
    }
  }

  endpoint(name, config) {
    let parsedConfig
    switch (typeof config) {
      case 'undefined':
        delete this[name]
        break
      case 'string':
        parsedConfig = config.match(/^(?<method>GET|PUT|POST|DELETE|OPTIONS)?\s+(?<url>.*)/im).groups
        break
      case 'function':
        parsedConfig = config(this.baseConfig)
        break
      case 'object':
        parsedConfig = config
        break
      default:
        throw new Error('ApiClient.endpoint expects a configuration that is undefined, string, function or object')
    }
    if (parsedConfig) {
      this[name] = new ApiClientEndpoint({ ...this.baseConfig, ...parsedConfig }, this.errorHandler)
    }
    return this[name]
  }

  endpoints(routes) {
    for (const [name, config] of Object.entries(routes)) {
      this.endpoint(name, config)
    }
    return this
  }

  handleErrors(errorHandler) {
    this.errorHandler = errorHandler
    return this
  }

  getCanceller() {
    return Axios.CancelToken.source()
  }
}

function ApiClientEndpoint(config, errorHandler = error => error) {
  const { cancelable = true, ...axiosConfig } = config
  this.config = axiosConfig
  this.cancelable = cancelable
  this.errorHandler = errorHandler
  this.request = apiClientRequest.bind(this)
  if (this.cancelable) {
    this.request.cancelable = cancelableApiClientRequest.bind(this)
  }
  return this.request
}

/**
 *
 * @param {object} [dataOrParams]
 * @param {object.<string, string>} [urlParams]
 * @returns {Promise<object| Error>}
 */
function apiClientRequest(dataOrParams, urlParams) {
  let url = this.config.url
  let data = {}
  let params = {}

  if (urlParams) {
    Object.entries(urlParams).forEach(([param, value]) => {
      url = url.replace(`{${param}}`, encodeURIComponent(value))
    })
  }

  if (this.config.method.toLowerCase() === 'get') {
    params = dataOrParams
  } else {
    data = dataOrParams
  }

  return Axios({ ...this.config, url, data, params }).catch(this.errorHandler)
}

function cancelableApiClientRequest(externalCancelTokenSource) {
  let cancelTokenSource
  if (externalCancelTokenSource) {
    cancelTokenSource = externalCancelTokenSource
  } else if (externalCancelTokenSource === undefined) {
    cancelTokenSource = Axios.CancelToken.source()
  } else {
    throw new Error('cancelableApiClientRequest expects an Axios cancel token source or undefined')
  }
  const { cancelToken, cancel } = cancelTokenSource
  const config = {
    ...this.config,
    cancelable: false,
    cancelToken,
  }
  const request = new ApiClientEndpoint(config, this.errorHandler)
  return [request, cancel]
}

export default ApiClient
