import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { alertLevels } from 'config/enums'
import logger from 'services/logger'
import { useBeforeInstallPrompt } from 'hooks'
import { withDependencies, dependencies } from 'components/Context'
import Alerts from 'components/common/Alerts'
import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import PlayerControls from 'components/common/PlayerControls'
import Popup from 'components/ui/Popup'

const Page = props => {
  const { auth, app, toolbar, contentClassName, children, ...otherProps } = props

  useBeforeInstallPrompt((prompt, choicePromise) => {
    logger.log('Page.handleBeforeInstallPrompt')
    alert.show({
      id: 'alert.addToHomeScreen',
      level: alertLevels.INFO,
      message: 'alert.addToHomeScreen',
      dismissable: true,
      onClick: () => {
        alert.hide()
        // Show the prompt
        prompt()
        // Wait for the user to respond to the prompt
        choicePromise.then(({ outcome }) => {
          if (outcome === 'accepted') {
            logger.log('Page.handleBeforeInstallPrompt.accepted')
          } else {
            logger.log('Page.handleBeforeInstallPrompt.rejected')
          }
        })
      },
    })
  })

  if (auth && auth.isLoggedIn) {
    return (
      <div className="l-rows l-height--100% l-position__relative" {...otherProps}>
        <Header />
        {toolbar}
        <div id="MainScrollElement" className={classNames('Content', contentClassName)}>
          {children}
        </div>
        {app.showPlayerControls && <PlayerControls />}
        <Footer />
        <Alerts />
        <Popup />
      </div>
    )
  } else {
    return <Redirect to="/party" />
  }
}

Page.propTypes = {
  toolbar: PropTypes.node,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
}

Page.defaultProps = {
  contentClassName: '',
}

export default withDependencies(dependencies.auth, dependencies.app)(observer(Page))
