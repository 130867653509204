import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import classNames from 'classnames'

import { alertLevels, popupSizes } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import DocPopup from 'components/common/popups/DocPopup'
import BasicPage from 'components/common/BasicPage'
import LoginSelectPopup from 'components/common/popups/LoginSelectPopup'
import * as LoginPopups from 'components/common/popups/LoginPopups'
import PlaysomeLoginForm from 'components/common/PlaysomeLoginForm'
import PlaysomeLogo from 'components/common/PlaysomeLogo'
import ProviderIcon from 'components/common/ProviderIcon'
import Text from 'components/ui/Text'
import { ChevronDownIcon } from 'components/ui/icons'

const LOGIN_TYPES = {
  JOIN: 'JOIN',
  HOST: 'HOST',
}
const LAST_LOGIN_TYPE_KEY = 'auth.lastLoginType'
const LAST_GUEST_LOGIN_PROVIDER_KEY = 'auth.lastLoginProvider'
const LAST_USER_ID_KEY = 'user.lastUserId'

const Start = props => {
  const { alert, analytics, apiClient, app, auth, config, history, locale, logger, party, popup, store } = props
  const [loginType, setLoginType] = useState(store.getItem(LAST_LOGIN_TYPE_KEY) || LOGIN_TYPES.JOIN)
  const [selectedGuestLoginProvider, setSelectedGuestLoginProvider] = useState(
    store.getItem(LAST_GUEST_LOGIN_PROVIDER_KEY) || config.auth.defaultGuestProvider
  )
  const [lastUserId, setLastUserId] = useState(store.getItem(LAST_USER_ID_KEY) || '')
  const hostAuthProviderId = config.auth.hostProvider

  const checkServerOnline = async () => {
    if (await apiClient.checkServer()) {
      showServerOnlineAlert()
    } else {
      showServerOfflineAlert()
    }
  }

  const showServerOnlineAlert = () => {
    alert.show('alert.serverOnline')
  }

  const showServerOfflineAlert = () => {
    alert.show({
      id: 'alert.serverOffline',
      level: alertLevels.ERROR,
      message: 'alert.serverOffline',
      dismissable: true,
      onClick: async () => {
        alert.hide('alert.serverOffline')
        setTimeout(checkServerOnline, 3000)
      },
    })
  }

  const toggleLoginType = type => {
    setLoginType(type)
    store.setItem(LAST_LOGIN_TYPE_KEY, type)
  }

  const showLoginSelectPopup = () => {
    popup.show({
      component: <LoginSelectPopup guestOnlyProviders={false} showAnonymousLogin={false} onLoginClick={startLogin} />,
    })
    analytics.events.showPopup('login_select')
  }

  const startAnonymousLogin = values => {
    return app
      .authorize(config.auth.providers.playsome.providerId, { userId: values.userId })
      .then(() => {
        setLastUserId(values.userId)
        store.setItem(LAST_USER_ID_KEY, values.userId)
      })
      .then(() => app.initialize())
      .then(() => analytics.events.login(config.auth.providers.playsome.providerId))
      .then(() => history.push(config.routes.loggedIn))
      .catch(error => {
        alert.show({
          level: alertLevels.ERROR,
          message: locale.translate('alert.' + error),
          timeout: config.alert.defaultTimeoutShort,
        })
        history.push(config.routes.logIn)
      })
  }

  const startLogin = async providerId => {
    if (providerId !== config.auth.anonymousGuestProvider) {
      setSelectedGuestLoginProvider(providerId)
      store.setItem(LAST_GUEST_LOGIN_PROVIDER_KEY, providerId)
    }

    const url = auth.getLoginUrl(providerId)

    if (url) {
      if (!navigator.onLine) {
        props.alert.show({
          level: alertLevels.ERROR,
          message: 'alert.offline',
          // timeout: config.alert.defaultTimeoutLong,
          dismissable: true,
        })
      } else if ((await apiClient.checkServer()) === false) {
        showServerOfflineAlert()
      } else {
        await analytics.events.login(providerId)
        logger.log("Start.startLogin.url", {
          providerId,
          url,
        })
        window.document.location.assign(url)
      }
    } else if (providerId in LoginPopups) {
      popup.show({
        component: LoginPopups[providerId],
        size: popupSizes.small,
      })
    } else {
      props.alert.show({
        level: alertLevels.ERROR,
        message: 'alert.loginError',
        timeout: config.alert.defaultTimeoutLong,
      })
    }
  }

  const showHelpPopup = event => {
    if (event) event.preventDefault()
    props.popup.show({
      component: <DocPopup docId={'help/login'} />,
      size: popupSizes.tall,
    })
  }

  const showTermsPopup = event => {
    event.preventDefault()
    props.popup.show({
      component: <DocPopup docId={'terms'} />,
      size: popupSizes.tall,
    })
  }

  const showPrivacyPopup = event => {
    event.preventDefault()
    props.popup.show({
      component: <DocPopup docId={'privacy'} />,
      size: popupSizes.tall,
    })
  }

  if (auth && auth.isLoggedIn) {
    return <Redirect to={config.routes.loggedIn} />
  } else {
    return (
      <BasicPage>
        <div className="l-rows l-height--100% l-max-width-40 l-margins--h-auto">
          <div className="Start__logo__container l-flex l-flex--b50% l-flex--centered-child">
            <div className="l-margins l-align--center">
              <Link to={'/'} tabIndex={4}>
                <PlaysomeLogo className="Start__logo__image" />
              </Link>
            </div>
          </div>
          <div className="Start__container l-flex l-flex--b50% l-flex--centered-child">
            <div className="l-rows l-margins">
              <div className="Tabs">
                <a
                  className={classNames('Tabs__tab', loginType === LOGIN_TYPES.JOIN ? 'Tabs__tab--active' : null)}
                  tabIndex={1}
                  onClick={() => toggleLoginType(LOGIN_TYPES.JOIN)}
                  onKeyPress={() => toggleLoginType(LOGIN_TYPES.JOIN)}
                  data-test="joinPartyTab"
                >
                  <Text>general.join_party</Text>
                </a>
                <a
                  className={classNames('Tabs__tab', loginType === LOGIN_TYPES.HOST ? 'Tabs__tab--active' : null)}
                  tabIndex={1}
                  onClick={() => toggleLoginType(LOGIN_TYPES.HOST)}
                  onKeyPress={() => toggleLoginType(LOGIN_TYPES.HOST)}
                  data-test="hostPartyTab"
                >
                  <Text>general.host_party</Text>
                </a>
              </div>
              <div className="l-width--20 l-max-width--100% l-height--18 l-paddings u-background-600">
                {loginType === LOGIN_TYPES.JOIN && (
                  <JoinParty
                    partyId={party.partyId}
                    lastUserId={lastUserId}
                    selectedGuestLoginProvider={selectedGuestLoginProvider}
                    startAnonymousLogin={startAnonymousLogin}
                    startLogin={startLogin}
                    showLoginSelectPopup={showLoginSelectPopup}
                    tabIndex={2}
                  />
                )}
                {loginType === LOGIN_TYPES.HOST && (
                  <HostParty
                    partyId={party.partyId}
                    hostAuthProviderId={hostAuthProviderId}
                    startLogin={startLogin}
                    tabIndex={2}
                  />
                )}
              </div>

              <div className="l-flex l-flex-spacer--2" />
              <div className="l-flex ">
                <p className="t-tiny l-align--center">
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      showHelpPopup()
                    }}
                    className="t-default t-link--no-decoration"
                    tabIndex={3}
                  >
                    <Text>general.help</Text>
                  </a>
                  &nbsp;<span className="t-ghosted">|</span>&nbsp;
                  <a
                    href="/docs/terms.html"
                    className="t-default t-link--no-decoration"
                    onClick={showTermsPopup}
                    tabIndex={3}
                  >
                    <Text>general.terms</Text>
                  </a>
                  &nbsp;<span className="t-ghosted">|</span>&nbsp;
                  <a
                    href="/docs/privacy.html"
                    className="t-default t-link--no-decoration"
                    onClick={showPrivacyPopup}
                    tabIndex={3}
                  >
                    <Text>general.privacy</Text>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </BasicPage>
    )
  }
}

export default withRouter(
  withDependencies(
    dependencies.alert,
    dependencies.analytics,
    dependencies.apiClient,
    dependencies.app,
    dependencies.auth,
    dependencies.config,
    dependencies.locale,
    dependencies.logger,
    dependencies.party,
    dependencies.popup,
    dependencies.store
  )(observer(Start))
)

const JoinParty = props => {
  const {
    partyId,
    lastUserId,
    selectedGuestLoginProvider,
    startAnonymousLogin,
    startLogin,
    showLoginSelectPopup,
    tabIndex = 1,
  } = props
  return (
    <div className="l-rows l-height--100%">
      {partyId && (
        <Fragment>
          <label>
            <Text>general.joining_party</Text>
          </label>
          <p className="l-margin--t0 t-ellipsis">{partyId}</p>
        </Fragment>
      )}

      <PlaysomeLoginForm
        className="l-margin--b"
        lastUserId={lastUserId}
        onLogin={startAnonymousLogin}
        data-test="joinPartyForm"
        tabIndex={tabIndex}
      />

      <label className="l-margin--b">
        <Text>general.or_log_in_with_social_account</Text>
      </label>
      <div className="button-group l-flex l-flex--fixed l-columns">
        <button
          onClick={e => {
            e.preventDefault()
            startLogin(selectedGuestLoginProvider)
          }}
          className="button--primary l-flex--grow-1"
          tabIndex={tabIndex}
          data-test="guestLoginButton"
        >
          <Text>{`authProvider.title.${selectedGuestLoginProvider}`}</Text>
          &nbsp;
          <ProviderIcon providerId={selectedGuestLoginProvider} className="Icon" colored={false} />
        </button>
        <button
          className="button--primary button--icon button-select__toggle"
          onClick={showLoginSelectPopup}
          data-test="guestLoginSelect"
          tabIndex={tabIndex}
        >
          <ChevronDownIcon className="Icon" />
        </button>
      </div>
    </div>
  )
}

const HostParty = props => {
  const { hostAuthProviderId, startLogin, tabIndex } = props
  return (
    <div className="l-margins--v">
      <button
        onClick={e => {
          e.preventDefault()
          startLogin(hostAuthProviderId)
        }}
        className="button--primary l-width--100%"
        tabIndex={tabIndex}
      >
        <Text>{`authProvider.loginLabel.${hostAuthProviderId}`}</Text>{' '}
        <ProviderIcon providerId={hostAuthProviderId} className="Icon" colored={false} />
      </button>
    </div>
  )
}
