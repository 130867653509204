import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Loader = props => {
  const className = classNames('Loader', props.size && 'Loader--' + props.size)
  return <img src="/img/playsome_logo_notext.svg" className={className} alt="" />
}
Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'icon']),
}

export default Loader
