import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'

import { withDependencies, dependencies } from 'components/Context'
import HtmlLoader from 'components/common/HtmlLoader'
import { PopupContainer, PopupHeader, PopupContent } from 'components/ui/Popup'

const DocPopup = props => {
  const { analytics, config, docId, history, locale, popup } = props
  const [title, setTitle] = useState('')
  const [clickedDocId, setClickedDocId] = useState('')
  const popupScrollingElement = useRef()

  const htmlSubstitutions = useMemo(
    () => ({
      appVersion: config.app.version,
    }),
    [config]
  )
  const domSubstitutions = useMemo(
    () => ({
      '[data-include=backToHelpIndex]': `<p><a href="help/index.html">${locale.translate(
        'general.back_to_help'
      )}</a></p>`,
    }),
    [locale]
  )

  useEffect(() => {
    analytics.events.showPopup('doc:' + docId)
  }, [analytics])

  const getPath = useCallback(
    docId => {
      return `${props.config.docs.path}/${docId}${docId && docId.endsWith('.html') ? '' : '.html'}`
    },
    [docId]
  )

  const transformHTML = useCallback(
    html => {
      let transformedHtml = html
      Object.entries(htmlSubstitutions).forEach(([search, replace]) => {
        transformedHtml = transformedHtml.replace(`{${search}}`, replace)
      })
      return transformedHtml
    },
    [htmlSubstitutions]
  )

  const transformDOM = useCallback(
    element => {
      setTitle(element.querySelector('title').textContent || '')
      Object.entries(domSubstitutions).forEach(([selector, html]) => {
        element.querySelectorAll(selector).forEach(substitutionElement => {
          if (typeof html === 'string') {
            substitutionElement.innerHTML = html
          } else if (typeof html === 'function') {
            substitutionElement.innerHTML = html(element)
          }
        })
      })
    },
    [domSubstitutions]
  )

  const handleClick = useCallback(
    event => {
      if (event.target.attributes && event.target.attributes.href && event.target.attributes.href.value) {
        event.preventDefault()
        const href = event.target.attributes.href.value
        if (href.startsWith('#')) {
          document.querySelector(`#${href.substr(1)}`).scrollIntoView()
          // Paths starting with slash are regular links
        } else if (href.startsWith('/')) {
          popup.hide()
          history.push(href)
          // External links
        } else if (href.startsWith('http://') || href.startsWith('https://') || href.startsWith('mailto:')) {
          popup.hide()
          window.location = href
          // Other links lead to other docs
        } else {
          setClickedDocId(href)
        }
      }
    },
    [history, popup]
  )

  const path = getPath(clickedDocId || docId)

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <span className="l-margins--h">{title}</span>
      </PopupHeader>
      <PopupContent scrolling={true} className="l-paddings--h">
        <HtmlLoader
          className="Doc l-min-height--8"
          path={path}
          onMount={el => (popupScrollingElement.current = el)}
          transformHTML={transformHTML}
          transformDOM={transformDOM}
          onClick={handleClick}
        />
      </PopupContent>
    </PopupContainer>
  )
}

export default withRouter(
  withDependencies(
    dependencies.analytics,
    dependencies.config,
    dependencies.locale,
    dependencies.popup
  )(observer(DocPopup))
)
