import { makeAutoObservable, reaction, isObservableArray, toJS } from 'mobx'
import clamp from 'lodash/clamp'

import logger from 'services/logger'

export default class Playlist {
  persistDisposer
  list = []
  selected = []

  constructor(list) {
    makeAutoObservable(this, {
      persistDisposer: false,
    })
    if (list) this.replace(list)
  }

  persist(store, storageKey = 'playlist') {
    this.store = store
    const list = this.store.getItem(storageKey)
    if (list) {
      this.replace(list)
    }
    this.persistDisposer = reaction(
      () => toJS(this.list),
      value => {
        this.store.setItem(storageKey, value)
      }
    )
  }

  /**
   * Get number of playlist tracks
   *
   * @returns {int}
   */
  get length() {
    return this.list.length
  }

  /**
   * Get number of selected playlist tracks
   *
   * @returns {int}
   */
  get selectedCount() {
    return this.selected.length
  }

  /**
   * Get list of track ids in list
   *
   * @return {string[]}
   */
  get trackIds() {
    return this.list.map(track => track.trackId)
  }

  /**
   * Get list of provider track ids in list
   *
   * @return {string[]}
   */
  get providerTrackIds() {
    return this.list.map(track => track.providerTrackId)
  }

  /**
   * Get pure Mobx-free playlist array
   *
   * @returns {Array}
   */
  get toJS() {
    return toJS(this.list)
  }

  /**
   * Completely replace playlist tracks with new ones
   *
   * @param {Array} list
   * @returns {Playlist}
   */
  replace(list) {
    this.list.replace(list)
    this.selected.clear()
    return this
  }

  /**
   * Append playlist tracks
   *
   * @param {Array} list
   * @returns {Playlist}
   */
  append(list) {
    this.list = [...this.list, ...list]
    return this
  }

  /**
   * Check if a unique track with given trackId exists in the list
   *
   * @param {string} trackId
   * @returns {boolean}
   */
  hasUnique(trackId) {
    return this.list.some(track => track.trackId === trackId)
  }

  /**
   * Check if a general track with providerId and providerTrackId exists in the list
   *
   * @param {string} providerId
   * @param {string} providerTrackId
   * @returns {boolean}
   */
  hasTrack(providerId, providerTrackId) {
    return this.list.some(track => track.providerId === providerId && track.providerTrackId === providerTrackId)
  }

  /**
   * Get track object for a given track id
   *
   * @param {string} trackId
   * @returns {Object}
   */
  getTrack(trackId) {
    for (let trackIndex = 0; trackIndex <= this.list.length - 1; trackIndex++) {
      if (this.list[trackIndex].trackId === trackId) {
        return this.list[trackIndex]
      }
    }
    return null
  }

  getTrackByProviderTrackId(providerTrackId) {
    for (let trackIndex = 0; trackIndex <= this.list.length - 1; trackIndex++) {
      if (this.list[trackIndex].providerTrackId === providerTrackId) {
        return this.list[trackIndex]
      }
    }
    return null
  }

  /**
   * Get track object for a given track index
   *
   * @param {number} trackIndex
   * @returns {Object}
   */
  getTrackAtIndex(trackIndex) {
    return this.list[trackIndex]
  }

  /**
   * Get array index for a track of a given id, false if track doesn't exist
   *
   * @param {string} trackId
   * @returns {int|boolean}
   */
  getTrackIndex(trackId) {
    for (let trackIndex = 0; trackIndex <= this.list.length - 1; trackIndex++) {
      if (this.list[trackIndex].trackId === trackId) {
        return trackIndex
      }
    }
    return false
  }

  updateTrackUserId(track, userId) {
    return {
      ...track,
      trackId: [userId, track.providerId, track.providerTrackId, Date.now()].join(':'),
      userId,
    }
  }

  /**
   * Recreate all track ids for a new user id
   *
   * @param {string} userId
   * @returns {Playlist}
   */
  updateTrackIds(userId) {
    this.list = this.list.map(track => (userId === track.userId ? track : this.updateTrackUserId(track, userId)))
    return this
  }

  /**
   * Add a new track or list of tracks at given index, or at the end of the list if no index given
   *
   * @param {Object} track
   * @param {int} [index]
   * @returns {Playlist}
   */
  add(track, index) {
    index = index === undefined ? this.list.length : index
    index = clamp(index, 0, this.list.length)
    if (Array.isArray(track)) {
      this.list.splice(index, 0, ...track)
    } else {
      this.list.splice(index, 0, track)
    }
    return this
  }

  /**
   * Remove track or list of tracks from playlist by provider track id
   *
   * @param {string|Array} providerTrackIds
   * @returns {Playlist}
   */
  remove(providerTrackIds) {
    if (!Array.isArray(providerTrackIds) && !isObservableArray(providerTrackIds)) {
      providerTrackIds = [providerTrackIds]
    }
    // Find tracks by providerTrackId and delete by found tracks' unique trackId
    this.list
      .filter(track => providerTrackIds.includes(track.providerTrackId))
      .map(track => track.trackId)
      .forEach(trackId => this.removeUnique(trackId))
    return this
  }

  /**
   * Remove unique track by trackId
   *
   * @param {string} trackId
   * @returns {Playlist}
   */
  removeUnique(trackId) {
    this.list = this.list.filter(track => track.trackId !== trackId)
    this.selected = this.selected.filter(selectedId => selectedId !== trackId)
    return this
  }

  /**
   * Remove selected tracks from playlist
   *
   * @returns {Playlist}
   */
  removeSelected = () => {
    this.selected.forEach(trackId => this.removeUnique(trackId))
    return this
  }

  /**
   * Remove all tracks from playlist
   *
   * @returns {Playlist}
   */
  empty() {
    this.list.clear()
    this.selected.clear()
    return this
  }

  filter(trackFilterFunction) {
    this.list = this.list.filter(trackFilterFunction)
    return this
  }

  /**
   * Add track another playlist
   *
   * @param {string} trackId
   * @param {Playlist} playlist
   */
  addTrackToPlaylist(trackId, playlist) {
    const track = this.getTrack(trackId)
    if (track) {
      playlist.add(track)
    }
    return this
  }

  /**
   * Move track another playlist
   *
   * @param {string} trackId
   * @param {Playlist} playlist
   */
  moveTrackToPlaylist(trackId, playlist) {
    const track = this.getTrack(trackId)
    if (track) {
      playlist.add(track)
      this.remove(trackId)
    }
    return this
  }

  /**
   * Move a track or list of tracks to a new index in the playlist, or the end if no index given
   *
   * @param {string|Array} trackId
   * @param {int} [newIndex]
   * @returns {Playlist}
   */
  reorder(trackId, newIndex) {
    let tracks = []
    if (Array.isArray(trackId)) {
      trackId.forEach(id => {
        tracks.push(this.list.splice(this.getTrackIndex(id), 1).pop())
      })
      this.add(tracks, newIndex)
    } else {
      tracks = this.list.splice(this.getTrackIndex(trackId), 1).pop()
      this.add(tracks, newIndex)
    }
    return this
  }

  /**
   * Move selected tracks to a new index in playlist
   *
   * @param {int} [newIndex]
   * @returns {Playlist}
   */
  reorderSelected(newIndex) {
    return this.reorder(Array.from(this.selected), newIndex)
  }

  /**
   * Check if a track with a given id is selected
   *
   * @param {string} trackId
   * @returns {boolean}
   */
  isSelected(trackId) {
    return this.selected.includes(trackId)
  }

  /**
   * Select a track or list of tracks with given ids, or select all tracks if no id is given
   *
   * @param {string|Array} trackId
   * @returns {Playlist}
   */
  select = trackId => {
    if (trackId === undefined) {
      this.selected = this.list.map(track => track.trackId)
    } else {
      if (Array.isArray(trackId) || isObservableArray(trackId)) {
        trackId.forEach(id => {
          if (!this.selected.includes(id)) {
            this.selected.push(id)
          }
        })
      } else {
        if (!this.selected.includes(trackId)) {
          this.selected.push(trackId)
        }
      }
    }
    return this
  }

  /**
   * Unselect a track or list of tracks with given ids, or select all tracks if no id is given
   *
   * @param {string|Array} [trackId]
   * @returns {Playlist}
   */
  unselect(trackId) {
    if (trackId === undefined) {
      this.selected = []
    } else {
      if (!Array.isArray(trackId)) {
        trackId = [trackId]
      }
      this.selected = this.selected.filter(id => !trackId.includes(id))
    }
    return this
  }

  /**
   * Toggle selection state for a track
   *
   * @param {string} trackId
   * @returns {Playlist}
   */
  toggleSelected(trackId) {
    return this.isSelected(trackId) ? this.unselect(trackId) : this.select(trackId)
  }

  /**
   * Return the next track after the provided track, or the first track if no previous track provided
   *
   * @param {object} [afterTrack]
   * @return {null|object}
   */
  getNextUnplayedTrack(afterTrack) {
    let trackIndex = 0
    let nextTrack = null
    if (afterTrack) {
      trackIndex = this.getTrackIndex(afterTrack.trackId) + 1
    }
    if (this.list.length > trackIndex) {
      nextTrack = this.list[trackIndex]
    }
    logger.log('getNextUnplayedTrack', { afterTrack, trackIndex, nextTrack })
    return nextTrack
  }

  /**
   * Get last track in list
   *
   * @returns {null|object}
   */
  getLastTrack() {
    const listLength = this.list.length
    if (listLength > 0) {
      return this.list[listLength - 1]
    } else {
      return null
    }
  }
}
