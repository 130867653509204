import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ProgressBar = props => {
  let { amount, className } = props

  return (
    <span className={classNames('ProgressBar', className)}>
      <span className="ProgressBar__bar" style={{ width: amount * 100 + '%' }} />
    </span>
  )
}
ProgressBar.propTypes = {
  amount: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default ProgressBar
