import React from 'react'
import { observer } from 'mobx-react-lite'

import { withDependencies, dependencies } from 'components/Context'
import { duration as formatDuration } from 'functions/format'
import ProgressBar from 'components/ui/ProgressBar'

const TimeLine = props => {
  const { player, ...otherProps } = props
  if (player.currentTrack) {
    return (
      <div {...otherProps}>
        <p className="l-columns l-margins--v0">
          <span>{formatDuration(Math.round(Math.min(player.currentTime, player.currentTrack.duration)))}</span>
          <ProgressBar
            className="l-flex--align-self--center l-margins--h"
            amount={player.currentTime / player.currentTrack.duration}
          />
          <span>{formatDuration(Math.round(player.currentTrack.duration))}</span>
        </p>
      </div>
    )
  } else {
    return (
      <div {...otherProps}>
        <p className="l-columns l-margins--v0">
          <span>{formatDuration(0)}</span>
          <ProgressBar className="l-flex--align-self--center l-margins--h" amount={0} />
          <span>{formatDuration(0)}</span>
        </p>
      </div>
    )
  }
}

export default withDependencies(dependencies.player)(observer(TimeLine))
