import React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { withDependencies, dependencies } from 'components/Context'
import { CheckCircleIcon, CheckIcon, CloseIcon, DeleteIcon, MoveToBottomIcon, MoveToTopIcon } from 'components/ui/icons'

const PlaylistToolbar = props => {
  const { playlist, visible, toggleVisible } = props
  return (
    <div className={classNames('Toolbar', !visible && 'Toolbar--hidden')}>
      <button
        className="Toolbar__button button--icon button--hollow button--primary"
        onClick={playlist.selectedCount > 0 ? () => playlist.unselect() : () => playlist.select()}
      >
        {playlist.selectedCount > 0 ? <CheckIcon className="Icon" /> : <CheckCircleIcon className="Icon" />}
      </button>
      <button
        className="Toolbar__button button--icon button--hollow button--primary"
        disabled={playlist.selectedCount === 0}
        onClick={() => playlist.removeSelected()}
      >
        <DeleteIcon className="Icon" />
      </button>
      <button
        className="Toolbar__button button--icon button--hollow button--primary"
        disabled={playlist.selectedCount === 0 || playlist.selectedCount === playlist.length}
        onClick={() => playlist.reorderSelected(0)}
      >
        <MoveToTopIcon className="Icon" />
      </button>
      <button
        className="Toolbar__button button--icon button--hollow button--primary"
        disabled={playlist.selectedCount === 0 || playlist.selectedCount === playlist.length}
        onClick={() => playlist.reorderSelected()}
      >
        <MoveToBottomIcon className="Icon" />
      </button>
      <button
        className="Toolbar__button button--icon button--hollow button--secondary l-margin--l-auto"
        onClick={() => toggleVisible(false)}
      >
        <CloseIcon className="Icon" />
      </button>
    </div>
  )
}

export default withDependencies(dependencies.playlist)(observer(PlaylistToolbar))
