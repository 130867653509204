import logger from 'services/logger'

class SpotifyOtherDevice {
  config
  auth
  deviceId
  subscribers = []
  pollingTimeout
  pollingInterval
  delayedPollingTimeout

  constructor(config, browser, spotify) {
    this.config = config
    this.browser = browser
    this.spotify = spotify
  }

  initialize(deviceId, auth) {
    this.deviceId = deviceId
    this.auth = auth
    return Promise.resolve(this)
  }

  destroy() {
    this.stopPolling()
    return this
  }

  static isAvailable() {
    return true
  }

  subscribe(callback) {
    this.subscribers.push(callback)
    return () => {
      this.subscribers = this.subscribers.filter(subscriber => subscriber !== subscriber)
    }
  }

  handleError = message => {
    logger.log('SpotifyOtherDevice.handleError', message)
    this.subscribers.forEach(callback => {
      callback({
        event: 'error',
        data: message,
      })
    })
  }

  handleStateChange = state => {
    logger.log('SpotifyOtherDevice.handleStateChange', state)
    if (state) {
      let transformedState = {}
      const { is_playing = true, progress_ms = 0, item, device } = state || {}
      transformedState = {
        isPlaying: is_playing,
        currentTime: progress_ms,
        currentTrackId: item?.id,
        volume: device.volume_percent ? device.volume_percent / 100 : null,
      }
      this.subscribers.forEach(callback => {
        callback({
          event: 'state',
          data: transformedState,
        })
      })
    }
    return this
  }

  /**
   * Checks player's current state and uses it
   */
  useCurrentState() {
    return this.spotify.getCurrentlyPlaying().then(this.handleStateChange)
  }

  play(track) {
    return this.spotify
      .playTrack(this.deviceId, track)
      .then(() => this.startPolling(this.config.spotify.playerInitialPollDelay))
  }

  pause() {
    return this.spotify.pauseTrack(this.deviceId).then(() => {
      logger.log('SpotifyOtherDevice.pause')
      this.stopPolling()
      this.delayedPoll(this.config.spotify.playerInitialPollDelay)
    })
  }

  resume() {
    return this.spotify
      .playTrack(this.deviceId)
      .then(() => this.startPolling(this.config.spotify.playerInitialPollDelay))
  }

  setVolume = async volume => {
    return this.spotify.setVolume(this.deviceId, Math.round(volume * 100))
  }

  seek(position) {
    return this.spotify.seekTrack(position)
  }

  transfer(device_id, play) {
    return this.spotify.transferDevice(device_id, play)
  }

  poll = () => {
    return this.spotify.getCurrentlyPlaying().then(data => {
      logger.log('SpotifyOtherDevice.poll', data)
      return this.handleStateChange(data)
    })
  }

  delayedPoll(delay) {
    this.delayedPollingTimeout = setTimeout(this.poll, typeof delay === 'number' && delay >= 0 ? delay : 0)
  }

  /**
   * Begin polling play state, with optional initial delay for picking up correct state shortly after play
   */
  startPolling = initialDelay => {
    if (this.pollingInterval) clearInterval(this.pollingInterval)
    if (
      typeof initialDelay === 'number' &&
      initialDelay >= 0 &&
      initialDelay < this.config.spotify.playerPollingInterval
    ) {
      this.pollingTimeout = setTimeout(() => {
        this.poll()
        this.pollingInterval = setInterval(this.poll, this.config.spotify.playerPollingInterval)
      }, initialDelay)
    } else {
      this.pollingInterval = setInterval(this.poll, this.config.spotify.playerPollingInterval)
    }
  }

  stopPolling() {
    logger.log('SpotifyOtherDevice.stopPolling', this.pollingTimeout, this.pollingInterval)
    clearTimeout(this.pollingTimeout)
    clearInterval(this.pollingInterval)
    clearTimeout(this.delayedPollingTimeout)
    this.pollingTimeout = null
    this.pollingInterval = null
    this.delayedPollingTimeout = null
    return this
  }
}

export default SpotifyOtherDevice
