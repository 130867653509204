const baseConfig = {}

const getBaseConfig = config => ({
  ...baseConfig,
  baseURL: config.url.api,
})

const endpoints = {
  checkServer: {
    method: 'get',
    url: '/online',
    timeout: 1000,
  },
  postFeedback: {
    method: 'post',
    url: '/feedback',
  },
  validatePlaysomeToken: {
    method: 'get',
    url: '/validate/{userId}',
  },
}

export { baseConfig, getBaseConfig, endpoints }
