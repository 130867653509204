import { makeAutoObservable, ObservableMap, reaction, toJS } from 'mobx'

import { alerts, connectionStates, serverCommands, serverEvents } from 'config/enums'
import { PlaylistHandler } from 'models'

export default class App {
  previousConnectionState
  connectionStateDisposer
  cancelConnectionSubscription
  isConnected = false

  constructor(dependencies) {
    const {
      alert,
      auth,
      banList,
      blackList,
      config,
      connection,
      historyPlaylist,
      locale,
      logger,
      masterPlaylist,
      tracksFiltered,
      spotify,
      party,
      player,
      playlist,
      spotifyDevice,
      spotifyDeviceHandler,
      store,
      userList,
    } = dependencies
    this.alert = alert
    this.auth = auth
    this.banList = banList
    this.blackList = blackList
    this.config = config
    this.connection = connection
    this.historyPlaylist = historyPlaylist
    this.locale = locale
    this.logger = logger
    this.masterPlaylist = masterPlaylist
    this.party = party
    this.player = player
    this.playlist = playlist
    this.spotify = spotify
    this.spotifyDevice = spotifyDevice
    this.spotifyDeviceHandler = spotifyDeviceHandler
    this.store = store
    this.tracksFiltered = tracksFiltered
    this.userList = userList

    makeAutoObservable(this)
    // Bind flow methods
    this.logout = this.logout.bind(this)
    this.startParty = this.startParty.bind(this)
    this.reconnectAndReinitialize = this.reconnectAndReinitialize.bind(this)
    this.endParty = this.endParty.bind(this)
    this.leaveParty = this.leaveParty.bind(this)
    this.banUser = this.banUser.bind(this)
    this.unbanUser = this.unbanUser.bind(this)

    // React to connection events
    this.previousConnectionState = ''
    this.connectionStateDisposer = reaction(() => this.connection.state, this.handleConnectionState, {
      context: this,
      fireImmediately: true,
      delay: 500,
    })

    // Subscribe to connection messages
    this.cancelConnectionSubscription = connection.subscribe(this.handleConnectionMessage)
  }

  // Initialize party and required dependencies
  *initialize() {
    if (this.auth.isLoggedIn && this.party.isJoined && !this.isConnected) {
      try {
        if (this.party.isHosted) {
          yield this.startParty(this.party.partyId, this.party.partyPassword, this.party.partyOptions)
          const spotifyDevice = yield this.spotifyDeviceHandler.restore()
          if (spotifyDevice) {
            yield this.player.setSpotifyDevice(spotifyDevice)
          }
        } else {
          yield this.joinParty(this.party.partyId, this.party.partyPassword)
          this.alert.show(alerts.partyJoined, this.party.partyId)
        }
      } catch (error) {
        this.logger.warn('app.reinitialize error', error)
        yield this.leaveParty()
        throw error
      }
    }
    return this
  }

  get showPlayerControls() {
    return this.party.isHosted && this.masterPlaylist.length > 0
  }

  handleConnectionState = state => {
    this.logger.log('app.handleConnectionState', state)
    switch (state) {
      case connectionStates.open:
        this.isConnected = true
        this.alert.hide([alerts.connectionConnecting, alerts.connectionError])
        break
      case connectionStates.connecting:
        this.isConnected = false
        this.alert.hide([alerts.connectionConnected, alerts.connectionError])
        this.alert.show(alerts.connectionConnecting)
        break
      case connectionStates.closed:
        this.isConnected = false
        this.alert.hide([alerts.connectionConnecting, alerts.connectionConnected])
        if (this.party.isJoined) {
          this.alert.show(alerts.connectionError, this.reconnectAndReinitialize)
        } else {
          this.alert.hide([alerts.connectionConnecting, alerts.connectionConnected])
        }
        break
      case connectionStates.error:
        this.isConnected = false
        break
    }
    this.previousConnectionState = state
    return this
  };

  *reconnectAndReinitialize() {
    yield this.connection.connect()
    this.alert.hide()
    return this.initialize()
  }

  reactToUserListChanges = () => {
    return reaction(
      () => Array.from(this.userList.values()),
      users => this.updateUserList(users),
      {
        context: this,
        fireImmediately: true,
        delay: 100,
      }
    )
  }

  reactToMasterPlaylistChanges = () => {
    return reaction(
      () => this.playlistHandler.masterPlaylist,
      masterPlaylist => {
        this.updateMasterPlaylist(masterPlaylist)
      },
      {
        context: this,
        fireImmediately: true,
        delay: 1000,
      }
    )
  }

  reactToFilteredTracksChanges = () => {
    return reaction(
      () => this.playlistHandler.filteredTracks,
      filteredTracks => {
        this.updateFilteredTracks(filteredTracks)
      },
      {
        context: this,
        fireImmediately: true,
        delay: 1000,
      }
    )
  }

  reactToHostPlaylistChanges = () => {
    return reaction(
      () => this.playlist.list,
      tracks => {
        this.userPlaylists.set(this.auth.userId, tracks)
      },
      {
        context: this,
        fireImmediately: true,
        delay: 500,
      }
    )
  }

  reactToPlaylistChanges = () => {
    return reaction(
      () => [...this.playlist.list],
      tracks => this.updatePlaylist(tracks),
      {
        context: this,
        fireImmediately: true,
        delay: 500,
      }
    )
  }

  reactToPlayerChanges = () => {
    return reaction(
      () => this.player.heartbeatState,
      state => {
        this.sendHeartbeat(state)
      },
      {
        context: this,
        fireImmediately: true,
        delay: this.config.connection.heartbeat,
      }
    )
  }

  /**
   * Dispatch actions based on server messages
   *
   * @param {Object} message
   * @param {string} message.event
   * @param {Object} [message.data]
   * @param {number} [message.data.currentTime]
   * @param {string} [message.data.currentTrackId]
   * @param {boolean} [message.data.isPlaying]
   * @param {Array} [message.data.playlist]
   * @param {Array} [message.data.tracks]
   * @param {string} [message.data.type]
   * @param {Array} [message.data.user]
   * @param {string} [message.data.userId]
   * @param {Array} [message.data.users]
   * @param {string} [message.currentTrackId]
   * @param {int} [message.currentTrackTime]
   */
  handleConnectionMessage = message => {
    const { event, data } = message
    this.logger.log('app.handleConnectionMessage', event, data)
    if (this.party.isHosted) {
      // Host events
      switch (event) {
        case serverEvents.userJoined:
          this.userList.set(data.user.userId, data.user)
          this.userPlaylists.set(data.user.userId, [])
          this.logger.log('app.clientJoined', data, toJS(this.userList))
          break
        case serverEvents.userLeft:
          this.userPlaylists.delete(data.user.userId)
          this.userList.delete(data.user.userId)
          break
        case serverCommands.updatePlaylist:
          this.userPlaylists.set(data.userId, data.playlist)
          break
        default:
          if (!(event in serverCommands) && !(event in serverEvents)) {
            this.logger.log('app.handleConnectionMessage.unknownEvent', event, data)
          }
          break
      }
    } else {
      // Client events
      switch (event) {
        case serverCommands.heartbeat:
          this.player.heartbeat(data.isPlaying, data.currentTrackId, data.currentTime)
          this.logger.log('app.heartbeat', data)
          break
        case serverCommands.updateMasterPlaylist:
          this.logger.log('app.updateMasterPlaylist', data)
          this.masterPlaylist.replace(data.playlist)
          break
        case serverCommands.updateFilteredTracks:
          this.logger.log('app.updateFilteredTracks', data)
          this.tracksFiltered.replace(data.tracks)
          break
        case serverCommands.updateUserList:
          this.logger.log('app.updateUserList', data)
          this.userList.clear()
          Object.values(data.users).forEach(user => {
            this.userList.set(user.userId, user)
          })
          break
        case serverCommands.updatePartyOptions:
          this.party.setOptions(data)
          break
        case serverCommands.requestUpdate:
          this.updatePlaylist(this.playlist.toJS)
          break
        case serverEvents.partyLeft:
          this.connection.close()
          this.cleanupLeftParty()
          break
        case serverEvents.partyBannedUser:
          this.connection.close()
          this.alert.show(alerts.partyBannedFrom, this.party.partyId)
          this.cleanupLeftParty()
          break
        case serverEvents.partyEnded:
          this.alert.show(alerts.partyEnded)
          this.cleanupLeftParty()
          break
        // Blacklist events
        case serverCommands.addBlacklist:
        case serverCommands.removeBlacklist:
          this.alert.show(this.locale.translate(`alert.${event}.${data.type}`, data))
          break
        default:
          if (!(event in serverCommands) && !(event in serverEvents)) {
            this.logger.log('app.handleConnectionMessage.unknownEvent', event, data)
          }
          break
      }
    }
  };

  *authorize(providerId, authData) {
    this.logger.log('app.authorize', { providerId, authData })
    try {
      const auth = yield this.auth.authorize(providerId, authData)
      this.logger.log('App.authorize', { auth })
      return this.onAuthorize(auth)
    } catch (error) {
      this.logger.error('app.authorize error', error)
      this.alert.show(alerts.error, this.locale.translate('alert.' + error.message))
      throw error
    }
  }

  *logout() {
    const leaveOrEndParty = this.party.isHosted ? this.endParty : this.leaveParty
    try {
      yield leaveOrEndParty()
      yield this.auth.logout()
    } catch (error) {
      this.logger.error('app.logout', error)
    }
    this.auth.clear()
    this.spotify.clearUser()
    this.spotify.clearAuth()
    this.store.getKeys().forEach(storeKey => {
      if (storeKey.startsWith('musicSearch.')) {
        this.store.removeItem(storeKey)
      }
    })
    return this
  }

  onAuthorize = auth => {
    this.auth = auth
    this.spotify.setAuth(auth)
    this.spotify.setUser(auth.identity.providerUserId, auth.identity.userId)
    this.playlist.updateTrackIds(auth.userId)
    this.spotifyDeviceHandler.setAuth(auth)
    return auth
  };

  *startParty(partyId, partyPassword, partyOptions = {}) {
    this.logger.log('app.startParty', partyId, partyPassword, partyOptions)
    if (!this.auth.isLoggedIn) throw new Error('userNotLoggedInError')
    if (!this.auth.canHostParty) throw new Error('userCannotHostPartyError')
    try {
      yield this.connection.connect()
      const message = yield this.connection.sendAndAwaitEvent(
        serverCommands.startParty,
        {
          partyId,
          partyOptions: {
            ...partyOptions,
            partyPassword,
          },
          userId: this.auth.userId,
          playsomeToken: this.auth.playsomeToken,
        },
        [
          serverEvents.partyRejoined,
          serverEvents.partyStarted,
          serverEvents.userNotFoundError,
          serverEvents.userUnauthorizedError,
          serverEvents.partyLimitReachedError,
          serverEvents.partyNotFoundError,
          serverEvents.partyPasswordWrongError,
          serverEvents.partyOptionInvalidError,
        ]
      )
      if (message.event === serverEvents.partyStarted || message.event === serverEvents.partyRejoined) {
        const { userList, usersBanned = [] } = message.data

        this.userPlaylists = new ObservableMap()
        this.userPlaylists.set(this.auth.userId, [])
        this.party.host(partyId, partyPassword, partyOptions)
        this.userList.replace(userList.map(user => [user.userId, user]))
        this.banList.replace(usersBanned.map(user => [user.userId, user]))
        this.playlistHandler = new PlaylistHandler(this.userPlaylists, this.historyPlaylist, this.party, this.blackList)

        this.userListChangeDisposer = this.reactToUserListChanges()
        this.masterPlaylistChangeDisposer = this.reactToMasterPlaylistChanges()
        this.filteredTracksChangeDisposer = this.reactToFilteredTracksChanges()
        this.hostPlaylistChangeDisposer = this.reactToHostPlaylistChanges()
        this.playerReactionDisposer = this.reactToPlayerChanges()

        // Spotify device
        const spotifyDevice = yield this.spotifyDeviceHandler.restore()
        if (spotifyDevice) {
          yield this.player.setSpotifyDevice(spotifyDevice)
        }
        // Send initial playlist update when created
        if (message.event === serverEvents.partyStarted) {
          this.historyPlaylist.empty()
          this.updateMasterPlaylist(this.playlist.toJS)
          // Send update command when rejoined
        } else {
          this.connection.send(serverCommands.requestUpdate)
        }
        this.alert.show(alerts.partyHosted)
        return this.party
      } else {
        this.connection.close()
        throw message.event
      }
    } catch (error) {
      this.connection.close()
      throw error
    }
  }

  *joinParty(partyId, partyPassword) {
    this.logger.log('app.joinParty', partyId, partyPassword)
    if (!this.auth.isLoggedIn) throw new Error('userNotLoggedInError')
    try {
      yield this.connection.connect()
      const message = yield this.connection.sendAndAwaitEvent(
        serverCommands.joinParty,
        {
          partyId,
          partyPassword,
          userId: this.auth.userId,
          playsomeToken: this.auth.playsomeToken,
        },
        [
          serverEvents.partyJoined,
          serverEvents.partyUserLimitReachedError,
          serverEvents.userBannedError,
          serverEvents.userNotFoundError,
          serverEvents.userUnauthorizedError,
          serverEvents.partyNotFoundError,
          serverEvents.partyPasswordWrongError,
        ]
      )
      if (message.event === serverEvents.partyJoined) {
        const { userList, partyOptions } = message.data
        this.playlistReactionDisposer = this.reactToPlaylistChanges()
        // Use party response password to decide if there is a password or not
        this.party.join(partyId, partyOptions.partyPassword).setOptions(partyOptions)
        this.userList.clear()
        userList.forEach(user => {
          this.userList.set(user.userId, user)
        })
        this.updatePlaylist(this.playlist.toJS)
        this.alert.show(alerts.partyJoined, partyId)
        return this.party
      } else {
        this.connection.close()
        throw message.event
      }
    } catch (error) {
      this.logger.error('app.joinParty', error)
      this.connection.close()
      throw error
    }
  }

  *endParty() {
    if (!this.party.isJoined) return this.party
    const message = yield this.connection.sendAndAwaitEvent(serverCommands.endParty, null, [
      serverEvents.partyEnded,
      serverEvents.connectionClosed,
    ])
    this.logger.log('app.endParty')
    switch (message.event) {
      case serverEvents.partyEnded:
      case serverEvents.connectionClosed:
        return this.cleanupEndedParty()
    }
  }

  *leaveParty() {
    if (!this.party.isJoined) return this
    if (this.connection.isConnected) {
      const message = yield this.connection.sendAndAwaitEvent(serverCommands.leaveParty, null, [
        serverEvents.partyLeft,
        serverEvents.connectionClosed,
      ])
      switch (message.event) {
        case serverEvents.partyLeft:
        case serverEvents.connectionClosed:
          this.cleanupLeftParty()
          return this
      }
    } else {
      this.cleanupLeftParty()
      return this
    }
  }

  *cleanupEndedParty() {
    try {
      yield this.player.stop()
      this.player.reset()
    } catch (error) {
      this.logger.error('app.cleanupEndedParty', error)
    }
    this.party.leave()
    this.masterPlaylist.empty()
    this.historyPlaylist.empty()
    this.userList.clear()
    this.connection.close()
    if (this.userListChangeDisposer) this.userListChangeDisposer()
    if (this.masterPlaylistChangeDisposer) this.masterPlaylistChangeDisposer()
    if (this.filteredTracksChangeDisposer) this.filteredTracksChangeDisposer()
    if (this.hostPlaylistChangeDisposer) this.hostPlaylistChangeDisposer()
    if (this.playerReactionDisposer) this.playerReactionDisposer()
    this.playlistHandler = null
    return this
  }

  cleanupLeftParty = () => {
    this.party.leave()
    this.masterPlaylist.empty()
    this.historyPlaylist.empty()
    this.userList.clear()
    this.player.reset()
    this.connection.close()
    if (this.playlistReactionDisposer) this.playlistReactionDisposer()
    return this
  }

  get partyUrl() {
    const params = [this.party.partyId, this.party.partyPassword]
    return this.config.url.joinParty + params.join('/')
  }

  *banUser(userId) {
    const message = yield this.connection.sendAndAwaitEvent(serverCommands.banUser, { userId }, [
      serverEvents.userBanned,
      serverEvents.userNotFoundError,
      serverEvents.connectionClosed,
    ])
    const {
      event,
      data: { usersBanned = [] },
    } = message
    switch (event) {
      case serverEvents.userBanned:
        this.banList.replace(usersBanned.map(user => [user.userId, user]))
        return
      default:
        throw event
    }
  }

  *unbanUser(userId) {
    const message = yield this.connection.sendAndAwaitEvent(serverCommands.unbanUser, { userId }, [
      serverEvents.userUnbanned,
      serverEvents.userNotFoundError,
      serverEvents.connectionClosed,
    ])
    const {
      event,
      data: { usersBanned = [] },
    } = message
    switch (event) {
      case serverEvents.userUnbanned:
        this.banList.replace(usersBanned.map(user => [user.userId, user]))
        return
      default:
        throw event
    }
  }

  addToBlacklist(type, id, name, description) {
    this.logger.log('app.addToBlacklist', type, id, name, description)
    this.blackList.add(type, id, name, description)
    this.connection.send(serverCommands.addBlacklist, {
      type,
      name,
    })
    this.alert.show(this.locale.translate(`alert.addBlacklist.${type}`, { name }))
  }

  removeFromBlacklist(id) {
    const blackListItem = this.blackList.get(id)
    if (blackListItem) {
      const { type, name } = blackListItem
      this.blackList.remove(id)
      this.connection.send(serverCommands.removeBlacklist, {
        type,
        name,
      })
      this.alert.show(this.locale.translate(`alert.removeBlacklist.${type}`, { name }))
    }
  }

  updateMasterPlaylist(playlist) {
    let sendPlaylist = []
    if (playlist && playlist.length > 0) {
      sendPlaylist = playlist.map(track => toJS(track))
    }
    this.masterPlaylist.replace(playlist)
    this.connection.send(serverCommands.updateMasterPlaylist, {
      playlist: sendPlaylist,
    })
    return this
  }

  updateFilteredTracks(tracks) {
    this.tracksFiltered.replace(tracks)
    this.connection.send(serverCommands.updateFilteredTracks, {
      tracks,
    })
    // If current track is among filtered, skip to next
    if (this.player.isTrackPlaying(Object.keys(tracks))) {
      this.player.playNext()
    }
    return this
  }

  updatePlaylist(playlist) {
    this.connection.send(serverCommands.updatePlaylist, {
      userId: this.auth.userId,
      playlist,
    })
    return this
  }

  updatePartyOptions = (partyPassword, partyOptions) => {
    this.party.partyPassword = partyPassword
    this.party.setOptions(partyOptions)
    this.connection.send(serverCommands.updatePartyOptions, partyOptions)
    return this
  }

  updateUserList = users => {
    this.connection.send(serverCommands.updateUserList, { users })
    return this
  }

  sendHeartbeat(currentlyPlaying) {
    this.connection.send(serverCommands.heartbeat, currentlyPlaying)
    return this
  }
}
