import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import logger from 'services/logger'
import { dependencies } from 'config/enums'
import { withDependencies } from 'components/Context'
import Loader from 'components/common/Loader'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import Avatar from 'components/ui/Avatar'
import { AppIcon, ComputerIcon, PlayIcon, PhoneIcon, SpeakerIcon } from 'components/ui/icons'
import * as colors from 'styles/export/colors'

const SpotifyDevicePopup = props => {
  const { analytics, player, popup, spotifyDeviceHandler } = props
  const [loading, setLoading] = useState(false)
  const [deviceList, setDevicesList] = useState([])

  const loadDeviceList = useCallback(async () => {
    setLoading(true)
    let devices = []
    try {
      devices = await spotifyDeviceHandler.list()
      setDevicesList(devices)
    } catch (error) {
      //
    } finally {
      setLoading(false)
    }
    return devices
  }, [spotifyDeviceHandler])

  useEffect(() => {
    loadDeviceList()
  }, [loadDeviceList])

  const selectDevice = useCallback(
    async (type, deviceId) => {
      const playerType = type === 'web' ? type : 'other'
      setLoading(true)
      const spotifyDevice = await spotifyDeviceHandler.create(playerType, deviceId)
      if (spotifyDevice) {
        try {
          await player.setSpotifyDevice(spotifyDevice)
          player.play()
        } catch (error) {
          logger.error('SpotifyDevicePopup.selectDevice', error)
        }
        popup.hide()
      }
      setLoading(false)
      await analytics.events.selectDevice(type)
    },
    [analytics, player, popup, spotifyDeviceHandler]
  )

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <Text className="l-margins--h">{'general.select_player_device'}</Text>
      </PopupHeader>
      <PopupContent scrolling={true}>
        {loading ? (
          <div className="l-centeredChild">
            <div>
              <Loader />
            </div>
          </div>
        ) : deviceList.length > 0 ? (
          <ul className="List">
            {deviceList.map(device => (
              <li
                key={device.id}
                className="ListItem ListItem--clickable"
                onClick={() => selectDevice(device.type, device.id)}
              >
                <span className="ListItem__icon">
                  <Avatar backgroundColor={device.isActive ? colors.primary : null}>
                    <DeviceIcon type={device.type} active={device.isActive} />
                  </Avatar>
                </span>
                <span className="ListItem__content">
                  {device.name} ({device.type})
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <div className="l-margins">
            <Text html={true}>label.no_spotify_devices_found_message</Text>
          </div>
        )}
      </PopupContent>
      <PopupFooter className="l-columns">
        <button
          className="l-flex button--hollow button--primary"
          tabIndex={4}
          disabled={loading}
          onClick={loadDeviceList}
        >
          <Text>general.refresh</Text>
        </button>
        <button className="l-flex button--hollow button--secondary" tabIndex={5} onClick={popup.hide}>
          <Text>general.cancel</Text>
        </button>
      </PopupFooter>
    </PopupContainer>
  )
}

const DeviceIcon = props => {
  const { type, active } = props
  const iconProps = {
    className: classNames('Icon', active ? 'Icon--background' : 'Icon--primary'),
  }
  switch (type) {
    case 'Computer':
      return <ComputerIcon {...iconProps} />
    case 'Smartphone':
      return <PhoneIcon {...iconProps} />
    case 'Speaker':
      return <SpeakerIcon {...iconProps} />
    case 'web':
      return <AppIcon {...iconProps} />
    default:
      return <PlayIcon {...iconProps} />
  }
}

export default withDependencies(
  dependencies.analytics,
  dependencies.player,
  dependencies.popup,
  dependencies.spotifyDeviceHandler,
  dependencies.spotify
)(observer(SpotifyDevicePopup))
