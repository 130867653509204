import React from 'react'

import { alerts } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import Page from 'components/common/Page'

const Join = props => {
  const {
    alert,
    app,
    config,
    locale,
    party,
    history,
    match: {
      params: { partyId, partyPassword },
    },
  } = props
  party.leave().join(partyId, partyPassword)
  app
    .initialize()
    .catch(error => {
      alert.show(alerts.error, locale.translate('alert.' + error))
    })
    .finally(() => history.replace(config.routes.loggedIn))
  return <Page />
}

export default withDependencies(
  dependencies.alert,
  dependencies.app,
  dependencies.config,
  dependencies.locale,
  dependencies.party
)(Join)
