import React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { popupSizes } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import Page from 'components/common/Page'
import PartyHostPopup from 'components/common/popups/PartyHostPopup.jsx'
import PartyJoinPopup from 'components/common/popups/PartyJoinPopup.jsx'
import SharePopup from 'components/common/popups/SharePopup.jsx'
import UserPopup from 'components/common/popups/UserPopup.jsx'
import ProviderIcon from 'components/common/ProviderIcon'
import UserAvatar from 'components/common/UserAvatar'
import Avatar from 'components/ui/Avatar'
import Text from 'components/ui/Text'
import { WaitingToHostPartyBackdrop, WaitingToJoinPartyBackdrop } from 'components/common/PageBackdrops'
import { PersonOutlineIcon, ShareIcon } from 'components/ui/icons'

const People = props => {
  const { auth, app, browser, locale, party, popup, share, userList } = props

  const showJoinPartyPopup = () => {
    popup.show({
      component: <PartyJoinPopup />,
      size: popupSizes.small,
    })
  }
  const showHostPartyPopup = () => {
    popup.show({
      component: <PartyHostPopup host={true} />,
    })
  }

  const showSharePopup = () => {
    if (browser.isMobile && browser.hasShare) {
      const partyUrl = app.partyUrl
      const host = Array.from(userList.values()).shift()
      const hostName = host.name || host.id
      share
        .shareNative(
          partyUrl,
          locale.translate('label.share_message', { partyId: party.partyId, hostName }),
          locale.translate('label.share_title', { partyId: party.partyId, hostName })
        )
        .catch(() => {})
    } else {
      popup.show({
        component: <SharePopup />,
      })
    }
  }

  const handleUserClick = user => {
    popup.show({
      component: <UserPopup user={user} />,
    })
  }

  return (
    <Page contentClassName="l-columns">
      {userList.size > 0 ? (
        <ul className="List">
          {Array.from(userList.values()).map((user, index) => (
            <li
              key={'user_' + user.userId}
              className="ListItem ListItem--clickable"
              onClick={() => handleUserClick(user)}
            >
              <div className="ListItem__icon">
                <UserAvatar user={user} />
              </div>
              <div className="ListItem__content">
                <span className="t-ellipsis">{user.name || user.userId}</span>
                <span>
                  {index === 0 && <Text className="t-small u-color--primary l-margin--r">general.host</Text>}
                  {user.userId === auth.identity.userId && (
                    <Text className="t-small u-color--secondary">label.user_you</Text>
                  )}
                </span>
              </div>
              <div className="ListItem__icon">
                <Avatar>
                  <ProviderIcon colored={true} providerId={user.providerId} className="Icon" />
                </Avatar>
              </div>
            </li>
          ))}
        </ul>
      ) : party.isJoined ? (
        <div className="l-flex l-rows u-opacity-25">
          <div className="l-flex l-centeredChild">
            <h2 className="l-align--center">
              <PersonOutlineIcon className="Icon Icon--gargantuan" />
              <br />
              <Text className="t-largest">label.userlist_empty_message</Text>
            </h2>
          </div>
        </div>
      ) : (
        <div className="l-flex l-rows l-columns--landscape--sm">
          {auth.canHostParty && <WaitingToHostPartyBackdrop onClick={showHostPartyPopup} />}
          <WaitingToJoinPartyBackdrop onClick={showJoinPartyPopup} />
        </div>
      )}
      {party.isJoined && (
        <button
          className={classNames(
            'animated animated--zoomIn animated--pauseOnFocus button-action button--secondary',
            app.showPlayerControls && 'button-action--withControls'
          )}
          onClick={showSharePopup}
        >
          <ShareIcon className="Icon" />
        </button>
      )}
    </Page>
  )
}

export default withDependencies(
  dependencies.auth,
  dependencies.app,
  dependencies.browser,
  dependencies.locale,
  dependencies.party,
  dependencies.popup,
  dependencies.share,
  dependencies.userList
)(observer(People))
