import React from 'react'
import PropTypes from 'prop-types'
import Color from 'color'

import { stringToNumber } from 'functions/format'
import { withDependencies } from 'components/Context'
import Avatar, { AVATAR_LABEL_POSITIONS } from 'components/ui/Avatar'

const USER_AVATAR_LABEL_POSITIONS = {
  ...AVATAR_LABEL_POSITIONS,
  AVATAR_LABEL_NONE: 'AVATAR_LABEL_NONE',
}

const UserAvatar = props => {
  const { user, labelPosition, config, style, ...otherProps } = props
  if (user) {
    const userHue = stringToNumber(user.providerUserId, 360)
    const userColor = Color({
      h: userHue,
      s: config.users.avatarColorValues.s,
      l: config.users.avatarColorValues.l,
    }).alpha(config.users.avatarColorValues.a)

    return (
      <Avatar
        backgroundColor={userColor.toString()}
        style={style}
        src={user.imageUrl}
        label={labelPosition === USER_AVATAR_LABEL_POSITIONS.AVATAR_LABEL_NONE ? null : user.name || user.id}
        labelPosition={labelPosition === USER_AVATAR_LABEL_POSITIONS.AVATAR_LABEL_NONE ? null : labelPosition}
        {...otherProps}
      >
        {user.name || user.providerUserId}
      </Avatar>
    )
  } else {
    return null
  }
}
UserAvatar.propTypes = {
  user: PropTypes.object,
  labelPosition: PropTypes.oneOf([...Object.values(USER_AVATAR_LABEL_POSITIONS)]),
}
UserAvatar.defaultProps = {
  labelPosition: USER_AVATAR_LABEL_POSITIONS.AVATAR_LABEL_LEFT,
}

export default withDependencies('config')(UserAvatar)
export { USER_AVATAR_LABEL_POSITIONS }
