import React from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    fallback: PropTypes.node,
    onError: PropTypes.func,
  }

  static defaultProps = {
    active: true,
    onError: () => {},
  }

  state = {
    error: null,
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  resetError = () => {
    return new Promise(resolve => {
      this.setState(
        {
          error: null,
        },
        resolve
      )
    })
  }

  componentDidCatch(caught) {
    const { active, onError } = this.props
    if (active) {
      onError(caught)
    } else {
      throw caught
    }
  }

  render() {
    const { error } = this.state
    const { active, children, fallback } = this.props
    if (active && error) {
      return fallback
    } else {
      return children
    }
  }
}

export default ErrorBoundary
