import React from 'react'
import PropTypes from 'prop-types'

import { duration as formatDuration } from 'functions/format'

const Divider = () => <span className="t-ghosted">{' ● '}</span>

const propTypes = {
  track: PropTypes.object.isRequired,
}

const TrackNameArtistAlbumLine = props => {
  const { track, ...otherProps } = props
  return (
    <span {...otherProps}>
      {track.name}
      <Divider />
      {track.artists.map(artist => artist.name).join(', ')}
      <Divider />
      {track.album.name}
    </span>
  )
}
TrackNameArtistAlbumLine.propTypes = propTypes

const TrackDurationArtistsAlbumLine = props => {
  const { track, ...otherProps } = props
  return (
    <span {...otherProps}>
      {formatDuration(track.duration)}
      <Divider />
      {track.artists.map(artist => artist.name).join(', ')}
      <Divider />
      {track.album.name}
    </span>
  )
}
TrackDurationArtistsAlbumLine.propTypes = propTypes

const TrackArtistAlbumLine = props => {
  const { track, ...otherProps } = props
  return (
    <span {...otherProps}>
      {track.artists.map(artist => artist.name).join(', ')}
      <Divider />
      {track.album.name}
    </span>
  )
}
TrackArtistAlbumLine.propTypes = propTypes

export { TrackNameArtistAlbumLine, TrackDurationArtistsAlbumLine, TrackArtistAlbumLine }
