import React from 'react'
import { Router, Route, Redirect, Switch } from 'react-router-dom'

import { Auth, Intro, Join, Party, People, Playlist, Start } from 'components/pages'

const Routes = ({ history }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Intro} />
        <Route exact path="/auth/:providerId" component={Auth} />
        <Route exact path="/join/:partyId/:partyPassword?" component={Join} />
        <Route exact path="/party" component={Start} />
        <Route exact path="/party/playlist" component={Playlist} />
        <Route exact path="/party/party" component={Party} />
        <Route exact path="/party/people" component={People} />
        <Redirect to="/party" />
      </Switch>
    </Router>
  )
}

export default Routes
