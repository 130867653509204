import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'

import { trackFilterReasons } from 'config/enums'
import { useDependencies, dependencies } from 'components/Context'
import MusicSearchPopup from 'components/common/popups/MusicSearchPopup'
import Avatar from 'components/ui/Avatar'
import { PopupContainer, PopupHeader, PopupContent, popupSizes } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import { BlacklistIcon, CheckIcon, DeleteIcon, FilterIcon, SearchIcon } from 'components/ui/icons'
import * as colors from 'styles/export/colors'

const TrackPopup = props => {
  const { analytics, app, popup } = useDependencies(dependencies.analytics, dependencies.app, dependencies.popup)
  const { isHost, isOwnPlaylist, playlist, track, trackFiltered } = props

  const searchMusic = (searchType, searchQuery) => {
    popup.show({
      component: <MusicSearchPopup searchType={searchType} searchQuery={searchQuery} />,
      size: popupSizes.tall,
    })
    analytics.events.showPopup('music_search')
  }

  const deleteTrack = trackId => {
    playlist.removeUnique(trackId)
    popup.hide()
    analytics.events.removeTrack()
  }

  const blacklist = (type, id, name, description) => {
    app.addToBlacklist(type, id, name, description)
    popup.hide()
    analytics.events.addBlacklist(type)
  }

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <span className="l-margins--h">{track.name}</span>
      </PopupHeader>
      <PopupContent scrolling={true}>
        <div
          className="l-aspectRatioBox l-aspectRatioBox--1-1 u-bg--cover"
          style={{ backgroundImage: `url(${track.album.imageUrl})` }}
        />
        <ul className="List">
          {trackFiltered && (
            <li className="ListItem">
              {trackFiltered === trackFilterReasons.played ? (
                <Fragment>
                  <Avatar className="ListItem__icon">
                    <CheckIcon className="Icon Icon--primary" />
                  </Avatar>
                  <div className="ListItem__content">
                    <Text>{'trackFilters.reason.' + trackFiltered}</Text>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Avatar className="ListItem__icon">
                    <FilterIcon className="Icon Icon--secondary" />
                  </Avatar>
                  <div className="ListItem__content">
                    <Text className="t-ghosted">general.track_filtered_due_to</Text>
                    <Text>{'trackFilters.reason.' + trackFiltered}</Text>
                  </div>
                </Fragment>
              )}
            </li>
          )}
          <li
            className="ListItem ListItem--clickable"
            onClick={() => searchMusic('artist', track.artists.map(artist => artist.name).join(' '))}
          >
            <Avatar className="ListItem__icon" backgroundColor={colors.primary}>
              <SearchIcon className="Icon Icon--background" />
            </Avatar>
            <span className="ListItem__content">
              <Text>general.search_artist</Text>
              <strong className="t-ellipsis">{track.artists.map(artist => artist.name).join(', ')}</strong>
            </span>
          </li>
          <li className="ListItem ListItem--clickable" onClick={() => searchMusic('album', track.album.name)}>
            <Avatar className="ListItem__icon" backgroundColor={colors.primary}>
              <SearchIcon className="Icon Icon--background" />
            </Avatar>
            <span className="ListItem__content">
              <Text>general.search_album</Text>
              <strong className="t-ellipsis">{track.album.name}</strong>
            </span>
          </li>
          {isOwnPlaylist && (
            <li className="ListItem ListItem--clickable" onClick={() => deleteTrack(track.trackId)}>
              <Avatar className="ListItem__icon" backgroundColor={colors.secondary}>
                <DeleteIcon className="Icon Icon--background" />
              </Avatar>
              <Text className="ListItem__content">general.delete_track</Text>
            </li>
          )}
          {isHost &&
            !isOwnPlaylist &&
            track.artists.map(artist => (
              <li
                key={artist.id}
                className="ListItem ListItem--clickable"
                onClick={() => blacklist('artist', artist.id, artist.name)}
              >
                <Avatar className="ListItem__icon" backgroundColor={colors.secondary}>
                  <BlacklistIcon className="Icon Icon--background" />
                </Avatar>
                <span className="ListItem__content">
                  <span>
                    <Text>general.blacklist</Text> <Text>{'blackListTypes.artist'}</Text>
                  </span>
                  <strong className="t-ellipsis">{artist.name}</strong>
                </span>
              </li>
            ))}
          {isHost && !isOwnPlaylist && (
            <li
              className="ListItem ListItem--clickable"
              onClick={() =>
                blacklist(
                  'album',
                  track.album.id,
                  track.album.name,
                  track.artists.map(artist => artist.name).join(', ')
                )
              }
            >
              <Avatar className="ListItem__icon" backgroundColor={colors.secondary}>
                <BlacklistIcon className="Icon Icon--background" />
              </Avatar>
              <span className="ListItem__content">
                <span>
                  <Text>general.blacklist</Text> <Text>{'blackListTypes.album'}</Text>
                </span>
                <strong className="t-ellipsis">{track.album.name}</strong>
              </span>
            </li>
          )}
        </ul>
      </PopupContent>
    </PopupContainer>
  )
}
TrackPopup.propTypes = {
  isHost: PropTypes.bool,
  isOwnPlaylist: PropTypes.bool,
  playlist: PropTypes.object,
  track: PropTypes.object.isRequired,
  trackFiltered: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
TrackPopup.defaultProps = {
  isOwnPlaylist: false,
  isHost: false,
}

export default observer(TrackPopup)
