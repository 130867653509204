import React from 'react'

import Text from 'components/ui/Text'
import { HostPartyIcon, JoinPartyIcon, PlaylistAddIcon, QueueMusicIcon } from 'components/ui/icons'

const EmptyMasterPlaylistBackdrop = () => {
  return (
    <div className="l-flex l-centeredChild u-opacity-25 u-opacity--hover-50">
      <h2 className="l-align--center">
        <QueueMusicIcon className="Icon Icon--gargantuan" />
        <br />
        <Text>label.master_playlist_empty_message</Text>
      </h2>
    </div>
  )
}

const PlaylistEmptyBackdrop = ({ onClick }) => {
  return (
    <div className="l-flex l-flex--centered-child u-opacity-25 u-opacity--hover-50 u-clickable" onClick={onClick}>
      <h2 className="l-align--center">
        <PlaylistAddIcon className="Icon Icon--gargantuan" />
        <br />
        <Text className="t-largest">label.playlist_empty_message</Text>
      </h2>
    </div>
  )
}

const WaitingToHostPartyBackdrop = ({ onClick }) => {
  return (
    <div className="l-flex l-centeredChild u-opacity-25 u-opacity--hover-50 u-clickable" onClick={onClick}>
      <h2 className="l-align--center">
        <HostPartyIcon className="Icon Icon--gargantuan" />
        <br />
        <Text>label.waiting_to_host_party_message</Text>
      </h2>
    </div>
  )
}

const WaitingToJoinPartyBackdrop = ({ onClick }) => {
  return (
    <div className="l-flex l-centeredChild u-opacity-25 u-opacity--hover-50 u-clickable" onClick={onClick}>
      <h2 className="l-align--center">
        <JoinPartyIcon className="Icon Icon--gargantuan" />
        <br />
        <Text>label.waiting_to_join_party_message</Text>
      </h2>
    </div>
  )
}

export { EmptyMasterPlaylistBackdrop, PlaylistEmptyBackdrop, WaitingToHostPartyBackdrop, WaitingToJoinPartyBackdrop }
