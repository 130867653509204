import { alertLevels, alerts } from './enums'

const getNamedAlerts = (config, locale) => ({
  [alerts.connectionConnected]: () => ({
    id: alerts.connectionConnected,
    message: locale.translate('alert.connectionConnected'),
    timeout: config.alert.defaultTimeoutShort,
  }),
  [alerts.connectionConnecting]: () => ({
    id: alerts.connectionConnecting,
    message: locale.translate('alert.connectionConnecting'),
    timeout: config.alert.defaultTimeoutShort,
  }),
  [alerts.connectionError]: onClick => ({
    id: alerts.connectionError,
    level: alertLevels.ERROR,
    message: locale.translate('alert.connectionError'),
    timeout: 0,
    dismissable: false,
    onClick,
  }),
  [alerts.error]: message => ({
    level: alertLevels.ERROR,
    message,
    timeout: config.alert.defaultTimeoutLong,
    dismissable: true,
  }),
  [alerts.partyBannedFrom]: partyId => ({
    level: alertLevels.WARNING,
    message: locale.translate('alert.bannedFromParty', { partyId }),
    timeout: config.alert.defaultTimeoutLong,
    dismissable: true,
  }),
  [alerts.partyEnded]: () => ({
    id: alerts.partyEnded,
    level: alertLevels.WARNING,
    message: locale.translate('alert.partyEnded'),
    timeout: config.alert.defaultTimeoutLong,
    dismissable: true,
  }),
  [alerts.partyJoined]: partyId => ({
    id: alerts.partyJoined,
    message: locale.translate('alert.partyJoinedPartyId', { partyId }),
    timeout: config.alert.defaultTimeoutShort,
  }),
  [alerts.partyHosted]: () => ({
    id: alerts.partyHosted,
    message: locale.translate('alert.partyHosted'),
    timeout: config.alert.defaultTimeoutShort,
    dismissable: true,
  }),
})

export { alerts, getNamedAlerts }
