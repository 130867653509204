import React, { Fragment, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { alertLevels } from 'config/enums'
import AlertIcon from 'components/ui/AlertIcon'
import Text from 'components/ui/Text'

const TooltipContainer = props => {
  const { mode, className, children, ...otherProps } = props
  const classes = classNames('Tooltip__container', 'Tooltip__container--' + mode, className)
  return (
    <div className={classes} {...otherProps}>
      {children}
    </div>
  )
}
TooltipContainer.propTypes = {
  mode: PropTypes.oneOf(['focus']),
}

const Tooltip = props => {
  const { visible, type, direction, flipDirection, message, ...otherProps } = props

  const [flipped, setFlipped] = useState(false)
  const onMouseOver = useCallback(() => {
    setFlipped(flipDirection ? !flipped : false)
  }, [flipped, flipDirection])

  const classes = classNames(
    'Tooltip',
    !visible && 'Tooltip--hidden',
    'Tooltip--' + type,
    'Tooltip--' + (flipDirection && flipped ? flipDirection : direction)
  )
  const messageList = Array.isArray(message) ? message : [message]

  return (
    <div className={classes} {...otherProps} onMouseOver={onMouseOver}>
      {messageList.map(msg => (
        <Fragment key={msg}>
          <AlertIcon level={type} size={'small'} />
          &nbsp;{typeof msg === 'string' ? <Text>{msg}</Text> : msg}
        </Fragment>
      ))}
    </div>
  )
}
Tooltip.directions = {
  NORTH: 'north',
  EAST: 'east',
  SOUTH: 'south',
  WEST: 'west',
}
Tooltip.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.oneOf([...Object.values(alertLevels)]),
  direction: PropTypes.oneOf([...Object.values(Tooltip.directions)]),
  flipDirection: PropTypes.oneOf([...Object.values(Tooltip.directions)]),
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
}
Tooltip.defaultProps = {
  visible: true,
  type: alertLevels.ERROR,
  direction: 'south',
}

export { TooltipContainer, Tooltip }
