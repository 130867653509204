import React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import Avatar from 'components/ui/Avatar'
import { TrackDurationArtistsAlbumLine } from 'components/common/TrackLine'
import { PlaylistCheckIcon } from 'components/ui/icons'

const TrackList = props => {
  const { keyPrefix, tracks, checkPlaylist, onTrackClick } = props

  return (
    <ul className="List">
      {tracks.map(track => (
        <li
          key={keyPrefix + track.trackId}
          className={classNames(
            'ListItem',
            onTrackClick ? 'ListItem--clickable' : '',
            checkPlaylist && checkPlaylist.hasTrack(track.providerId, track.providerTrackId) ? 'u-opacity-25' : ''
          )}
          onClick={onTrackClick ? () => onTrackClick(track) : null}
        >
          <div className="ListItem__icon">
            {checkPlaylist && checkPlaylist.hasTrack(track.providerId, track.providerTrackId) ? (
              <Avatar src="" rounded={false}>
                <PlaylistCheckIcon className="Icon" />
              </Avatar>
            ) : (
              <Avatar src={track.album.thumbUrl} rounded={false} />
            )}
          </div>
          <div className="ListItem__content">
            <span className="t-ellipsis">{track.name}</span>
            <span className="t-ellipsis t-small">
              <TrackDurationArtistsAlbumLine track={track} />
            </span>
          </div>
        </li>
      ))}
    </ul>
  )
}
TrackList.defaultProps = {
  keyPrefix: 'trackList_',
}

export default observer(TrackList)
