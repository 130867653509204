function isEmail(value) {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value
  )
}

function isPartyId(value) {
  return /^\w[\w\-_.]{1,32}$/g.test(value)
}

function isPlaysomeUserId(value) {
  return /^[a-zåäöÅÄÖ0-9_\-.!?]{2,32}$/gi.test(value)
}

function isPartyPassword(value) {
  return /^[a-zåäö0-9]{1,32}$/gi.test(value)
}

function isStringOfLength(min = 0, max) {
  return value => typeof value === 'string' && value.length >= min && max !== undefined && value.length <= max
}

export { isEmail, isPartyId, isPlaysomeUserId, isStringOfLength, isPartyPassword }
