import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * Add long press events to a child component, preserving existing pointer events.
 *
 * <LongPressable
 *    threshold={1000}
 *    onLongPress={event => ...}
 *    onLongPressStart={event => ...}
 *    onLongPressCancel={event => ...}
 * >
 *   <button onPointerDown={event => ...}>Long press me!</button>
 * </LongPressable>
 */
const LongPressable = props => {
  const { onLongPress, onLongPressCancel, onLongPressStart, threshold, children } = props
  const [childEvents, setChildEvents] = useState({})
  const startLongPressRef = useRef()

  useEffect(() => {
    // Save children pointer events for triggering
    if (children) {
      const { onPointerDown, onPointerUp } = children.props
      setChildEvents({
        onPointerDown,
        onPointerUp,
      })
    } else {
      setChildEvents({})
    }
  }, [children])

  const handlePointerDown = useCallback(
    event => {
      startLongPressRef.current = Date.now()
      if (onLongPressStart) {
        onLongPressStart({
          ...event,
          type: 'longpressstart',
        })
      }
      // Run original onPointerDown function
      if (childEvents.onPointerDown) {
        childEvents.onPointerDown(event)
      }
    },
    [childEvents, onLongPressStart, startLongPressRef]
  )

  const handlePointerUp = useCallback(
    event => {
      const endLongPress = Date.now()
      if (startLongPressRef.current) {
        if (endLongPress - startLongPressRef.current >= threshold) {
          event.preventDefault()
          event.stopPropagation()
          onLongPress({
            ...event,
            type: 'longpress',
          })
          startLongPressRef.current = null
        } else {
          if (onLongPressCancel) {
            onLongPressCancel({
              ...event,
              type: 'longpresscancel',
            })
          }
          if (childEvents.onPointerUp) {
            childEvents.onPointerUp(event)
          }
        }
      }
    },
    [childEvents, onLongPress, onLongPressCancel, startLongPressRef]
  )

  if (children && (onLongPress || onLongPressStart || onLongPressCancel)) {
    return React.cloneElement(children, {
      onPointerDown: handlePointerDown,
      onPointerUp: handlePointerUp,
    })
  } else {
    return children
  }
}

LongPressable.propTypes = {
  children: PropTypes.element.isRequired,
  // Length of press in milliseconds to count as a long press
  threshold: PropTypes.number,
  // Callback for successful long press
  onLongPress: PropTypes.func,
  // Callback for long press started
  onLongPressStart: PropTypes.func,
  // Callback for long press ended before threshold
  onLongPressCancel: PropTypes.func,
}
LongPressable.defaultProps = {
  threshold: 1000,
}

export default LongPressable
