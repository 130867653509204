import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { dependencies } from 'config/enums'
import { withDependencies } from 'components/Context'
import { sentenceCase } from 'functions/format'
import Avatar from 'components/ui/Avatar'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import { BlacklistIcon, DeleteIcon } from 'components/ui/icons'

const BLACKLIST_STORE_KEY = 'blackListType'

const BlackListPopup = props => {
  const { app, blackList, config, popup, store } = props
  const [currentBlackListType, setCurrentBlackListType] = useState(props.store.getItem(BLACKLIST_STORE_KEY) || 'artist')
  const blackListTypes = Array.from(Object.values(config.blackList.types))
  const blackListCounts = blackList.countsByType
  const blackListItems = blackList.getList(currentBlackListType)

  const handleTypeClick = useCallback(
    blackListType => {
      setCurrentBlackListType(blackListType)
      store.setItem(BLACKLIST_STORE_KEY, blackListType)
    },
    [store]
  )

  const handleRemoveClick = useCallback(
    id => {
      app.removeFromBlacklist(id)
    },
    [app]
  )

  const handleClearAllClick = useCallback(
    type => {
      blackList.clear(type)
    },
    [blackList]
  )

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <Text className="l-margins--h">label.menu_blacklists</Text>
      </PopupHeader>
      <PopupContent className="l-rows">
        <ul className="Tabs">
          {blackListTypes.map(blackListType => (
            <li
              key={blackListType}
              className={classNames('Tabs__tab', currentBlackListType === blackListType && 'Tabs__tab--active')}
              onClick={() => handleTypeClick(blackListType)}
            >
              <span className="Badge Badge--sm" data-value={blackListCounts[blackListType] || 0}>
                <Text filter={sentenceCase}>{'blackListTypes.' + blackListType}</Text>
              </span>
            </li>
          ))}
        </ul>
        <div className="l-overflow--scroll-y l-flex--b100%">
          {blackListItems.length > 0 && (
            <ul className="List l-flex--fixed">
              {blackListItems.map(blackListItem => (
                <li
                  key={blackListItem.id}
                  className="ListItem ListItem--clickable"
                  onClick={() => handleRemoveClick(blackListItem.id)}
                >
                  <span className="ListItem__content">
                    <Text>{blackListItem.name}</Text>
                    {blackListItem.description && <span className="t-ghosted">{blackListItem.description}</span>}
                  </span>
                  <Avatar className="ListItem__icon">
                    <DeleteIcon className="Icon Icon--secondary" />
                  </Avatar>
                </li>
              ))}
            </ul>
          )}
          {blackListItems.length === 0 && (
            <div className="l-centeredChild">
              <div className="l-align--center u-opacity-25">
                <BlacklistIcon className="Icon Icon--huge" />
                <p>
                  <Text>general.no_blacklisted_items</Text>
                </p>
              </div>
            </div>
          )}
        </div>
      </PopupContent>
      <PopupFooter className="l-columns">
        <button
          type="button"
          className="l-flex l-flex--b50% button--hollow button--primary"
          tabIndex={4}
          disabled={blackListItems.length === 0}
          onClick={() => handleClearAllClick(currentBlackListType)}
        >
          <Text>general.clear</Text>
        </button>
        <button className="l-flex l-flex--b50% button--hollow button--secondary" tabIndex={5} onClick={popup.hide}>
          <Text>general.close</Text>
        </button>
      </PopupFooter>
    </PopupContainer>
  )
}

export default withDependencies(
  dependencies.app,
  dependencies.blackList,
  dependencies.config,
  dependencies.popup,
  dependencies.store
)(observer(BlackListPopup))
