import React from 'react'
import { observer } from 'mobx-react-lite'

import { alerts } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import ProviderIcon from 'components/common/ProviderIcon'
import UserAvatar, { USER_AVATAR_LABEL_POSITIONS } from 'components/common/UserAvatar'
import Avatar from 'components/ui/Avatar'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import { BlockIcon, DeleteIcon } from 'components/ui/icons'

const BanListPopup = props => {
  const { alert, app, banList, popup } = props

  const handleUnbanClick = userId => {
    app
      .unbanUser(userId)
      .then(() => {
        alert.show('alert.userUnbanned')
      })
      .catch(error => {
        alert.show(alerts.error, `alert.${error}`)
      })
    popup.hide()
  }

  const bannedUserIds = Array.from(banList.keys())
  const bannedUsers = Array.from(banList.values())

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <span className="l-margins--h">
          <Text>general.banned_users</Text>
        </span>
      </PopupHeader>
      {bannedUsers.length > 0 ? (
        <PopupContent scrolling={true}>
          <ul className="List">
            {bannedUsers.map(bannedUser => (
              <li
                key={bannedUser.userId}
                className="ListItem ListItem--clickable"
                onClick={() => handleUnbanClick(bannedUser.banId)}
              >
                <UserAvatar
                  className="ListItem__icon"
                  labelPosition={USER_AVATAR_LABEL_POSITIONS.AVATAR_LABEL_NONE}
                  user={bannedUser}
                />
                <span className="ListItem__content">
                  <span>
                    {bannedUser.name || bannedUser.userId}
                    <ProviderIcon providerId={bannedUser.providerId} colored={false} className="Icon l-margin--l" />
                  </span>
                  <span className="t-ghosted">{bannedUser.label}</span>
                </span>
                <Avatar className="ListItem__icon">
                  <DeleteIcon className="Icon Icon--secondary" />
                </Avatar>
              </li>
            ))}
          </ul>
        </PopupContent>
      ) : (
        <PopupContent className="l-flex--centered-child">
          <div className="l-align--center u-opacity-25 l-margins--v">
            <BlockIcon className="Icon Icon--huge" />
            <p>
              <Text>general.no_banned_users</Text>
            </p>
          </div>
        </PopupContent>
      )}
      <PopupFooter className="l-columns">
        <button
          type="button"
          className="l-flex l-flex--b50% button--hollow button--primary"
          tabIndex={4}
          disabled={bannedUsers.length === 0}
          onClick={() => handleUnbanClick(bannedUserIds)}
        >
          <Text>general.unban_all</Text>
        </button>
        <button className="l-flex l-flex--b50% button--hollow button--secondary" tabIndex={5} onClick={popup.hide}>
          <Text>general.close</Text>
        </button>
      </PopupFooter>
    </PopupContainer>
  )
}

export default withDependencies(
  dependencies.alert,
  dependencies.app,
  dependencies.banList,
  dependencies.popup
)(observer(BanListPopup))
