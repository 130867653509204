import React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { stopEventPropagation } from 'functions/events'
import ProviderIcon from 'components/common/ProviderIcon'
import Text from 'components/ui/Text'
import { CloseIcon, MenuIcon } from 'components/ui/icons'

const HeaderView = props => {
  const {
    auth,
    banList,
    blackList,
    closeSideMenu,
    party,
    showAboutPopup,
    showBanListPopup,
    showBlackListPopup,
    showEndOrLeaveConfirmation,
    showFeedbackPopup,
    showHelpPopup,
    showLogoutConfirmation,
    showPartyHostPopup,
    showPartyInfoPopup,
    showPartyJoinPopup,
    sideMenuOpen,
    toggleSideMenu,
  } = props
  return (
    <div className="Header">
      <div className="Header__bar l-columns">
        <div className="l-flex--shrink-0">
          <button
            className="button--hollow button--primary button--icon l-margin--r0.5"
            onClick={() => toggleSideMenu()}
            data-test="SideMenuButton"
          >
            {sideMenuOpen ? <CloseIcon className="Icon" /> : <MenuIcon className="Icon" />}
          </button>
        </div>
        <div className="l-flex">
          <h1>Playsome {party.isJoined && ' : ' + party.partyId}</h1>
        </div>
      </div>
      <div className={classNames('SideMenu__container', sideMenuOpen && 'SideMenu--open')} onClick={closeSideMenu}>
        <ul className="SideMenu" onClick={stopEventPropagation} data-test="SideMenu">
          <SideMenuItem visible={party.isJoined && party.isHosted} onClick={showPartyHostPopup}>
            <Text partyId={party.partyId} html>
              label.menu_party_hosted
            </Text>
          </SideMenuItem>
          <SideMenuItem visible={party.isJoined && !party.isHosted} onClick={showPartyInfoPopup}>
            <Text partyId={party.partyId} html>
              label.menu_party_joined
            </Text>
          </SideMenuItem>

          <SideMenuItem visible={party.isJoined && party.isHosted} onClick={showEndOrLeaveConfirmation}>
            <Text partyId={party.partyId}>general.end_party</Text>
          </SideMenuItem>
          <SideMenuItem visible={party.isJoined && !party.isHosted} onClick={showEndOrLeaveConfirmation}>
            <Text partyId={party.partyId}>general.leave_party</Text>
          </SideMenuItem>

          <SideMenuItem visible={!party.isJoined && auth.canHostParty} onClick={showPartyHostPopup}>
            <Text>general.host_party</Text>
          </SideMenuItem>
          <SideMenuItem visible={!party.isJoined} onClick={showPartyJoinPopup} data-test="SideMenu_joinParty">
            <Text>general.join_party</Text>
          </SideMenuItem>

          <SideMenuItem
            className="SideMenu__item--bordered"
            visible={party.isJoined && party.isHosted}
            onClick={showBlackListPopup}
          >
            <span
              className={classNames(blackList.size > 0 && 'Badge Badge--white-faded Badge--inline')}
              data-value={blackList.size}
            >
              <Text>label.menu_blacklists</Text>
            </span>
          </SideMenuItem>
          <SideMenuItem visible={party.isJoined && party.isHosted} onClick={showBanListPopup}>
            <span
              className={classNames(banList.size > 0 && 'Badge Badge--white-faded Badge--inline')}
              data-value={banList.size}
            >
              <Text>label.menu_banned_users</Text>
            </span>
          </SideMenuItem>

          <SideMenuItem
            className="SideMenu__item--bordered SideMenu__item--bottom"
            visible={auth.isLoggedIn}
            onClick={showLogoutConfirmation}
            data-test="SideMenu_logOut"
          >
            <span>
              <Text userName={auth.displayName}>label.menu_log_out_user</Text>
              <ProviderIcon providerId={auth.providerId} colored={false} className="Icon l-margin--l0.5" />
            </span>
          </SideMenuItem>

          <SideMenuItem className="SideMenu__item--bordered" onClick={showFeedbackPopup}>
            <Text>general.feedback_and_bugs</Text>
          </SideMenuItem>

          <SideMenuItem onClick={showHelpPopup}>
            <Text>general.help_and_faq</Text>
          </SideMenuItem>

          <SideMenuItem onClick={showAboutPopup}>
            <Text>general.about_playsome</Text>
          </SideMenuItem>
        </ul>
      </div>
    </div>
  )
}

const SideMenuItem = props => {
  const { visible, onClick, className, children, ...otherProps } = props
  return (
    visible && (
      <li className={classNames('SideMenu__item', className)} onClick={onClick} {...otherProps}>
        {children}
      </li>
    )
  )
}
SideMenuItem.defaultProps = {
  visible: true,
}

export default observer(HeaderView)
