import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { alertLevels } from 'config/enums'
import { TooltipContainer, Tooltip } from 'components/ui/Tooltip'

const TextInput = props => {
  const {
    button,
    className,
    containerClassName,
    disabled,
    error,
    icon,
    info,
    inputRef,
    label,
    onChange,
    type,
    ...otherProps
  } = props

  let tooltipType
  if (error) {
    tooltipType = alertLevels.ERROR
  } else if (info) {
    tooltipType = alertLevels.INFO
  }

  const TextInputElement = type === 'textarea' ? 'textarea' : 'input'

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Escape') {
        event.stopPropagation()
        event.preventDefault()
        onChange('')
      }
    },
    [onChange]
  )

  return (
    <div className={containerClassName}>
      {label && <label className={classNames(disabled && 'input--disabled')}>{label}</label>}
      <TooltipContainer mode="focus">
        <div className="l-columns">
          <div className="l-flex">
            <div className="input__iconContainer">
              <TextInputElement
                className={classNames(error && 'input--invalid', className)}
                disabled={disabled}
                onChange={onChange}
                onKeyDown={onKeyDown}
                type={type}
                ref={inputRef}
                {...otherProps}
              />
              {icon}
            </div>
          </div>
          <div className="l-flex l-flex--fixed">{button}</div>
        </div>
        <Tooltip
          visible={!!error || !!info || false}
          type={tooltipType}
          direction={Tooltip.directions.SOUTH}
          flipDirection={Tooltip.directions.NORTH}
          message={error || info || null}
        />
      </TooltipContainer>
    </div>
  )
}

TextInput.propTypes = {
  button: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  icon: PropTypes.node,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  inputRef: PropTypes.object,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['text', 'textarea']),
}

TextInput.defaultProps = {
  type: 'text',
}

export default TextInput
