import React from 'react'
import { observer } from 'mobx-react-lite'

import { partyOptions } from 'config/enums'
import { duration, lowercase, wrapWith } from 'functions/format'
import { withDependencies, dependencies } from 'components/Context'
import { PopupContainer, PopupHeader, PopupContent } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import { CheckIcon, CloseIcon } from 'components/ui/icons'

const PartyInfoPopup = props => {
  const { party, popup } = props
  const options = {
    [partyOptions.trackMaxLength]: party.getOption(partyOptions.trackMaxLength),
    [partyOptions.allowTrackDuplicates]: party.getOption(partyOptions.allowTrackDuplicates),
  }

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <Text className="l-margins--h">general.party</Text>
      </PopupHeader>
      <PopupContent className="l-paddings">
        <label>
          <Text>general.party_name</Text>
        </label>
        <p className="l-margin--t0">{party.partyId}</p>
        <label>
          <Text>general.party_password</Text>
        </label>
        <p className="l-margin--t0">
          {party.partyPassword || <Text filter={[lowercase, wrapWith('( ', ' )')]}>general.none</Text>}
        </p>
        <label>
          <Text>general.party_settings</Text>
        </label>
        <table className="table--rowSeparators t-lineHeight--2">
          <tbody>
            <tr>
              <td>
                <Text>trackFilters.name.trackMaxLength</Text>
              </td>
              <td className="l-align--right">
                {options[partyOptions.trackMaxLength] ? (
                  duration(options[partyOptions.trackMaxLength])
                ) : (
                  <Text>general.any</Text>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <Text>trackFilters.name.allowTrackDuplicates</Text>
              </td>
              <td className="l-align--right">
                {options[partyOptions.allowTrackDuplicates] ? (
                  <CheckIcon className="Icon Icon--success" />
                ) : (
                  <CloseIcon className="Icon Icon--error" />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </PopupContent>
    </PopupContainer>
  )
}

export default withDependencies(dependencies.party, dependencies.popup)(observer(PartyInfoPopup))
