import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { preventEventDefault, stopEventPropagation } from 'functions/events'
import { getMainScrollingElement } from 'functions/mainScrollingElement'
import { useRemSize } from 'hooks'
import { useDependencies, dependencies } from 'components/Context'
import { TrackDurationArtistsAlbumLine } from 'components/common/TrackLine'
import UserAvatar, { USER_AVATAR_LABEL_POSITIONS } from 'components/common/UserAvatar'
import Avatar from 'components/ui/Avatar'
import LongPressable from 'components/ui/LongPressable'
import ProgressCircle from 'components/ui/ProgressCircle'
import { CheckCircleIcon, DragHandleIcon, PlayIcon } from 'components/ui/icons'
import * as colors from 'styles/export/colors'

const DraghandleIcon = SortableHandle(() => {
  return <DragHandleIcon className="Icon Icon--large" />
})

const TrackItem = observer(props => {
  const { hasControls, hasRightIcon, metadata, onClick, onLongPress, onRightClick, selected, track, user } = props
  const handlePointer = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      if (event.pointerType === 'mouse') {
        if (event.button === 0 && (onClick || !onRightClick)) {
          onClick(track)
        }
        if (event.button === 2 && onRightClick) onRightClick(track)
      } else {
        if (onClick) onClick(track)
      }
    },
    [onClick, onRightClick]
  )
  const handleLongPress = useCallback(onLongPress ? () => onLongPress(track) : () => {}, [onLongPress, track])
  return (
    <LongPressable onLongPress={handleLongPress}>
      <li
        className={classNames(
          'ListItem',
          onLongPress || onClick || onRightClick || hasControls ? 'ListItem--clickable' : '',
          metadata?.classNames
        )}
        onClick={handlePointer}
        onContextMenu={preventEventDefault}
      >
        <div className="ListItem__icon">
          <LeftIcon selectable={hasControls} selected={selected} src={track.album.thumbUrl} track={track} />
        </div>
        <div className="ListItem__content">
          <span className="t-ellipsis">
            {metadata?.icons?.length > 0 && <span className="l-margin--r0.5">{metadata.icons}</span>}
            {track.name}
          </span>
          <span className="t-ellipsis t-small">
            <TrackDurationArtistsAlbumLine track={track} />
          </span>
        </div>
        {hasRightIcon && user && (
          <div className="ListItem__icon ListItem__icon--labeled--md" onClick={stopEventPropagation}>
            <UserAvatar user={user} labelPosition={USER_AVATAR_LABEL_POSITIONS.AVATAR_LABEL_LEFT_MD} />
            <span className="l-margin--l l-display--none l-display--inline-block--width--md l-max-width-10 t-ellipsis">
              {user.name || user.id}
            </span>
          </div>
        )}
        {hasControls && (
          <div className="ListItem__icon">
            <DraghandleIcon />
          </div>
        )}
      </li>
    </LongPressable>
  )
})

const LeftIcon = observer(props => {
  const { selectable, selected, src, track } = props
  const { player } = useDependencies(dependencies.player)
  const currentlyPlaying = player.currentTrack && player.currentTrack.trackId === track.trackId

  if (selectable && selected) {
    return (
      <Avatar className="u-bg--primary" rounded={false}>
        <CheckCircleIcon className="Icon" />
      </Avatar>
    )
  } else {
    if (currentlyPlaying) {
      return <CurrentlyPlayingIcon track={track} />
    } else {
      return <Avatar src={src} rounded={false} />
    }
  }
})

const CurrentlyPlayingIcon = observer(props => {
  const { track } = props
  const { player } = useDependencies(dependencies.player)
  const remSize = useRemSize()
  const currentlyPlayingProgress = player.currentTime ? player.currentTime / track.duration : 0
  return (
    <div className="MultiIcon">
      <PlayIcon className="Icon Icon--primary" />
      <ProgressCircle
        className="Icon Icon--stroke--primary"
        value={currentlyPlayingProgress}
        size={remSize * 3}
        thickness={remSize / 4}
        color={colors.primary}
      />
    </div>
  )
})

export const TrackListDisplay = observer(props => {
  const {
    className,
    hasControls,
    hasRightIcon,
    ItemComponent,
    metadata,
    onClick,
    onLongPress,
    onRightClick,
    playlist,
    userList,
  } = props
  const toggleSelected = useCallback(track => playlist.toggleSelected(track.trackId), [playlist])
  const itemProps = {
    hasControls,
    hasRightIcon,
    onLongPress,
    onClick: hasControls ? toggleSelected : onClick,
    onRightClick,
  }
  return (
    <ul className={classNames('List', className)}>
      {playlist.list.map((track, index) => (
        <ItemComponent
          key={track.trackId}
          index={index}
          metadata={metadata && track.trackId in metadata ? metadata[track.trackId] : null}
          RightControlIcon={null}
          selected={playlist.isSelected(track.trackId)}
          track={track}
          user={userList.get(track.userId)}
          {...itemProps}
        />
      ))}
    </ul>
  )
})
TrackListDisplay.propTyes = {
  className: PropTypes.string,
  hasControls: PropTypes.bool,
  hasRightIcon: PropTypes.bool,
  ItemComponent: PropTypes.node,
  metadata: PropTypes.object,
  onClick: PropTypes.func,
  onLongPress: PropTypes.func,
  onRightClick: PropTypes.func,
  playlist: PropTypes.array.isRequired,
  userList: PropTypes.object,
}
TrackListDisplay.defaultProps = {
  hasRightIcon: true,
}

export const SortableTrackItem = SortableElement(props => <TrackItem {...props} />)

export const SortableTrackListDisplay = SortableContainer(props => <TrackListDisplay {...props} />)

export const TrackList = props => {
  const { onSortStart, onSortEnd, ...otherProps } = props
  const { userList } = useDependencies(dependencies.userList)
  if (otherProps.hasControls) {
    return (
      <SortableTrackListDisplay
        getContainer={getMainScrollingElement}
        ItemComponent={SortableTrackItem}
        lockAxis="y"
        onSortEnd={onSortEnd}
        onSortStart={onSortStart}
        useDragHandle={true}
        userList={userList}
        {...otherProps}
      />
    )
  } else {
    return <TrackListDisplay ItemComponent={TrackItem} userList={userList} {...otherProps} />
  }
}
TrackList.propTypes = {
  onSortEnd: PropTypes.func,
  hasControls: PropTypes.bool,
}

export default TrackList
