import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Link } from 'react-router-dom'

import { popupSizes } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import DocPopup from 'components/common/popups/DocPopup'
import BasicPage from 'components/common/BasicPage'
import PlaysomeLogo from 'components/common/PlaysomeLogo'
import Text from 'components/ui/Text'

const Intro = props => {
  const { auth, config, popup } = props

  const showIntroPopup = () => {
    popup.show({
      component: <DocPopup docId={'what_is_playsome'} />,
      size: popupSizes.tall,
    })
  }

  if (auth && auth.isLoggedIn) {
    return <Redirect to={config.routes.loggedIn} />
  } else {
    return (
      <BasicPage>
        <div className="l-rows l-columns--landscape--sm l-height--100% l-max-width-40 l-margins--h-auto">
          <div className="Intro__logo__container l-flex l-flex--b50% l-flex--centered-child">
            <div className="l-margins l-align--center">
              <PlaysomeLogo className="Intro__logo__image" />
              <h1 className="Start__logo__subheading l-margin--t0">
                <Text>app.catchphrase</Text>
              </h1>
            </div>
          </div>
          <div className="l-flex l-flex--b50% l-flex--centered-child">
            <div className="l-margins">
              <Link to="/party" className="button button--primary button--fluid l-margin--b">
                <Text>general.try_it</Text>
              </Link>
              <button className="button button--fluid l-margin--b" onClick={showIntroPopup}>
                <Text>general.what_is_playsome</Text>
              </button>
            </div>
          </div>
        </div>
      </BasicPage>
    )
  }
}

export default withDependencies(dependencies.auth, dependencies.config, dependencies.popup)(observer(Intro))
