export default class Locale {
  lang = 'en'
  translations

  constructor(lang, translations) {
    this.lang = lang
    this.translations = translations
  }

  /**
   * Translate key to string, with parameter substitution.
   * Parameters in curly brackets are recursively translated.
   *
   * @param {string} key
   * @param {object} [params] - Substitution parameter map
   * @returns {string} - Translation if set, otherwise original key
   */
  translate(key, params) {
    if (key && this.translations && this.translations[key]) {
      let translation = this.translations[key]
      if (params) {
        for (let p in params) {
          if (p in params && params[p]) {
            if (params[p].startsWith('{') && params[p].endsWith('}')) {
              params[p] = this.translate(params[p].substring(0, params[p].length - 1).substring(1))
            }
            translation = translation.replace('{' + p + '}', params[p])
          }
        }
      }
      return translation
    }
    return key
  }
}
