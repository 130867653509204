import React from 'react'
import classNames from 'classnames'

import { alertLevels } from 'config/enums'
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from 'components/ui/icons'

function AlertIcon(props) {
  const { level, colored, size, className, ...otherProps } = props
  const classes = classNames('Icon', size && 'Icon--' + size, colored && 'Icon--' + level, className)
  switch (level) {
    case alertLevels.ERROR:
      return <ErrorIcon className={classes} {...otherProps} />
    case alertLevels.WARNING:
      return <WarningIcon className={classes} {...otherProps} />
    case alertLevels.SUCCESS:
      return <SuccessIcon className={classes} {...otherProps} />
    case alertLevels.INFO:
      return <InfoIcon className={classes} {...otherProps} />
    default:
      return null
  }
}
AlertIcon.defaultProps = {
  level: alertLevels.ERROR,
  size: null,
  colored: true,
}

export default AlertIcon
