class Analytics {
  constructor(gtag, config, logger) {
    this.gtag = gtag
    this.config = config
    this.logger = logger
  }

  isActive() {
    return this.gtag
  }

  page(page_path) {
    if (this.isActive()) {
      return new Promise(resolve => {
        try {
          this.gtag('config', this.config.ga_id, {
            page_path,
            event_callback: resolve,
          })
        } catch {
          resolve()
        }
      })
    } else {
      return Promise.resolve()
    }
  }

  view(screen_name) {
    if (this.isActive()) {
      return new Promise(resolve => {
        try {
          this.gtag('event', 'screen_view', {
            app_name: this.config.app_name,
            app_version: this.config.app_version,
            screen_name,
            event_callback: resolve,
          })
        } catch (error) {
          this.logger.warn('Analytics.view.error', error)
          resolve()
        }
      })
    } else {
      return Promise.resolve()
    }
  }

  event(action, event_label) {
    if (this.isActive()) {
      return new Promise(resolve => {
        try {
          this.gtag('event', action, {
            event_category: 'engagement',
            event_label,
            event_callback: resolve,
          })
        } catch {
          resolve()
        }
      })
    } else {
      return Promise.resolve()
    }
  }

  exception(description, fatal = false) {
    if (this.isActive() && this.config.exceptionSampleRate && Math.random() <= this.config.exceptionSampleRate) {
      return new Promise(resolve => {
        try {
          this.gtag('event', 'exception', {
            description,
            fatal,
            event_callback: resolve,
          })
        } catch {
          resolve()
        }
      })
    } else {
      return Promise.resolve()
    }
  }

  events = {
    addBlacklist: type => this.event(`blacklist_add:${type}`),
    addSearchedTrack: () => this.event('track_search_add'),
    editPlaylist: type => this.event(`playlist_edit:${type}`),
    endParty: () => this.event('party_end'),
    hostParty: settings => this.event(`party_host:${formatPartyOptions(settings)}`),
    joinParty: () => this.event('party_join'),
    leaveParty: () => this.event('party_leave'),
    shareParty: method => this.event(`party_share${method}`),
    login: providerId => this.event(`login:${providerId}`),
    logout: () => this.event('logout'),
    removeTrack: () => this.event('track_remove'),
    searchMusic: type => this.event(`music_search:${type}`),
    searchMoreMusic: offset => this.event(`music_search_more:${offset}`),
    selectDevice: type => this.event(`device_select:${type}`),
    showPopup: type => this.event(`popup_show:${type}`),
    updatePartyOptions: options => this.event(`party_update:${formatPartyOptions(options)}`),
  }
}

function formatPartyOptions(options) {
  if (Array.isArray(options)) {
    return options
      .filter(option => option)
      .map(option => option.toLocaleLowerCase())
      .sort()
      .join(',')
  } else {
    return ''
  }
}

export default Analytics
