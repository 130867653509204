const en = {
  'app.name': 'Playsome',
  'app.catchphrase': 'Playlist Democracy',
  'app.url': 'https://playsome.net',

  'general.#_tracks': '{count} tracks',
  'general.about_playsome': 'About Playsome',
  'general.add_music': 'Add music',
  'general.any': 'Any',
  'general.back': 'Back',
  'general.back_to_help': 'Back to Help',
  'general.ban_by_ip_address': '(Ban by IP address)',
  'general.ban_user': 'Ban user',
  'general.banned_users': 'Banned users',
  'general.blacklist': 'Blacklist',
  'general.cancel': 'Cancel',
  'general.clear': 'Clear',
  'general.close': 'Close',
  'general.connect': 'Connect',
  'general.delete_track': 'Delete track',
  'general.download': 'Download',
  'general.edit_playlist': 'Edit playlist',
  'general.email_optional': 'Email (optional)',
  'general.end': 'End',
  'general.end_party': 'End party',
  'general.error': 'Error',
  'general.export': 'Export',
  'general.export_playlist': 'Export playlist',
  'general.feedback_and_bugs': 'Feedback & bugs',
  'general.filter_playlists': 'Filter playlists',
  'general.import_all_tracks': 'Import all tracks',
  'general.invite_friends': 'Invite friends',
  'general.help': 'Help',
  'general.help_and_faq': 'Help & FAQ',
  'general.host': 'Host',
  'general.host_party': 'Host party',
  'general.hosted_party': 'Hosted party',
  'general.join': 'Join',
  'general.join_party': 'Join party',
  'general.joining_party': 'Joining party',
  'general.leave': 'Leave',
  'general.leave_party': 'Leave party',
  'general.cant_load_content': "Can't load content",
  'general.log_in': 'Log in',
  'general.log_in_anonymously_with_a_nickname': 'Log in anonymously with a nickname',
  'general.logged_in_as': 'Logged in as {userName}',
  'general.log_out': 'Log out',
  'general.log_in_with_provider': 'Log in with {providerId}',
  'general.log_out_from_provider': 'Log out from {providerId}',
  'general.message': 'message',
  'general.more': 'More',
  'general.next_up': 'Next up',
  'general.nickname': 'Nickname',
  'general.none': 'None',
  'general.no_blacklisted_items': 'No blacklisted items',
  'general.no_banned_users': 'No banned users',
  'general.nothing_found': 'Nothing found',
  'general.now_playing': 'Now playing',
  'general.or_log_in_with_social_account': '...or log in with social account',
  'general.party_partyId_joined': 'Party {partyId} joined',
  'general.party': 'Party',
  'general.party_address': 'Party address',
  'general.party_guests': 'Party guests',
  'general.party_host_guests': 'Party host / guests',
  'general.party_login': 'Party login',
  'general.party_name': 'Party name',
  'general.party_password': 'Party password',
  'general.party_password_optional': 'Party password (optional)',
  'general.party_settings': 'Party settings',
  'general.playlist_name': 'Playlist name',
  'general.playlist_description': 'Playlist description',
  'general.playsome_version': 'Playsome {version}',
  'general.privacy': 'Privacy',
  'general.qr_code': 'QR Code',
  'general.refresh': 'Refresh',
  'general.retry': 'Retry',
  'general.select_player_device': 'Select Spotify device',
  'general.search': 'Search',
  'general.search_album': 'Search album',
  'general.search_artist': 'Search artist',
  'general.search_music': 'Search music',
  'general.submit': 'Submit',
  'general.terms': 'Terms',
  'general.track_filtered_due_to': 'Track filtered due to:',
  'general.try_it': 'Try it',
  'general.type': 'Type',
  'general.unban_all': 'Unban all',
  'general.update': 'Update',
  'general.user_id': 'User ID',
  'general.user_profile': 'User profile',
  'general.what_is_playsome': 'What is Playsome?',

  'label.end_party_confirmation_text': 'Are you sure you want to end the party?',
  'label.exported_playlist_default_name': 'Playsome : {party_id}',
  'label.exported_playlist_default_description': 'From {appUrl} by {users}',
  'label.feedback_message':
    'Found any bugs? Have any ideas or wishes for features? Let me know!\n\nLeave an email address if you want to hear back from me.',
  'label.feedback_alternative':
    'Alternativeely, tweet at me: <a href="https://twitter.com/intent/tweet?text=@hertzahaeon&hashtags=playsome" target="_blank">@hertzahaeon</a>',
  'label.image_download_filename': 'playsome_{partyId}_qr.{fileExt}',
  'label.import_from_playlist': 'Import from Spotify',
  'label.leave_party_confirmation_text': 'Are you sure you want to leave the party?',
  'label.login_anonymously_nickname_info': 'Playsome needs a nickname to show by your selected music.',
  'label.logout_host_confirmation': 'Are you sure you want to log out and end your party?',
  'label.logout_client_confirmation': 'Are you sure you want to log out and leave the party?',
  'label.master_playlist_empty_message': 'Waiting for someone to add music',
  'label.menu_party_hosted': 'Hosted: <strong>{partyId}</strong>',
  'label.menu_party_joined': 'Joined: <strong>{partyId}</strong>',
  'label.menu_blacklists': 'Blacklisted music',
  'label.menu_banned_users': 'Banned users',
  'label.menu_log_out_user': 'Log out {userName}',
  'label.no_spotify_devices_found_message':
    '<p>No Spotify devices found.</p><p><a href="https://www.spotify.com/se/connect/" target="_blank">Connect to a Spotify device</a> and refresh.</p>',
  'label.party_hosted': 'Hosted: {partyId}',
  'label.party_joined': 'Joined: {partyId}',
  'label.playlist_empty_message': 'Add your music',
  'label.share_message': 'Join the {partyId} party hosted by {hostName} at Playsome.net!',
  'label.share_title': 'Invitation to join {partyId} by {hostName} at Playsome.net',
  'label.user_you': 'You!',
  'label.userlist_empty_message': 'No one here...',
  'label.waiting_to_host_party_message': 'Host a party',
  'label.waiting_to_join_party_message': 'Join a party',

  'authProvider.title.facebook': 'Facebook',
  'authProvider.title.google': 'Google',
  'authProvider.title.playsome': 'Playsome',
  'authProvider.title.spotify': 'Spotify',
  'authProvider.loginLabel.facebook': 'Facebook',
  'authProvider.loginLabel.google': 'Google',
  'authProvider.loginLabel.playsome': 'Log in anonymously',
  'authProvider.loginLabel.spotify': 'Log in with Spotify',

  'blackListTypes.artist': 'artist',
  'blackListTypes.album': 'album',

  'searchtypes.album': 'Album',
  'searchtypes.artist': 'Artist',
  'searchtypes.track': 'Track',

  'trackFilters.reason.played': 'Played',
  'trackFilters.name.trackMaxLength': 'Max track length',
  'trackFilters.reason.trackMaxLength': 'Max track length exceeded',
  'trackFilters.name.blacklistedArtist': 'Artist blacklist',
  'trackFilters.reason.blacklistedArtist': 'Artist blacklisted',
  'trackFilters.name.blacklistedAlbum': 'Album blacklist',
  'trackFilters.reason.blacklistedAlbum': 'Album blacklisted',
  'trackFilters.name.allowTrackDuplicates': 'Allow duplicate tracks',
  'trackFilters.reason.allowTrackDuplicates': 'Duplicate track',

  'shareProviders.name.email': 'Email',
  'shareProviders.name.messenger': 'Messenger',
  'shareProviders.name.telegram': 'Telegram',
  'shareProviders.name.whatsapp': 'Whatsapp',

  'alert.addBlacklist.album': 'Album "{name}" blacklisted',
  'alert.addBlacklist.artist': 'Artist "{name}" blacklisted',
  'alert.addToHomeScreen': 'Add Playsome to your home screen for a better, app-like experience!',
  'alert.bannedFromParty': 'Banned from party {partyId}!',
  'alert.connectionClosed': 'Server connection closed.',
  'alert.connectionConnected': 'Connected to server!',
  'alert.connectionConnecting': 'Connecting to server...',
  'alert.connectionError': 'Server connection error. Tap to reconnect.',
  'alert.copied_to_clipboard': 'Copied to clipboard',
  'alert.feedback_thanks': 'Thank you for your feedback!.',
  'alert.loginError': 'Could not login. Please try again.',
  'alert.loginWindowBlockedError': 'Could not open login. Please allow popups in your browser and try again.',
  'alert.musicSearchError': 'Search error!',
  'alert.offline': "Can't find the internet. Connect and try again.",
  'alert.partyEnded': 'Party ended.',
  'alert.partyHosted': 'Party hosted!',
  'alert.partyJoined': 'Party joined!',
  'alert.partyJoinedPartyId': 'Joined party: {partyId}',
  'alert.partyLeft': 'Party left.',
  'alert.partyLimitReachedError': 'Party server is too busy, try again soon. Sorry!',
  'alert.partyNotFoundError': 'Party not found',
  'alert.partyPasswordWrongError': "Wrong password. Please try again. (It's case sensitive!)",
  'alert.partyOptionsUpdated': 'Party settings updated',
  'alert.partyStarted': 'Party started',
  'alert.partyUserLimitReachedError': 'Party is full!',
  'alert.playlistExported': 'Playlist exported.',
  'alert.playlistExportError': 'Playlist export failed.',
  'alert.playlistLoadError': "Couldn't load playlist",
  'alert.removeBlacklist.album': 'Album "{name}" removed from blacklist',
  'alert.removeBlacklist.artist': 'Artist "{name}" removed from blacklist',
  'alert.serverOffline': 'Server is offline. Tap to retry.',
  'alert.serverOnline': 'Server is online!',
  'alert.tooFrequentRequestsError': "You're doing that too frequently. Back off and try again soon.",
  'alert.unknownAuthError': 'A login error occurred. Try again.',
  'alert.unknownError': 'Some kind of error occurred',
  'alert.userBanned': 'User banned from party.',
  'alert.userBannedError': "You're banned from this party.",
  'alert.userCannotHostPartyError': 'Party hosting not available for current login.',
  'alert.userExistsError': 'Username taken.',
  'alert.userNotFoundError': "You've been logged out.",
  'alert.userUnauthorizedError': 'Login expired.',
  'alert.userUnbanned': 'User unbanned from party.',

  'validation.emailExpectedError': 'Email required',
  'validation.nameExpectedError': 'Name required',
  'validation.partyIdExpectedError': '2-32 characters required (letters, numbers, underscore, dash, period)',
  'validation.partyPasswordExpectedError': '0-32 characters required (letters & numbers)',
  'validation.playsomeUserIdExpectedError': '2-32 characters required (letters, numbers, underscore, dash, period)',
  'validation.valueRequiredError': 'Required',

  'page.windowTitle': 'Playsome : {pageTitle}',
  'page.title.start': 'Start',
  'page.title.playlist': 'Playlist',
  'page.title.party': 'Party',
  'page.title.people': 'People',
}

export default en
