import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { alerts } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import Loader from 'components/common/Loader'
import TrackList from 'components/common/TrackList'
import Avatar from 'components/ui/Avatar'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import TextInput from 'components/ui/TextInput'
import { ChevronLeftIcon, CloseIcon } from 'components/ui/icons'

const PlaylistImportPopup = props => {
  const { locale, playlist, popup, spotify } = props
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [playlists, setPlaylists] = useState([])
  const [filteredPlaylists, setFilteredPlaylists] = useState([])
  const [currentPlaylist, setCurrentPlaylist] = useState(null)

  // Initial playlist load
  useEffect(() => {
    getAllPlaylists()
  }, [])

  const getAllPlaylists = useCallback(
    async (offset = 0, limit = 50) => {
      setIsLoading(true)
      let loadedPlaylists = []
      let hasMoreResults = true
      while (hasMoreResults) {
        const result = await spotify.getPlaylists(offset || loadedPlaylists.length, limit)
        loadedPlaylists = [...loadedPlaylists, ...result.items]
        hasMoreResults = result.hasMoreTracks
      }
      setPlaylists(loadedPlaylists)
      setIsLoading(false)
    },
    [spotify]
  )

  useEffect(() => {
    const newFilteredPlaylists =
      filter.length > 0
        ? playlists.filter(playlist => playlist.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
        : playlists
    setFilteredPlaylists(newFilteredPlaylists)
  }, [playlists, filter])

  const handleFilterUpdate = event => {
    setFilter(event.target.value)
  }

  const handleFilterReset = () => {
    setFilter('')
  }

  const showPlaylist = playlist => {
    setIsLoading(true)
    spotify
      .getPlaylistTracks(playlist.providerPlaylistId, playlist.ownerId)
      .then(tracks => {
        setCurrentPlaylist({
          ...playlist,
          tracks,
        })
      })
      .catch(() => {
        alert.show(alerts.error, 'alert.playlistLoadError')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const backToPlaylists = () => {
    setCurrentPlaylist(null)
  }

  const addTrack = track => {
    // Regenerate track id
    track = Object.assign(track, {
      trackId: spotify.generateTrackId(track.providerId),
    })
    playlist.add(track)
  }

  const importPlaylist = providerPlaylistId => {
    setIsLoading(true)
    spotify.getPlaylistTracks(providerPlaylistId).then(tracks => {
      setIsLoading(false)
      playlist.append(tracks)
      popup.hide()
    })
  }

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <Text className="l-margins--h">label.import_from_playlist</Text>
      </PopupHeader>
      <PopupContent className="l-rows" scrolling={false}>
        {isLoading ? (
          <div className="l-flex l-flex--centered-child">
            <div>
              <Loader />
            </div>
          </div>
        ) : playlists.length ? (
          currentPlaylist ? (
            <CurrentPlaylist
              tracks={currentPlaylist.tracks}
              checkPlaylist={playlist}
              currentPlaylistName={currentPlaylist.name}
              onTrackClick={addTrack}
              backToPlaylists={backToPlaylists}
            />
          ) : (
            <PlaylistsList
              locale={locale}
              playlists={filteredPlaylists}
              filter={filter}
              onChangeFilter={handleFilterUpdate}
              onResetFilter={handleFilterReset}
              showPlaylist={showPlaylist}
            />
          )
        ) : (
          <div className="l-flex l-flex--centered-child">
            <Text>general.nothing_found</Text>
          </div>
        )}
      </PopupContent>
      <PopupFooter className="l-columns">
        {currentPlaylist ? (
          <button
            className="button--hollow button--primary l-flex l-flex--b50%"
            onClick={() => importPlaylist(currentPlaylist.providerPlaylistId, currentPlaylist.ownerId)}
          >
            <Text>general.import_all_tracks</Text>
          </button>
        ) : (
          <div className="l-flex l-flex--b50%" />
        )}
        <button key="popupClose" className="button--hollow button--secondary l-flex l-flex--b50%" onClick={popup.hide}>
          <Text>general.close</Text>
        </button>
      </PopupFooter>
    </PopupContainer>
  )
}

const CurrentPlaylist = props => {
  const { tracks, checkPlaylist, currentPlaylistName, backToPlaylists, onTrackClick } = props
  return (
    <div className="l-rows l-height--100% l-width--100%">
      <div className="l-flex l-flex--fixed l-columns l-margin--r">
        <div className="l-flex l-flex--fixed">
          <button className="button--icon button--hollow" onClick={backToPlaylists}>
            <ChevronLeftIcon className="Icon" />
          </button>
        </div>
        <div className="l-flex__spacer" />
        <div className="l-flex l-overflow--hidden">
          <h4 className="t-lineHeight--large t-ellipsis l-margins--0">{currentPlaylistName}</h4>
        </div>
      </div>
      <div className="l-flex l-overflow--scroll-y">
        <TrackList tracks={tracks} checkPlaylist={checkPlaylist} onTrackClick={onTrackClick} />
      </div>
    </div>
  )
}

const PlaylistsList = observer(props => {
  const { locale, playlists, filter, onChangeFilter, onResetFilter, showPlaylist } = props
  return (
    <Fragment>
      <div className="l-margins--h">
        <TextInput
          label={locale.translate('general.filter_playlists')}
          icon={
            <CloseIcon
              className={classNames(
                'Icon u-color--secondary--hover u-clickable',
                filter.length === 0 && 'l-display--none'
              )}
              onClick={onResetFilter}
            />
          }
          value={filter}
          onChange={onChangeFilter}
        />
      </div>
      <div className="l-overflow--scroll-y">
        <ul className="List">
          {playlists.map(playlist => (
            <li
              key={playlist.providerPlaylistId}
              className="ListItem ListItem--clickable"
              onClick={() => showPlaylist(playlist)}
            >
              <div className="ListItem__icon">
                <Avatar src={playlist.thumbUrl} rounded={false} />
              </div>
              <div className="ListItem__content">
                <strong className="t-ellipsis">{playlist.name}</strong>
                {playlist.trackCount && (
                  <Text className="t-ghosted t-ellipsis" count={playlist.trackCount.toString()}>
                    general.#_tracks
                  </Text>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  )
})

export default withDependencies(
  dependencies.alert,
  dependencies.app,
  dependencies.locale,
  dependencies.playlist,
  dependencies.popup,
  dependencies.spotify
)(observer(PlaylistImportPopup))
