import React from 'react'
import PropTypes from 'prop-types'

import { EmailIcon, MessengerIcon, TelegramIcon, WhatsappIcon } from 'components/ui/icons'
import * as providerColors from 'styles/export/providerColors'

const ShareIcon = props => {
  const { shareProviderId, color, ...otherProps } = props
  if (color === true) {
    otherProps.color = providerColors[shareProviderId] || null
  } else if (typeof color === 'string') {
    otherProps.color = color
  }
  switch (shareProviderId) {
    case 'email':
      return <EmailIcon {...otherProps} />
    case 'messenger':
      return <MessengerIcon {...otherProps} />
    case 'telegram':
      return <TelegramIcon {...otherProps} />
    case 'whatsapp':
      return <WhatsappIcon {...otherProps} />
    default:
      return null
  }
}
ShareIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  shareProviderId: PropTypes.string.isRequired,
}
ShareIcon.defaultProps = {
  color: true,
}

export default ShareIcon
