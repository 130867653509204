import React from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'qs'

import { alerts } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import Page from 'components/common/Page'

const Auth = props => {
  const {
    alert,
    app,
    config,
    locale,
    match: {
      params: { providerId },
    },
    location,
    history,
  } = props
  const params = location.search
    ? qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })
    : {}
  if ('code' in params) {
    props.app
      .authorize(providerId, {
        ...params,
        redirect_uri: window.location.origin + location.pathname,
      })
      .then(() => app.initialize())
      .then(() => history.push(config.routes.loggedIn))
      .catch(error => {
        alert.show(alerts.error, locale.translate('alert.' + error))
        history.push(config.routes.logIn)
      })
      .finally()
  }
  return <Page />
}

export default withDependencies(
  dependencies.alert,
  dependencies.app,
  dependencies.config,
  dependencies.locale
)(withRouter(Auth))
