import { makeAutoObservable, reaction, toJS } from 'mobx'

export default class BlackList {
  list = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  persist(store, storageKey = 'blackList') {
    this.store = store
    const persistedList = this.store.getItem(storageKey)
    if (persistedList) {
      try {
        this.list.replace(new Map(persistedList))
      } catch {
        // Fail silently
      }
    }
    this.persistDisposer = reaction(
      () => toJS(this.list),
      value => {
        this.store.setItem(storageKey, Array.from(value.entries()))
      }
    )
    return this
  }

  unPersist() {
    this.store = undefined
    if (this.persistDisposer) {
      this.persistDisposer()
      this.persistDisposer = undefined
    }
    return this
  }

  get size() {
    return this.list.size
  }

  get idsByType() {
    const idList = {}
    this.list.forEach(({ type }, id) => {
      if (type in idList) {
        idList[type].push(id)
      } else {
        idList[type] = [id]
      }
    })
    return idList
  }

  get countsByType() {
    const counts = {}
    this.list.forEach(({ type }) => {
      if (type in counts) {
        counts[type] += 1
      } else {
        counts[type] = 1
      }
    })
    return counts
  }

  has(id) {
    return this.list.has(id)
  }

  get(id) {
    return this.list.get(id)
  }

  add(type, id, name, description) {
    if (!this.has(id)) {
      this.list.set(id, { type, name, description })
    }
    return this
  }

  remove(id) {
    this.list.delete(id)
  }

  clear(type) {
    if (type === undefined) {
      this.list.clear()
    } else {
      this.list = this.list.replace(this.list.entries().filter(item => item.type !== type))
    }
    return this
  }

  getList(filterType) {
    return Array.from(this.list.entries())
      .map(([id, data]) => {
        if (filterType === undefined || filterType === data.type) {
          return { id, ...data }
        } else {
          return null
        }
      })
      .filter(item => item !== null)
  }
}
