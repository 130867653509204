import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AlertIcon from './AlertIcon'
import Text from './Text'
import { CloseIcon } from './icons'

const Alert = props => {
  const { alert, className, ...otherProps } = props
  return (
    <div
      className={classNames(
        'Alert',
        alert && 'Alerts--' + alert.level,
        alert && alert.onClick && 'u-clickable',
        className
      )}
      onClick={alert && alert.onClick ? alert.onClick : null}
      {...otherProps}
    >
      <AlertIcon level={alert.level} className="Alert__icon" />
      <div className="Alert__message">
        {typeof alert.message === 'string' ? <Text>{alert.message}</Text> : alert.message}
      </div>
      {alert.dismissable && <CloseIcon className="Alert__close-icon" onClick={() => alert.hide()} />}
    </div>
  )
}

Alert.propTypes = {
  alert: PropTypes.shape({
    dismissable: PropTypes.bool,
    hide: PropTypes.func,
    level: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
  }),
  className: PropTypes.string,
}
Alert.defaultProps = {
  className: '',
}

export default Alert
