/* global PRODUCTION */
import React, { Fragment, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { dependencies, popupSizes } from 'config/enums'
import { withDependencies } from 'components/Context'
import PlayingPopup from 'components/common/popups/PlayingPopup'
import SpotifyDevicePopup from 'components/common/popups/SpotifyDevicePopup'
import { TrackNameArtistAlbumLine } from 'components/common/TrackLine'
import Avatar from 'components/ui/Avatar'
import TimeLine from 'components/common/TimeLine'
import { PlayCircleIcon, PauseCircleIcon, SkipIcon } from 'components/ui/icons'

const PLAYING_POPUP_ID = 'PLAYING_POPUP'

const PlayerControls = props => {
  const { analytics, masterPlaylist, player, popup, wakeLock } = props
  const visible = masterPlaylist.length > 0 && popup.currentId !== PLAYING_POPUP_ID

  // Toggle wake lock on document visibility change
  useEffect(() => {
    if (wakeLock) {
      document.addEventListener('visibilitychange', onVisibilityChange)
      return () => {
        document.removeEventListener('visibilitychange', onVisibilityChange)
      }
    }
  })

  const onVisibilityChange = useCallback(
    async event => {
      const document = event.target
      if (document?.visibilityState === 'visible') {
        if (player.isPlaying) await wakeLock.enable()
      } else {
        await wakeLock.release()
      }
    },
    [player, wakeLock]
  )

  const showDevices = useCallback(() => {
    popup.show({
      component: <SpotifyDevicePopup />,
      size: popupSizes.small,
    })
  }, [popup])

  const showPlaying = useCallback(async () => {
    popup.show({
      id: PLAYING_POPUP_ID,
      component: <PlayingPopup showDevices={showDevices} togglePlayPause={togglePlayPause} />,
      size: popupSizes.tall,
    })
    await analytics.events.showPopup('playing')
  }, [analytics, popup])

  const togglePlayPause = useCallback(async () => {
    if (player.spotifyDevice) {
      await player.toggle()
      if (wakeLock) {
        if (player.isPlaying) {
          wakeLock.enable()
        } else {
          wakeLock.release()
        }
      }
    } else {
      showDevices()
    }
  }, [player, showDevices, wakeLock])

  const handlePlayPause = useCallback(
    event => {
      event.stopPropagation()
      return togglePlayPause()
    },
    [togglePlayPause]
  )

  return (
    visible && (
      <div className="PlayerControls l-columns u-clickable" onClick={showPlaying}>
        <div className="l-flex l-flex--fixed">
          {player.currentTrack ? (
            <Avatar className="l-margins--h l-margins--v0.5" src={player.currentTrack.album.thumbUrl} />
          ) : (
            <Avatar className="l-margins--h l-margins--v0.5" />
          )}
        </div>
        <div className="l-flex l-rows l-margins--v0.5 l-overflow--hidden">
          {player.currentTrack && (
            <Fragment>
              <p className="l-margins--v0 t-ellipsis">
                <TrackNameArtistAlbumLine track={player.currentTrack} />
              </p>
              <TimeLine />
            </Fragment>
          )}
        </div>
        <div className="l-flex l-flex--fixed l-margins--v0.5">
          {!PRODUCTION && (
            <button
              className="button--icon button--large button--hollow"
              onClick={event => {
                event.stopPropagation()
                player.playNext()
              }}
            >
              <SkipIcon className="Icon Icon--larger" />
            </button>
          )}
          <button className="button--icon button--large button--hollow l-margin--r0.5" onClick={handlePlayPause}>
            {player.isPlaying ? (
              <PauseCircleIcon className="Icon Icon--larger" />
            ) : (
              <PlayCircleIcon className="Icon Icon--larger" />
            )}
          </button>
        </div>
      </div>
    )
  )
}

export default withDependencies(
  dependencies.analytics,
  dependencies.masterPlaylist,
  dependencies.player,
  dependencies.popup,
  dependencies.wakeLock
)(observer(PlayerControls))
