// Inspired by https://github.com/lancedikson/bowser

class Browser {
  constructor(userAgent) {
    this.userAgent = userAgent
  }

  // Platform / browser

  get isMobile() {
    return /Mobi/.test(this.userAgent)
  }

  get isFirefox() {
    return /firefox|iceweasel|fxios/i.test(this.userAgent)
  }

  get isChrome() {
    return /chrome|crios|crmo/i.test(this.userAgent)
  }

  get isEdge() {
    return /edg([ea]|ios)/i.test(this.userAgent)
  }

  // Capabilities

  get hasClipboard() {
    return 'clipboard' in window.navigator
  }

  get hasLocalStorage() {
    const TEST_KEY = 'test'
    const TEST_VALUE = 'xxx'
    try {
      window.localStorage.setItem(TEST_KEY, TEST_VALUE)
      const test = window.localStorage.getItem(TEST_KEY) === TEST_VALUE
      window.localStorage.removeItem(TEST_KEY)
      return test
    } catch {
      return false
    }
  }

  get hasShare() {
    return 'share' in window.navigator
  }

  get hasWakeLock() {
    return 'wakeLock' in window.navigator
  }
}

export default Browser
