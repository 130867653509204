import React from 'react'

import Alerts from 'components/common/Alerts'
import Popup from 'components/ui/Popup'

const BasicPage = props => {
  const { children } = props
  return (
    <div className="l-rows l-height--100% l-position__relative">
      <div id="MainScrollElement" className="Content l-height--100%">
        {children}
      </div>
      <Alerts />
      <Popup />
    </div>
  )
}

export default BasicPage
