const alertLevels = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
}

const alerts = {
  connectionConnected: 'connectionConnected',
  connectionConnecting: 'connectionConnecting',
  connectionError: 'connectionError',
  error: 'error',
  info: 'info',
  partyBannedFrom: 'partyBannedFrom',
  partyEnded: 'partyEnded',
  partyHosted: 'partyHosted',
  partyJoined: 'partyJoined',
}

const connectionStates = {
  closed: 'closed',
  connecting: 'connecting',
  open: 'open',
  error: 'error',
}

const dependencies = {
  app: 'app',
  auth: 'auth',
  alert: 'alert',
  analytics: 'analytics',
  apiClient: 'apiClient',
  banList: 'banList',
  blackList: 'blackList',
  browser: 'browser',
  cache: 'cache',
  config: 'config',
  history: 'history',
  historyPlaylist: 'historyPlaylist',
  locale: 'locale',
  logger: 'logger',
  masterPlaylist: 'masterPlaylist',
  party: 'party',
  player: 'player',
  playlist: 'playlist',
  popup: 'popup',
  share: 'share',
  spotify: 'spotify',
  spotifyDeviceHandler: 'spotifyDeviceHandler',
  store: 'store',
  tracksFiltered: 'tracksFiltered',
  userList: 'userList',
  wakeLock: 'wakeLock',
}

const partyOptions = {
  partyPassword: 'partyPassword',
  partyGuestLimit: 'partyGuestLimit',
  trackMaxLength: 'trackMaxLength',
  allowTrackDuplicates: 'allowTrackDuplicates',
}

const popupSizes = {
  minimal: 'minimal',
  small: 'small',
  medium: 'medium',
  large: 'large',
  tall: 'tall',
  cover: 'cover',
}

const serverCommands = {
  banUser: 'banUser',
  addBlacklist: 'addBlacklist',
  removeBlacklist: 'removeBlacklist',
  endParty: 'endParty',
  heartbeat: 'heartbeat',
  joinParty: 'joinParty',
  leaveParty: 'leaveParty',
  requestUpdate: 'requestUpdate',
  startParty: 'startParty',
  unbanUser: 'unbanUser',
  updateFilteredTracks: 'updateFilteredTracks',
  updateMasterPlaylist: 'updateMasterPlaylist',
  updatePartyOptions: 'updatePartySettings',
  updatePlaylist: 'updatePlaylist',
  updateUserList: 'updateUserList',
}

const serverEvents = {
  connectionClosed: 'connectionClosed',
  partyBannedUser: 'partyBannedUser',
  partyEnded: 'partyEnded',
  partyJoined: 'partyJoined',
  partyLeft: 'partyLeft',
  partyLimitReachedError: 'partyLimitReachedError',
  partyNotFoundError: 'partyNotFoundError',
  partyOptionInvalidError: 'partyOptionInvalidError',
  partyPasswordWrongError: 'partyPasswordWrongError',
  partyRejoined: 'partyRejoined',
  partySettingsUpdated: 'partySettingsUpdated',
  partyStarted: 'partyStarted',
  partyUserLimitReachedError: 'partyUserLimitReachedError',
  userBanned: 'userBanned',
  userBannedError: 'userBannedError',
  userJoined: 'userJoined',
  userLeft: 'userLeft',
  userNotFoundError: 'userNotFoundError',
  userUnauthorizedError: 'userUnauthorizedError',
  userUnbanned: 'userUnbanned',
}

const trackFilterReasons = {
  blacklistedArtist: 'blacklistedArtist',
  blacklistedAlbum: 'blacklistedAlbum',
  played: 'played',
  [partyOptions.trackMaxLength]: 'trackMaxLength',
  [partyOptions.allowTrackDuplicates]: 'allowTrackDuplicates',
}

export {
  alertLevels,
  alerts,
  connectionStates,
  dependencies,
  partyOptions,
  popupSizes,
  serverCommands,
  serverEvents,
  trackFilterReasons,
}
