import React, { useEffect, useMemo } from 'react'
import { action, flow } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import isEqual from 'lodash/isEqual'
import classNames from 'classnames'

import { alertLevels } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import Loader from 'components/common/Loader'
import { TrackDurationArtistsAlbumLine } from 'components/common/TrackLine'
import Text from 'components/ui/Text'
import TextInput from 'components/ui/TextInput'
import Select from 'components/ui/Select.jsx'
import Avatar from 'components/ui/Avatar.jsx'
import { PopupContainer, PopupHeader, PopupContent } from 'components/ui/Popup'
import { CloseIcon, HelpCircleIcon, PlaylistCheckIcon, SearchIcon } from 'components/ui/icons'

const MusicSearch = props => {
  const {
    alert,
    analytics,
    auth,
    config,
    locale,
    logger,
    playlist,
    popup,
    searchQuery,
    searchType,
    spotify,
    store,
  } = props
  const search = useLocalObservable(() => ({
    query: searchQuery || store.getItem('musicSearch.searchQuery') || '',
    type: searchType || props.store.getItem('musicSearch.searchType') || 'artist',
    tracks: store.getItem('musicSearch.searchTracks') || [],
    hasMoreResults: store.getItem('musicSearch.hasMoreResults') || false,
    isSearching: false,
    last: null,
  }))

  const emptySearch = useMemo(
    () => ({
      query: '',
      type: 'artist',
      limit: config.musicSearch.limit,
      offset: 0,
    }),
    [config]
  )

  const searchTypes = useMemo(
    () => ({
      artist: locale.translate('searchtypes.artist'),
      album: locale.translate('searchtypes.album'),
      track: locale.translate('searchtypes.track'),
    }),
    [locale]
  )

  useEffect(() => {
    if (searchQuery || searchType) {
      searchTracks(searchQuery, searchType)
    }
  }, [])

  const searchTracks = flow(function* (query, type, limit, offset = 0) {
    const currentSearch = {
      query,
      type,
      limit: limit > 0 ? limit : config.musicSearch.limit,
      offset: offset >= 0 ? offset : 0,
    }
    if (!isEqual(currentSearch, search.last) && currentSearch.query.length >= config.musicSearch.minQueryLength) {
      search.isSearching = true
      try {
        const result = yield spotify.search(query, type, limit, offset)
        search.isSearching = false
        if (currentSearch.query === search.last?.query && currentSearch.type === search.last?.type) {
          search.tracks = search.tracks.concat(...result.tracks)
        } else {
          search.tracks = result.tracks
        }
        search.hasMoreResults = result.total > result.offset + result.tracks.length
        search.last = currentSearch
        store.setItem('musicSearch.searchQuery', query)
        store.setItem('musicSearch.searchType', type)
        store.setItem('musicSearch.searchTracks', search.tracks)
        store.setItem('musicSearch.hasMoreResults', search.hasMoreResults)
        store.setItem('musicSearch.lastSearch', currentSearch)
        analytics.events.searchMusic(type)
      } catch (error) {
        logger.error('MusicSearch.searchTracks', error)
        popup.hide()
        alert.show({
          level: alertLevels.ERROR,
          message: locale.translate('alert.musicSearchError'),
          timeout: config.alert.defaultTimeout,
          dismissable: true,
        })
        store.setItem('musicSearch.searchTracks', [])
        store.setItem('musicSearch.hasMoreResults', false)
      }
    }
  })

  const clearSearch = action(() => {
    search.isSearching = false
    search.query = ''
    search.tracks = []
    search.hasMoreResults = false
    search.last = emptySearch
  })

  const handleTypeChange = action(event => {
    search.type = event.target.value
    store.setItem('musicSearch.type', event.target.value)
  })

  const handleSearchChange = action(event => {
    search.query = event.target.value
  })

  const handleSearch = event => {
    if (event) event.preventDefault()
    searchTracks(search.query, search.type)
  }

  const handleSearchMore = async event => {
    if (event) event.preventDefault()
    const limit = search.last?.limit || config.musicSearch.limit
    const offset = (search.last?.offset || search.tracks.length) + config.musicSearch.limit
    await searchTracks(search.query, search.type, limit, offset)
    await analytics.events.searchMoreMusic(search.last?.offset || 0)
  }

  const toggleTrack = track => {
    if (playlist.hasTrack(track.providerId, track.providerTrackId)) {
      playlist.remove(track.providerTrackId)
    } else {
      playlist.add(playlist.updateTrackUserId(track, auth.userId))
      analytics.events.addSearchedTrack()
    }
  }

  return (
    <PopupContainer data-test="MusicSearchPopup">
      <PopupHeader onHide={popup.hide}>
        <Text className="l-margins--h">general.search_music</Text>
      </PopupHeader>
      <PopupContent>
        <div className="l-rows l-height--100%">
          <form className="l-flex l-flex--fixed l-columns l-margins" onSubmit={handleSearch}>
            <div className="l-flex">
              <Select
                name="type"
                inputClassName="l-margin--b0"
                options={searchTypes}
                value={search.type}
                onChange={handleTypeChange}
              />
            </div>
            <div className="l-flex__spacer" />
            <div className="l-flex l-flex--b75%">
              <TextInput
                id="search"
                className="l-margin--b0"
                name="search"
                autoFocus={true}
                tabIndex={2}
                value={search.query}
                icon={
                  <CloseIcon
                    className={classNames(
                      'Icon u-color--secondary--hover u-clickable',
                      search.query.length > 0 && 'l-display--none'
                    )}
                    onClick={clearSearch}
                  />
                }
                onChange={handleSearchChange}
              />
            </div>
            <div className="l-flex__spacer" />
            <div className="l-flex--fixed">
              <button type="submit" className="button--primary button--icon button--slim" data-test="MusicSearchButton">
                <SearchIcon className="Icon" />
              </button>
            </div>
          </form>
          {search.isSearching && search.tracks.length === 0 && (
            <div className="l-flex l-flex--centered-child l-height--100%">
              <div>
                <Loader />
              </div>
            </div>
          )}
          {!search.isSearching && search.last && search.tracks.length === 0 && (
            <div className="l-flex l-flex--centered-child l-height--100%">
              <div className="l-align--center u-opacity-25 l-margins--v">
                <HelpCircleIcon className="Icon Icon--huge" />
                <p>
                  <Text>general.nothing_found</Text>
                </p>
              </div>
            </div>
          )}
          {search.tracks.length > 0 && (
            <div className="l-flex l-overflow--scroll-y">
              <ul className="List" data-test="MusicSearchResultList">
                {search.tracks.map(track => (
                  <li
                    key={track.trackId}
                    className={classNames(
                      'ListItem',
                      'ListItem--clickable',
                      playlist.hasTrack(track.providerId, track.providerTrackId) && 'u-opacity-25'
                    )}
                    onClick={() => toggleTrack(track)}
                    data-test="MusicSearchResult"
                  >
                    <div className="ListItem__icon">
                      {playlist.hasTrack(track.providerId, track.providerTrackId) ? (
                        <Avatar src="" rounded={false}>
                          <PlaylistCheckIcon className="Icon" />
                        </Avatar>
                      ) : (
                        <Avatar src={track.album.thumbUrl} rounded={false} />
                      )}
                    </div>
                    <div className="ListItem__content">
                      <span className="t-ellipsis">{track.name}</span>
                      <span className="t-ellipsis t-small">
                        <TrackDurationArtistsAlbumLine track={track} />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
              {search.hasMoreResults && (
                <div className="l-paddings">
                  <button className="button--fluid" disabled={search.isSearching} onClick={handleSearchMore}>
                    {search.isSearching ? (
                      <div className="l-margins--v0.5">
                        <Loader size="small" />
                      </div>
                    ) : (
                      <Text>general.more</Text>
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </PopupContent>
    </PopupContainer>
  )
}

export default withDependencies(
  dependencies.alert,
  dependencies.analytics,
  dependencies.app,
  dependencies.auth,
  dependencies.config,
  dependencies.locale,
  dependencies.logger,
  dependencies.party,
  dependencies.playlist,
  dependencies.popup,
  dependencies.spotify,
  dependencies.store
)(observer(MusicSearch))
