import { makeObservable, observable, computed, action } from 'mobx'
import uniqueId from 'lodash/uniqueId'

import { alertLevels } from 'config/enums'
import toType from '../functions/toType'

const alertLevelPriority = Array.from(Object.keys(alertLevels))

export default class Alert {
  config
  alerts = []
  named = new Map()

  constructor(alertConfig, namedAlerts) {
    this.config = alertConfig
    if (namedAlerts instanceof Map) {
      this.named = namedAlerts
    } else if (namedAlerts instanceof Object) {
      Object.entries(namedAlerts).forEach(([name, config]) => this.named.set(name, config))
    }
    makeObservable(this, {
      alerts: observable.shallow,
      named: false,
      list: computed,
      current: computed,
      show: action,
      hide: action,
    })
  }

  get list() {
    return this.alerts
  }

  get current() {
    const prioritized = [...this.alerts]
    return prioritized.length > 0 ? prioritized[0] : null
  }

  /**
   * Add an alert
   *
   * @param {Object|string} optionsOrName
   * @param {string} [optionsOrName.id] - Unique id for which only one alert can exist at a time
   * @param {string} [optionsOrName.level]
   * @param {string} [optionsOrName.message]
   * @param {number} [optionsOrName.timeout] - Timeout in ms
   * @param {boolean} [optionsOrName.dismissable]
   * @param {function} [optionsOrName.onClick]
   * @param {array} [namedArgs]
   * @returns this
   */
  show(optionsOrName, ...namedArgs) {
    let options = {}
    if (typeof optionsOrName === 'string') {
      // Display named alert if found
      if (this.named.has(optionsOrName)) {
        options = this.named.get(optionsOrName)(...namedArgs)
        // Display default alert with message
      } else {
        options.message = optionsOrName
      }
    } else {
      options = optionsOrName
    }
    const {
      id,
      level = alertLevels.INFO,
      message,
      timeout = this.config.defaultTimeoutShort,
      dismissable = true,
      onClick = null,
    } = options
    const newAlert = {
      id: id || uniqueId('alert-'),
      level,
      message,
      timeout,
      dismissable,
      visible: true,
      onClick,
      hide: () => {},
      cancelTimeout: () => {},
      timestamp: Date.now(),
    }
    newAlert.hide = () => this.hide(newAlert)
    if (newAlert.timeout > 0) {
      const timeout = setTimeout(newAlert.hide, newAlert.timeout)
      newAlert.cancelTimeout = () => clearTimeout(timeout)
    }
    this.alerts = [newAlert, ...this.alerts].sort((a, b) => {
      if (alertLevelPriority.indexOf(a.level) < alertLevelPriority.indexOf(b.level)) return -1
      if (alertLevelPriority.indexOf(a.level) > alertLevelPriority.indexOf(b.level)) return 1
      if (a.timestamp > b.timestamp) return -1
      if (a.timestamp < b.timestamp) return 1
      return 0
    })
    return this
  }

  /**
   *
   * @param {undefined} hideAlert - Hide all alerts
   * @param {string} hideAlert - Hide alert by id
   * @param {object} hideAlert - Hide alert by object reference
   * @param {function} hideAlert - Hide alert by filter function
   * @param {array} hideAlert - Hide list of alerts
   * @returns this
   */
  hide(hideAlert) {
    let shouldHide
    switch (toType(hideAlert)) {
      case 'undefined':
        shouldHide = () => true
        break
      case 'string':
        shouldHide = checkAlert => checkAlert.id === hideAlert
        break
      case 'object':
        shouldHide = checkAlert => hideAlert === checkAlert
        break
      case 'function':
        shouldHide = hideAlert
        break
      case 'array':
        hideAlert.forEach(hideAlertElement => this.hide(hideAlertElement))
        return this
      default:
        return this
    }
    if (shouldHide) {
      this.alerts.forEach(checkAlert => {
        if (shouldHide(checkAlert)) {
          checkAlert.cancelTimeout()
          this.alerts.remove(checkAlert)
        }
      })
    }
    return this
  }
}
