/* global PRODUCTION */
const searchParams = new URLSearchParams(window.location.search);
let logger
if (PRODUCTION && !searchParams.has("debug")) {
  logger = {
    log: () => {},
    warn: () => {},
    error: () => {},
  }
} else {
  logger = console
}

export default logger
