import { makeAutoObservable, reaction, toJS } from 'mobx'
import axios from 'axios'

import logger from 'services/logger'

const STORE_PROVIDERID_KEY = 'auth.providerId'
const STORE_PLAYSOMETOKEN_KEY = 'auth.playsomeToken'
const STORE_AUTHDATA_KEY = 'auth.auth'
const STORE_IDENTITY_KEY = 'auth.identity'

export default class Auth {
  store
  config
  providerId
  playsomeToken
  authData
  identity

  constructor(config) {
    this.config = config
    makeAutoObservable(this, {
      store: false,
      config: false,
    })
  }

  persist(store) {
    this.store = store
    const providerId = this.store.getItem(STORE_PROVIDERID_KEY)
    const playsomeToken = this.store.getItem(STORE_PLAYSOMETOKEN_KEY)
    const authData = this.store.getItem(STORE_AUTHDATA_KEY)
    const identity = this.store.getItem(STORE_IDENTITY_KEY)
    if (providerId && playsomeToken && authData && identity) {
      this.providerId = providerId
      this.playsomeToken = playsomeToken
      this.authData = authData
      this.identity = identity
    }

    this.persistDisposers = [
      reaction(
        () => this.providerId,
        value => {
          this.store.setItem(STORE_PROVIDERID_KEY, value)
        }
      ),
      reaction(
        () => this.playsomeToken,
        value => {
          this.store.setItem(STORE_PLAYSOMETOKEN_KEY, value)
        }
      ),
      reaction(
        () => toJS(this.authData),
        value => {
          this.store.setItem(STORE_AUTHDATA_KEY, value)
        }
      ),
      reaction(
        () => toJS(this.identity),
        value => {
          this.store.setItem(STORE_IDENTITY_KEY, value)
        }
      ),
    ]
    return this
  }

  get isLoggedIn() {
    return !!this.providerId && !!this.playsomeToken && !!this.authData && !!this.identity
  }

  get isFresh() {
    if (this.isLoggedIn) {
      if ('expires' in this.authData) {
        return this.authData.expires > Math.floor(Date.now() / 1000)
      }
      return true
    }
    return false
  }

  get expiresIn() {
    if (this.authData && 'expires' in this.authData) {
      return Math.floor(this.authData.expires - Date.now() / 1000)
    }
    return 0
  }

  get userId() {
    return this.identity ? this.identity.userId : null
  }

  get providerUserId() {
    return this.identity ? this.identity.providerUserId : null
  }

  get displayName() {
    return this.identity ? this.identity.name || this.identity.userId : null
  }

  isLoggedInToProvider(providerId) {
    logger.log('auth.isLoggedInToProvider', providerId, this.providerId, this.isFresh)
    return this.providerId === providerId && this.isFresh
  }

  get canHostParty() {
    return ['spotify'].includes(this.providerId)
  }

  get canImportExportPlaylist() {
    return ['spotify'].includes(this.providerId)
  }

  getAuthData() {
    return this.authData ? toJS(this.authData) : null
  }

  getIdentity() {
    return this.identity ? toJS(this.identity) : null
  }

  getLoginUrl = providerId => {
    if (this.config.auth.providers[providerId].url.login) {
      return this.config.auth.providers[providerId].url.login
    } else {
      return null
    }
  };

  /**
   * Send authorization message to server auth api
   *
   * @param {string} providerId
   * @param {object} [data] - Authorization data from login, auth data for reauthorization, or empty for implicit grant authorization.
   * @returns Generator<Auth, void, ?>
   */
  *authorize(providerId, data) {
    logger.log('auth.authorize', providerId, data)
    try {
      const response = yield axios.post(this.config.auth.providers[providerId].url.auth, data)
      const { playsomeToken, auth, identity } = response?.data?.data
      if (auth && 'access_token' in auth) {
        this.providerId = providerId
        if (playsomeToken) {
          this.playsomeToken = playsomeToken
        }
        this.authData = {
          ...this.authData,
          ...auth,
          expires: Math.floor(Date.now() / 1000) + auth['expires_in'],
        }
        if (identity) {
          this.identity = this.transformIdentity(identity)
        }
        logger.log('auth.authorize', {
          playsomeToken,
          auth,
          identity,
        })
        return this
      } else {
        throw new Error('noAuthTokenError')
      }
    } catch (error) {
      logger.error('auth.authorize error', error.message, error.response)
      if (error) {
        if (['noAuthTokenError'].includes(error.message)) {
          throw error
        }
        if (error.message === 'Network Error') {
          throw new Error('connectionError')
        }
        if (error.response?.data?.event) {
          throw new Error(error.response.data.event)
        }
      }
      throw new Error('unknownAuthError')
    }
  }

  *validatePlaysomeToken() {
    if (!this.identity || !this.playsomeToken) {
      return false
    }
    try {
      yield axios.get(this.config.auth.url.validate.replace('{userId}', encodeURIComponent(this.identity.userId)), {
        params: {
          playsomeToken: this.playsomeToken,
        },
      })
      return true
    } catch {
      return false
    }
  }

  transformIdentity(identity) {
    return identity
  }

  clear() {
    this.providerId = null
    this.playsomeToken = null
    this.authData = null
    this.identity = null
    return this
  }

  refresh() {
    if (!this.isLoggedIn) return Promise.reject('noAuthenticationError')
    // Reauthorize with refresh token
    if ('refresh_token' in this.authData && this.authData.refresh_token) {
      return this.authorize(this.providerId, {
        refresh_token: this.authData.refresh_token,
      })
    } else {
      return Promise.reject('reLoginNeededError')
    }
  }

  *logout() {
    if (this.config.auth.providers[this.providerId].url.logout) {
      try {
        yield axios.post(this.config.auth.providers[this.providerId].url.logout, {
          userId: this.identity.userId,
          playsomeToken: this.playsomeToken,
        })
        return undefined
      } catch (error) {
        if (error && 'message' in error && error.message === 'Network Error') {
          throw new Error('connectionError')
        }
        logger.error('auth.logout error', error)
        throw new Error('unknownError')
      }
    } else {
      return undefined
    }
  }
}
