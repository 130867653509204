import { makeAutoObservable } from 'mobx'

import { connectionStates } from 'config/enums'
import logger from 'services/logger'

export default class Connection {
  url
  SocketClass
  socket
  state = connectionStates.closed
  subscribers = new Map()

  constructor(url, SocketClass = WebSocket) {
    this.url = url
    this.SocketClass = SocketClass
    makeAutoObservable(this, {
      url: false,
      SocketClass: false,
      socket: false,
      subscribers: false,
    })
  }

  get isConnected() {
    return this.socket && this.socket.readyState === this.SocketClass.OPEN
  }

  connect() {
    if (this.isConnected) {
      return Promise.resolve(this)
    } else {
      this.state = connectionStates.connecting
      return new Promise((resolve, reject) => {
        try {
          this.socket = new this.SocketClass(this.url)
          this.socket.onopen = () => {
            this.onOpen()
            resolve(this)
          }
        } catch (error) {
          logger.error('connection.connect.error', error)
          reject('connectionError')
        }
      })
    }
  }

  onOpen = () => {
    logger.log('connection.onopen', this.socket)
    this.socket.onopen = null
    this.socket.onerror = this.onError
    this.socket.onclose = this.onClose
    this.socket.onmessage = this.onMessage
    this.state = connectionStates.open
    return this
  }

  onError = error => {
    logger.error('Connection.onerror', error)
    this.state = connectionStates.error
    throw new Error('connectionError')
  }

  onClose = () => {
    logger.log('Connection.onclose')
    this.state = connectionStates.closed
    this.handleMessage({ event: 'connectionClosed' })
  }

  onMessage = event => this.handleMessage(JSON.parse(event.data))

  // Handle message events
  handleMessage(message) {
    this.subscribers.forEach(subscriber => {
      if (
        !subscriber.options.events ||
        (subscriber.options.events && subscriber.options.events.indexOf(message.event) !== -1)
      ) {
        subscriber.callback(message)
        if (subscriber.options.once) {
          subscriber.unsubscribe()
        }
      }
    })
    return this
  }

  /**
   * Listen to message events
   *
   * @param {function} callback
   * @param {object} [options]
   * @param {string[]} [options.events]
   * @param {boolean} [options.once]
   * @returns function
   */
  subscribe(callback, options) {
    const optionsWithDefaults = {
      events: null,
      once: false,
      ...options,
    }
    const unsubscribe = () => {
      this.subscribers.delete(callback)
    }
    this.subscribers.set(callback, { callback, unsubscribe, options: optionsWithDefaults })
    return unsubscribe
  }

  send(event, data) {
    logger.log('Connection.send', event, data)
    if (this.isConnected) {
      this.socket.send(JSON.stringify({ event, data }))
      return this
    } else {
      return this.handleMessage({ event: 'connectionClosed' })
    }
  }

  /**
   * Send message and return awaited response event
   *
   * @param {string} sendEvent
   * @param {object} sendData
   * @param {string[]} [awaitEvents]
   * @returns {Promise<unknown>}
   */
  sendAndAwaitEvent(sendEvent, sendData, awaitEvents) {
    logger.log('Connection.sendAndAwaitEvent', { sendEvent, sendData, awaitEvents })
    return new Promise(resolve => {
      this.subscribe(
        event => {
          logger.log('Connection.sendAndAwaitEvent.response', { event })
          resolve(event)
        },
        {
          events: awaitEvents,
          once: true,
        }
      )
      this.send(sendEvent, sendData)
    })
  }

  close() {
    if (this.socket) {
      this.socket.close()
    }
    return this
  }
}
