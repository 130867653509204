import { makeAutoObservable, action } from 'mobx'

import logger from 'services/logger'

const playingStates = {
  stopped: 'stopped',
  playing: 'playing',
  paused: 'paused',
}

export default class Player {
  spotifyDevice
  cancelSpotifyDeviceSubscription
  lastTickTime
  tickInterval
  playingState = playingStates.stopped
  /** @type {Track || null} */
  currentTrack = null
  currentTime = 0
  volume = 1.0

  constructor(playlist, historyPlaylist) {
    makeAutoObservable(this, {
      spotifyDevice: false,
      cancelSpotifyDeviceSubscription: false,
      lastTickTime: false,
      tickInterval: false,
    })
    this.playlist = playlist
    this.historyPlaylist = historyPlaylist
  }

  get isPlaying() {
    return this.playingState === playingStates.playing
  }

  get heartbeatState() {
    return {
      isPlaying: this.isPlaying,
      currentTrackId: this.currentTrack?.trackId,
      currentTime: this.currentTime,
    }
  }

  *setSpotifyDevice(spotifyDevice) {
    logger.log('player.setSpotifyDevice', spotifyDevice.deviceId, spotifyDevice)
    if (this.cancelSpotifyDeviceSubscription) this.cancelSpotifyDeviceSubscription()
    if (this.spotifyDevice) this.spotifyDevice.destroy()
    this.spotifyDevice = spotifyDevice
    if (this.spotifyDevice) {
      this.cancelSpotifyDeviceSubscription = this.spotifyDevice.subscribe(
        action(message => {
          const { event, data } = message
          switch (event) {
            case 'state':
              this.updateState(data).catch(error =>
                this.handleError({ event: error?.message || error?.toString() || 'unknownPlayerError' })
              )
              break
            case 'error':
              this.handleError(data)
              break
          }
        })
      )
      yield this.spotifyDevice.transfer(spotifyDevice.deviceId, false)
      // await this.spotifyDevice.poll()
      if (this.isPlaying) {
        yield this.play()
        yield this.seek(this.currentTime)
      } else {
        this.currentTrack = null
        this.currentTime = 0
      }
    }
    return this
  }

  handleError(error) {
    logger.error('player.handleError', error)
    switch (error) {
      default:
        this.stop().catch(() => {})
        break
    }
  }

  heartbeat(isPlaying, currentTrackId, currentTime) {
    if (currentTrackId) {
      if (isPlaying) {
        this.playingState = playingStates.playing
      } else {
        this.playingState = playingStates.paused
      }
      if (!this.currentTrack || (this.currentTrack && this.currentTrack.trackId !== currentTrackId)) {
        this.currentTrack = this.playlist.getTrack(currentTrackId)
      }
      this.currentTime = currentTime
    } else {
      this.playingState = playingStates.stopped
      this.currentTrack = null
      this.currentTime = 0
    }
    logger.log('player.heartbeat', this.isPlaying, this.currentTime, this.currentTrack)
  }

  *updateState(state) {
    const { isPlaying, currentTime, currentTrackId, volume } = state
    logger.log('player.updateState', {
      isPlaying,
      isPlaying_player: this.isPlaying,
      playingState: this.playingState,
      currentTrackId,
      currentTrackId_player: this.currentTrack && this.currentTrack.providerTrackId,
      currentTime,
      volume,
    })

    if (currentTrackId) {
      if (!this.currentTrack || currentTrackId !== this.currentTrack.providerTrackId) {
        this.currentTrack = this.playlist.getTrackByProviderTrackId(currentTrackId)
        if (!this.currentTrack) {
          yield this.stop()
          throw Error('trackNotFoundError')
        }
      }
    }
    this.currentTime = currentTime
    if (volume !== null) {
      this.volume = volume
    }

    switch (this.playingState) {
      case playingStates.playing:
        if (!isPlaying) {
          yield this.playNext()
          if (!this.currentTrack) {
            yield this.stop()
          }
        }
        break
      case playingStates.paused:
      case playingStates.stopped:
        if (isPlaying) {
          yield this.resume()
        } else {
          yield this.pause()
        }
        break
    }
  }

  /**
   * Start tick timer, adjusting current time by exact time passed since last tick, adjusting for setInterval inaccuracy
   */
  startTicking = () => {
    if (this.tickInterval) return
    this.lastTickTime = Date.now()
    this.tickInterval = setInterval(
      action(() => {
        const now = Date.now()
        this.currentTime += now - this.lastTickTime
        this.lastTickTime = now
      }),
      250
    )
    return this
  }

  stopTicking = () => {
    if (this.tickInterval) {
      clearInterval(this.tickInterval)
      this.tickInterval = null
    }
    return this
  }

  toggle() {
    if (this.currentTrack) {
      if (this.isPlaying) {
        return this.pause()
      } else {
        return this.resume()
      }
    } else {
      return this.playNext()
    }
  }

  /**
   * Play a track, if no track provided, play next track
   *
   * @param {Track} [track]
   * @return {*}
   */
  *play(track) {
    if (!this.spotifyDevice) {
      throw new Error('noSpotifyPlayerError')
    }
    if (track) {
      this.currentTrack = track
    } else {
      this.currentTrack = this.currentTrack || this.playlist.getNextUnplayedTrack()
    }
    if (this.currentTrack) {
      try {
        yield this.spotifyDevice.play(this.currentTrack)
        this.playingState = playingStates.playing
        this.startTicking()
      } catch (error) {
        logger.error('player.play', error)
        this.playingState = playingStates.stopped
        this.stopTicking()
      }
    }
    return this
  }

  playNext() {
    const nextTrack = this.playlist.getNextUnplayedTrack(this.currentTrack)
    if (this.currentTrack) {
      this.playlist.addTrackToPlaylist(this.currentTrack.trackId, this.historyPlaylist)
      this.currentTrack = null
    }
    logger.log('player.playNext', { nextTrack, currentTrack: this.currentTrack, historyPlaylist: this.historyPlaylist })
    if (nextTrack) {
      return this.play(nextTrack)
    } else {
      return this.stop()
    }
  }

  *pause() {
    if (this.playingState === playingStates.paused) return this
    try {
      yield this.spotifyDevice.pause()
      this.playingState = playingStates.paused
      this.stopTicking()
      logger.log('player.paused')
      return this
    } catch (error) {
      logger.log('player.pause ERROR', error)
    }
  }

  *resume() {
    if (this.playingState === playingStates.playing) return this
    try {
      yield this.spotifyDevice.resume()
      this.playingState = playingStates.playing
      this.startTicking()
      logger.log('player.resume')
      return this
    } catch (error) {
      logger.log('player.resume ERROR', error)
    }
  }

  *stop() {
    if (this.playingState === playingStates.stopped) return this
    try {
      yield this.spotifyDevice.pause()
      this.playingState = playingStates.stopped
      this.stopTicking()
      logger.log('player.stop')
      return this
    } catch (error) {
      logger.log('player.stop ERROR', error)
    }
  }

  seek(position) {
    logger.log('player.seek', position)
    return this.spotifyDevice.seek(position).then(() => {
      this.currentTime = position
    })
  }

  setVolume(volume) {
    const originalVolume = this.volume
    this.volume = volume
    if (this.spotifyDevice) {
      return this.spotifyDevice.setVolume(volume).catch(error => {
        logger.error('player.setVolume.error', error)
        this.volume = originalVolume
      })
    } else {
      return Promise.resolve(undefined)
    }
  }

  rewind() {
    this.currentTrack = null
    this.currentTime = 0
    return this
  }

  reset() {
    if (this.cancelSpotifyDeviceSubscription) {
      this.cancelSpotifyDeviceSubscription()
    }
    if (this.spotifyDevice) {
      this.spotifyDevice.destroy()
      this.spotifyDevice = null
    }
    this.stopTicking()
    this.lastTickTime = 0
    this.tickInterval = null
    this.playingState = playingStates.stopped
    this.currentTrack = null
    this.currentTime = 0
    this.volume = 1.0
    return this
  }

  /**
   * Check if a specific track is playing, or one of a list of tracks
   *
   * @param {string|string[]} trackId
   * @return {boolean}
   */
  isTrackPlaying(trackId) {
    if (this.isPlaying && this.currentTrack) {
      if (Array.isArray(trackId)) {
        return trackId.includes(this.currentTrack.trackId)
      } else {
        return trackId === this.currentTrack.trackId
      }
    }
    return false
  }
}
