import { makeAutoObservable } from 'mobx'

import { partyOptions, trackFilterReasons } from 'config/enums'
import { filterPlaylists, mergePlaylists } from 'functions/playlistUtils'

class PlaylistHandler {
  constructor(userPlaylists, historyPlaylist, party, blackList) {
    this.userPlaylists = userPlaylists
    this.historyPlaylist = historyPlaylist
    this.party = party
    this.blackList = blackList
    makeAutoObservable(this)
  }

  get playlists() {
    return Array.from(this.userPlaylists.values())
  }

  /**
   * Find the index of the current playing user, based on last played track
   *
   * @returns {number}
   */
  get currentPlayingUserOffset() {
    let offset = 0
    const userList = Array.from(this.userPlaylists.keys())
    const lastTrack = this.historyPlaylist.getLastTrack()
    if (lastTrack) {
      offset = userList.findIndex(userId => userId === lastTrack.userId) || 0
      offset++
      if (offset > userList.size - 1) offset = 0
    }
    return offset
  }

  get filterParams() {
    return {
      playedTracks: this.historyPlaylist.trackIds,
      trackMaxLength: this.party.getOption(partyOptions.trackMaxLength),
      blackList: this.blackList.idsByType,
      allowTrackDuplicates: this.party.getOption(partyOptions.allowTrackDuplicates),
    }
  }

  get trackFilter() {
    const { playedTracks, trackMaxLength, blackList, allowTrackDuplicates } = this.filterParams
    return function trackFilter(track, previouslyAcceptedTracks = new Set()) {
      // Filter out already played tracks
      if (playedTracks.length > 0 && playedTracks.includes(track.trackId)) {
        return trackFilterReasons.played
      }
      // Filter too long tracks
      if (trackMaxLength && track.duration > trackMaxLength) {
        return trackFilterReasons.trackMaxLength
      }
      // Filter blacklisted
      if (blackList.artist) {
        for (const blackListedArtist of blackList.artist) {
          if (track.artists.find(artist => artist.id === blackListedArtist)) {
            return trackFilterReasons.blacklistedArtist
          }
        }
      }
      if (blackList.album && blackList.album.includes(track.album.id)) {
        return trackFilterReasons.blacklistedAlbum
      }
      // Filter track duplicates
      if (!allowTrackDuplicates) {
        if (previouslyAcceptedTracks.has(track.providerTrackId)) {
          return trackFilterReasons.allowTrackDuplicates
        } else {
          previouslyAcceptedTracks.add(track.providerTrackId)
        }
      }
      return false
    }
  }

  get filteredPlaylists() {
    return filterPlaylists(this.playlists, this.trackFilter)
  }

  get masterPlaylist() {
    return mergePlaylists(this.filteredPlaylists.playlists, this.currentPlayingUserOffset)
  }

  get filteredTracks() {
    return this.filteredPlaylists.filtered
  }
}

export default PlaylistHandler
