import React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { useDependencies, dependencies } from 'components/Context'
import SharePopup from 'components/common/popups/SharePopup.jsx'
import UserAvatar, { USER_AVATAR_LABEL_POSITIONS } from 'components/common/UserAvatar'
import PrettyUrl from 'components/ui/PrettyUrl'
import { ShareIcon } from 'components/ui/icons'

const PartyToolbar = () => {
  const { analytics, app, browser, locale, party, popup, share, userList } = useDependencies(
    dependencies.analytics,
    dependencies.app,
    dependencies.browser,
    dependencies.locale,
    dependencies.party,
    dependencies.popup,
    dependencies.share,
    dependencies.userList
  )
  const hostUser = Array.from(userList.values())[0]

  const showSharePopup = () => {
    if (browser.isMobile && browser.hasShare) {
      const partyUrl = app.partyUrl
      const host = Array.from(userList.values()).shift()
      const hostName = host.name || host.id
      share
        .shareNative(
          partyUrl,
          locale.translate('label.share_message', { partyId: party.partyId, hostName }),
          locale.translate('label.share_title', { partyId: party.partyId, hostName })
        )
        .catch(() => {})
      analytics.events.shareParty('native')
    } else {
      popup.show({
        component: <SharePopup />,
      })
      analytics.events.showPopup('party_share')
    }
  }

  return (
    <div
      className={classNames(
        'Toolbar Toolbar--large',
        !party.isJoined && 'Toolbar--hidden',
        'l-columns l-align-items--center'
      )}
    >
      <div>
        <UserAvatar user={hostUser} labelPosition={USER_AVATAR_LABEL_POSITIONS.AVATAR_LABEL_RIGHT} size={3} />
      </div>
      <div className="l-flex__spacer" />
      <div className="l-margin--t l-overflow--hidden">
        <h1 className="t-lineHeight--2.5 l-margins--0 t-ellipsis">{party.partyId}</h1>
        <h4 className="t-lineHeight--1.5 l-margins--0 t-ellipsis">
          <PrettyUrl slashClassname="t-ghosted l-paddings--h0.5 l-display--inline-block">{app.partyUrl}</PrettyUrl>
        </h4>
      </div>
      <div className="l-flex__spacer" />
      <div className="l-margin--l-auto">
        <button className="button--icon" onClick={showSharePopup}>
          <ShareIcon className="Icon" />
        </button>
      </div>
    </div>
  )
}

export default observer(PartyToolbar)
