import React, { useContext } from 'react'

import { dependencies } from 'config/enums'

const DependencyContext = React.createContext({})

const selectDependencies = (dependencies, select) => {
  // eslint-disable-next-line no-unused-vars
  return select && select.length > 0
    ? Object.fromEntries(Object.entries(dependencies).filter(([name]) => select.includes(name)))
    : dependencies
}

const withDependencies = (...selectedDependencies) => {
  return WrappedComponent =>
    // eslint-disable-next-line react/display-name
    props => (
      <DependencyContext.Consumer>
        {dependencies => <WrappedComponent {...props} {...selectDependencies(dependencies, selectedDependencies)} />}
      </DependencyContext.Consumer>
    )
}

const useDependencies = (...selectedDependencies) => {
  const dependencies = useContext(DependencyContext)
  return selectDependencies(dependencies, selectedDependencies)
}

export { DependencyContext, withDependencies, useDependencies, dependencies }
