import React from 'react'

import BasicHtmlLoader from 'components/ui/HtmlLoader'
import Loader from 'components/common/Loader'
import Text from 'components/ui/Text'
import { ErrorIcon } from 'components/ui/icons'

const HtmlLoader = props => {
  return <BasicHtmlLoader LoaderComponent={Loading} ErrorComponent={Error} {...props} />
}

const Loading = () => (
  <div className="l-align--center l-margins--v">
    <Loader size="small" />
  </div>
)

const Error = () => (
  <div className="l-align--center l-margins--v">
    <p>
      <ErrorIcon className="Icon Icon--larger Icon--secondary" />
      <br />
      <Text>general.cant_load_content</Text>
    </p>
  </div>
)

export default HtmlLoader
