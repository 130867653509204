import React from 'react'

const PlaysomeLogo = props => {
  const { ...otherProps } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.87 47.61" {...otherProps}>
      <g>
        <g>
          <path
            d="M4.27,38.75a4.18,4.18,0,0,1,2.33.54,2,2,0,0,1,.81,1.79,2,2,0,0,1-.82,1.8,4.19,4.19,0,0,1-2.35.55H3.77v2.32H2.24V38.93a8.49,8.49,0,0,1,1.06-.14Q3.85,38.75,4.27,38.75Zm.1,1.33H4l-.28,0v2h.48a2.56,2.56,0,0,0,1.2-.22.85.85,0,0,0,.4-.82,1,1,0,0,0-.1-.48.78.78,0,0,0-.29-.3A1.28,1.28,0,0,0,5,40.13,3.74,3.74,0,0,0,4.37,40.08Z"
            transform="translate(-2.24)"
          />
          <path
            d="M10.51,45.86a3.55,3.55,0,0,1-1-.14,1.52,1.52,0,0,1-.63-.37,1.22,1.22,0,0,1-.31-.57A3.14,3.14,0,0,1,8.45,44V38.24L9.91,38v5.73a2,2,0,0,0,0,.36.63.63,0,0,0,.11.27.6.6,0,0,0,.24.18,1.3,1.3,0,0,0,.42.09Z"
            transform="translate(-2.24)"
          />
          <path
            d="M13.55,40.36a3.33,3.33,0,0,1,1.08.15,1.66,1.66,0,0,1,.69.43,1.59,1.59,0,0,1,.37.68,3.4,3.4,0,0,1,.11.89v3.1l-.87.17a8.2,8.2,0,0,1-1.35.1,4.17,4.17,0,0,1-.91-.09,1.94,1.94,0,0,1-.7-.29,1.33,1.33,0,0,1-.45-.53,1.88,1.88,0,0,1-.16-.81,1.56,1.56,0,0,1,.18-.78,1.42,1.42,0,0,1,.49-.51,2.18,2.18,0,0,1,.7-.27,3.83,3.83,0,0,1,.81-.08l.5,0a2.13,2.13,0,0,1,.36.07v-.14a.83.83,0,0,0-.23-.61,1.08,1.08,0,0,0-.78-.23,5,5,0,0,0-.74.05,3.15,3.15,0,0,0-.63.16l-.19-1.2.32-.08.42-.08.48-.06Zm.12,4.36h.41l.31,0V43.55l-.26,0-.32,0-.39,0a1,1,0,0,0-.32.09.55.55,0,0,0-.22.19.54.54,0,0,0-.08.3.52.52,0,0,0,.23.49A1.25,1.25,0,0,0,13.67,44.72Z"
            transform="translate(-2.24)"
          />
          <path
            d="M21.58,40.5q-.44,1.52-.92,2.85T19.6,45.93a5.94,5.94,0,0,1-.41.76,2.35,2.35,0,0,1-.45.52,1.58,1.58,0,0,1-.55.3,2.46,2.46,0,0,1-.72.09,2.92,2.92,0,0,1-.63-.06,2.8,2.8,0,0,1-.48-.15l.25-1.19a2.47,2.47,0,0,0,.4.11,2.17,2.17,0,0,0,.37,0,.81.81,0,0,0,.6-.21,1.74,1.74,0,0,0,.36-.59q-.5-1-1-2.25a28.3,28.3,0,0,1-.94-2.79H18q.1.39.23.84t.28.92q.15.47.3.91t.29.8q.13-.36.27-.8t.27-.91l.26-.92.22-.84Z"
            transform="translate(-2.24)"
          />
          <path
            d="M23.83,44.69a1.43,1.43,0,0,0,.57-.08.31.31,0,0,0,.17-.31.38.38,0,0,0-.21-.32,4.1,4.1,0,0,0-.66-.3q-.34-.13-.62-.27a1.85,1.85,0,0,1-.48-.33,1.37,1.37,0,0,1-.3-.47,1.75,1.75,0,0,1-.11-.65,1.42,1.42,0,0,1,.54-1.17,2.31,2.31,0,0,1,1.48-.43,4.73,4.73,0,0,1,.9.08,4.34,4.34,0,0,1,.69.19l-.26,1.16a5.22,5.22,0,0,0-.55-.16,2.93,2.93,0,0,0-.67-.07c-.46,0-.69.13-.69.39a.41.41,0,0,0,0,.16.34.34,0,0,0,.12.14l.24.14.39.17a5.94,5.94,0,0,1,.79.36,1.92,1.92,0,0,1,.5.38,1.17,1.17,0,0,1,.25.45,2.06,2.06,0,0,1,.07.58,1.34,1.34,0,0,1-.57,1.18,2.81,2.81,0,0,1-1.62.4A4.53,4.53,0,0,1,22,45.58l.25-1.21a4.77,4.77,0,0,0,.76.23A3.65,3.65,0,0,0,23.83,44.69Z"
            transform="translate(-2.24)"
          />
          <path
            d="M31.93,43.12a3.47,3.47,0,0,1-.18,1.13,2.46,2.46,0,0,1-.51.88,2.3,2.3,0,0,1-.8.57,2.81,2.81,0,0,1-2.08,0,2.3,2.3,0,0,1-.8-.57,2.58,2.58,0,0,1-.52-.88,3.3,3.3,0,0,1-.19-1.13A3.18,3.18,0,0,1,27.05,42a2.55,2.55,0,0,1,.53-.87,2.38,2.38,0,0,1,.8-.56,2.55,2.55,0,0,1,1-.2,2.58,2.58,0,0,1,1,.2,2.32,2.32,0,0,1,.8.56,2.53,2.53,0,0,1,.52.87A3.27,3.27,0,0,1,31.93,43.12Zm-1.49,0A1.9,1.9,0,0,0,30.17,42a.88.88,0,0,0-.77-.4.89.89,0,0,0-.77.4,1.87,1.87,0,0,0-.27,1.08,1.92,1.92,0,0,0,.27,1.09.88.88,0,0,0,.77.41.87.87,0,0,0,.77-.41A2,2,0,0,0,30.44,43.12Z"
            transform="translate(-2.24)"
          />
          <path
            d="M35.89,43a2.08,2.08,0,0,0-.18-1,.67.67,0,0,0-.62-.3l-.28,0-.28,0v4.06H33.06V40.68l.44-.1.53-.1.57-.07.58,0a2.39,2.39,0,0,1,.91.14,2,2,0,0,1,.57.35,2.69,2.69,0,0,1,1.47-.49,2.54,2.54,0,0,1,1,.17,1.55,1.55,0,0,1,.63.5,1.9,1.9,0,0,1,.32.76,5,5,0,0,1,.09,1v3H38.72V43a2.1,2.1,0,0,0-.18-1,.67.67,0,0,0-.62-.3,1.61,1.61,0,0,0-.68.21,2.36,2.36,0,0,1,.09.49c0,.17,0,.35,0,.54v2.87H35.89Z"
            transform="translate(-2.24)"
          />
          <path
            d="M41.26,43.17a3.28,3.28,0,0,1,.21-1.23,2.61,2.61,0,0,1,.55-.87,2.31,2.31,0,0,1,.79-.53,2.42,2.42,0,0,1,.92-.18,2.26,2.26,0,0,1,1.74.69,2.86,2.86,0,0,1,.64,2c0,.09,0,.18,0,.29s0,.2,0,.28H42.76a1,1,0,0,0,.42.73,1.67,1.67,0,0,0,1,.27,4.17,4.17,0,0,0,.79-.08,2.87,2.87,0,0,0,.63-.19l.2,1.21a2,2,0,0,1-.31.12l-.44.11-.51.08-.55,0a3.18,3.18,0,0,1-1.21-.21,2.35,2.35,0,0,1-.85-.57,2.32,2.32,0,0,1-.5-.87A3.51,3.51,0,0,1,41.26,43.17Zm3.43-.57a1.53,1.53,0,0,0-.06-.37.94.94,0,0,0-.17-.32.9.9,0,0,0-.29-.23.91.91,0,0,0-.43-.09,1,1,0,0,0-.42.08.88.88,0,0,0-.29.22,1,1,0,0,0-.18.33,2.24,2.24,0,0,0-.09.38Z"
            transform="translate(-2.24)"
          />
        </g>
        <g>
          <g>
            <path
              d="M24,19a5,5,0,1,0,5,5A5,5,0,0,0,24,19Zm2.73,5.69-3.42,2A.79.79,0,0,1,22.13,26V22a.79.79,0,0,1,1.19-.69l3.42,2A.79.79,0,0,1,26.73,24.69Z"
              transform="translate(-2.24)"
            />
            <path
              d="M38.56,25.73l-3.43-2.06a13,13,0,0,1-22.26,0L9.44,25.73A17.09,17.09,0,0,0,19,33.24V37a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V33.24A17.09,17.09,0,0,0,38.56,25.73Z"
              transform="translate(-2.24)"
            />
          </g>
          <g>
            <path
              className="Playsome-logo--primary"
              d="M25.75,16a5,5,0,1,0,2-6.79A5,5,0,0,0,25.75,16Zm3.66-5.13,3.38,2a.79.79,0,0,1,0,1.37l-3.45,1.9a.79.79,0,0,1-1.17-.71l.08-3.94A.79.79,0,0,1,29.41,10.9Z"
              transform="translate(-2.24)"
            />
            <path
              className="Playsome-logo--primary"
              d="M44.7,9,41.83,3.77A2,2,0,0,0,39.08,3L35.8,4.79A16.93,16.93,0,0,0,24,0V4A13,13,0,0,1,35.13,23.68l3.43,2.06a16.88,16.88,0,0,0,2.09-12.2l3.27-1.8A2,2,0,0,0,44.7,9Z"
              transform="translate(-2.24)"
            />
          </g>
          <g>
            <path
              className="Playsome-logo--secondary"
              d="M11,17A13,13,0,0,1,24,4V0A17,17,0,0,0,9.44,25.73l3.43-2.06A12.9,12.9,0,0,1,11,17Z"
              transform="translate(-2.24)"
            />
            <path
              className="Playsome-logo--secondary"
              d="M22.28,16a5,5,0,1,0-6.86,1.73A5,5,0,0,0,22.28,16ZM16,15.4l.06-3.94a.79.79,0,0,1,1.2-.67l3.39,2a.79.79,0,0,1,0,1.37L17.18,16.1A.79.79,0,0,1,16,15.4Z"
              transform="translate(-2.24)"
            />
            <path
              className="Playsome-logo--secondary"
              d="M12.65,4.38,9.42,2.46a2,2,0,0,0-2.77.7L3.59,8.26A2,2,0,0,0,4.29,11L7.5,13A16.7,16.7,0,0,0,9.44,25.73l3.43-2.06A13,13,0,0,1,24,4V0A16.91,16.91,0,0,0,12.65,4.38Z"
              transform="translate(-2.24)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PlaysomeLogo
