import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { popupSizes } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import { CloseIcon } from 'components/ui/icons'

const Popup = props => {
  const { popup } = props
  let { visible, component, size } = popup
  const popupElementRef = useRef()

  const popupComponent = useMemo(() => {
    if (component) {
      switch (typeof component) {
        case 'object':
          return React.cloneElement(component, { onHide: popup.hide })
        case 'function':
          return React.createElement(component, { onHide: popup.hide })
      }
    }
    return null
  }, [component])

  const handleKey = useCallback(
    event => {
      if (event.key === 'Escape') {
        event.stopPropagation()
        popup.hide()
      }
    },
    [popup]
  )

  if (visible) {
    return (
      <div className="Popup__overlay" onClick={popup.hide}>
        <div
          className={classNames('Popup', size ? 'Popup--' + size : '')}
          tabIndex={0}
          onClick={event => event.stopPropagation()}
          onKeyDown={handleKey}
          ref={popupElementRef}
        >
          {popupComponent}
        </div>
      </div>
    )
  } else {
    return null
  }
}

const PopupContainer = props => {
  const { children, ...otherProps } = props
  return (
    <div className="Popup__container" {...otherProps}>
      {children}
    </div>
  )
}

const PopupHeader = props => {
  const { children, className, onHide } = props
  return (
    <div className={classNames('Popup__header', className)}>
      <div className="Popup__header__title">{children}</div>
      <div className="Popup__header__closeButton">
        <button className="button--icon button--hollow button--secondary" onClick={onHide} data-test="PopupCloseButton">
          <CloseIcon className="Icon" />
        </button>
      </div>
    </div>
  )
}

const PopupContent = props => {
  const { className, scrolling, children, ...otherProps } = props
  return (
    <div className={classNames('Popup__content', scrolling && 'Popup__content--scrolling', className)} {...otherProps}>
      {children}
    </div>
  )
}
PopupContent.defaultProps = {
  scrolling: false,
}

const PopupFooter = props => {
  const { className, children } = props
  return <div className={classNames('Popup__footer', className)}>{children}</div>
}

export default withDependencies(dependencies.popup)(observer(Popup))
export { PopupContainer, PopupHeader, PopupContent, PopupFooter, popupSizes }
