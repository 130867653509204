import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'

import { dependencies, useDependencies } from 'components/Context'
import ShareIcon from 'components/common/ShareIcon'
import Avatar from 'components/ui/Avatar'
import { PopupContainer, PopupHeader, PopupContent } from 'components/ui/Popup'
import ImagePopup from 'components/common/popups/ImagePopup'
import Text from 'components/ui/Text'
import TextInput from 'components/ui/TextInput'
import { ClipboardPlusIcon, LinkIcon, QRCodeIcon } from 'components/ui/icons'
import * as providerColors from 'styles/export/providerColors'

const SharePopup = () => {
  const { alert, analytics, app, browser, locale, party, popup, share, userList } = useDependencies(
    dependencies.alert,
    dependencies.analytics,
    dependencies.app,
    dependencies.browser,
    dependencies.locale,
    dependencies.party,
    dependencies.popup,
    dependencies.share,
    dependencies.userList
  )
  const shareProviders = share.getProviders()
  const partyUrl = app.partyUrl
  const hasClipboard = browser.hasClipboard
  const linkTextComponentRef = useRef()

  const relayClickToCopy = () => {
    if (linkTextComponentRef.current) linkTextComponentRef.current.click()
  }

  const handleClickToCopy = event => {
    const element = event.target
    element.setSelectionRange(0, element.value.length)
    if (hasClipboard) {
      navigator.clipboard.writeText(partyUrl).then(() => {
        alert.show(locale.translate('alert.copied_to_clipboard'))
      })
    }
    analytics.events.shareParty('url')
  }

  const handleQRCode = () => {
    import(/* webpackChunkName: "qrcode" */ 'qrcode')
      .then(QRCode =>
        QRCode.toDataURL(partyUrl, {
          width: 256,
          margin: 2,
        })
      )
      .then(qrDataUrl => {
        const filename = locale.translate('label.image_download_filename', {
          partyId: party.partyId,
          fileExt: 'png',
        })
        popup.show({
          component: (
            <ImagePopup url={qrDataUrl} alt={partyUrl} title={<Text>general.qr_code</Text>} downloadable={filename} />
          ),
        })
      })
    analytics.events.shareParty('qrcode')
  }

  const handleShareClick = shareProviderId => {
    const host = Array.from(userList.values()).shift()
    const hostName = host.name || host.id
    share.share(
      shareProviderId,
      partyUrl,
      locale.translate('label.share_message', { partyId: party.partyId, hostName }),
      locale.translate('label.share_title', { partyId: party.partyId, hostName })
    )
    popup.hide()
    analytics.events.shareParty(shareProviderId)
  }

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <Text className="l-margins--h">general.invite_friends</Text>
      </PopupHeader>
      <PopupContent scrolling={true}>
        <div className="l-paddings--h" />
        <ul className="List">
          <li className="ListItem ListItem--clickable" onClick={relayClickToCopy}>
            <Avatar className="ListItem__icon">
              <LinkIcon className="Icon Icon--white" />
            </Avatar>
            <div className="ListItem__content">
              <TextInput
                className="l-margin--b0"
                icon={hasClipboard && <ClipboardPlusIcon className="Icon Icon--white" />}
                readOnly
                value={partyUrl}
                onClick={handleClickToCopy}
                inputRef={linkTextComponentRef}
              />
            </div>
          </li>

          <li className="ListItem ListItem--clickable" onClick={handleQRCode}>
            <Avatar className="ListItem__icon">
              <QRCodeIcon className="Icon Icon--white" />
            </Avatar>
            <div className="ListItem__content">
              <Text>general.qr_code</Text>
            </div>
          </li>

          {shareProviders.map(shareProviderId => (
            <li
              key={shareProviderId}
              className="ListItem ListItem--clickable"
              onClick={() => handleShareClick(shareProviderId)}
            >
              <Avatar className="ListItem__icon" backgroundColor={providerColors[shareProviderId] || null}>
                <ShareIcon shareProviderId={shareProviderId} color={false} className="Icon" />
              </Avatar>
              <Text className="ListItem__content">{`shareProviders.name.${shareProviderId}`}</Text>
            </li>
          ))}
        </ul>
      </PopupContent>
    </PopupContainer>
  )
}

export default observer(SharePopup)
