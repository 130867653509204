import logger from 'services/logger'

class SpotifyWebDevice {
  static deviceName = 'Spotify Web Player for Playsome'
  config
  auth
  deviceId
  spotifyPlayer
  spotify
  subscribers = []

  constructor(config, _, spotify) {
    this.config = config
    this.spotify = spotify
  }

  initialize(_, auth) {
    this.auth = auth
    return new Promise((resolve, reject) => {
      if ('Spotify' in window) {
        resolve(window.Spotify)
      }
      if (!this.auth || (this.auth && !this.auth.isLoggedInToProvider('spotify'))) {
        reject('spotifyAuthRequiredError')
      }
      const script = document.createElement('script')
      script.onerror = () => reject('scriptLoadError')
      script.src = this.config.spotify.url.webPlayer
      window.onSpotifyWebPlaybackSDKReady = () => resolve(window.Spotify)
      document.head.appendChild(script)
    }).then(Spotify => {
      logger.log('spotifyWebDevice.initialize', this.auth)
      const { access_token } = this.auth.getAuthData()
      this.spotifyPlayer = new Spotify.Player({
        name: SpotifyWebDevice.deviceName,
        getOAuthToken: callback => {
          callback(access_token)
        },
      })

      return new Promise((resolve, reject) => {
        // Error handling
        this.spotifyPlayer.addListener('initialization_error', () => reject('spotifyWebPlayerInitializationError'))
        this.spotifyPlayer.addListener('authentication_error', this.handleError)
        this.spotifyPlayer.addListener('account_error', this.handleError)
        this.spotifyPlayer.addListener('playback_error', this.handleError)

        // Playback status updates
        this.spotifyPlayer.addListener('player_state_changed', this.handleStateChange)

        // Ready
        this.spotifyPlayer.addListener('ready', async ({ device_id }) => {
          logger.log('SpotifyWebDevice.initialize.ready', device_id)
          this.deviceId = device_id
          resolve(this)
        })

        // Not Ready
        this.spotifyPlayer.addListener('not_ready', () => reject('spotifyWebPlayerNotReadyError'))

        // Connect to the player
        return this.spotifyPlayer.connect()
      }).then(connected => {
        if (connected) {
          return this
        } else {
          throw new Error('spotifyWebPlayerConnectionError')
        }
      })
    })
  }

  destroy() {
    this.spotifyPlayer.disconnect()
    return this
  }

  static isAvailable(browser) {
    logger.log('SpotifyWebDevice.isAvailable', browser.isFirefox, browser.isChrome, browser.isEdge)
    // https://developer.spotify.com/documentation/web-playback-sdk/#supported-browsers
    return !browser.isMobile && (browser.isFirefox || browser.isChrome || browser.isEdge)
  }

  subscribe(callback) {
    this.subscribers.push(callback)
    return () => {
      this.subscribers = this.subscribers.filter(subscriber => subscriber !== subscriber)
    }
  }

  handleError = message => {
    logger.log('SpotifyWebDevice.handleError', message)
    this.subscribers.forEach(callback => {
      callback({
        event: 'error',
        data: message,
      })
    })
  }

  handleStateChange = async state => {
    logger.log('SpotifyWebDevice.handleStateChange', state)
    let sendState
    const volume = await this.spotifyPlayer.getVolume()
    if (state) {
      const {
        paused,
        position,
        track_window: { current_track },
      } = state
      let currentTrackId =
        current_track.linked_from && current_track.linked_from.id ? current_track.linked_from.id : current_track.id
      sendState = {
        isPlaying: !paused,
        currentTime: position,
        currentTrackId,
        volume,
      }
    } else {
      sendState = {
        isPlaying: true,
        currentTime: 0,
        currentTrackId: null,
        volume: null,
      }
    }
    this.subscribers.forEach(callback => {
      callback({
        event: 'state',
        data: sendState,
      })
    })
  }

  async setVolume(volume) {
    this.spotifyPlayer.setVolume(volume)
  }

  async play(track) {
    await this.spotify.playTrack(this.deviceId, track)
    setTimeout(async () => {
      const state = await this.spotifyPlayer.getCurrentState()
      const volume = await this.spotifyPlayer.getVolume()
      await this.handleStateChange({ ...state, volume })
    }, 100)
  }

  pause() {
    return this.spotifyPlayer.pause()
  }

  resume() {
    return this.spotifyPlayer.resume()
  }

  seek(position) {
    return this.spotify.seekTrack(position)
  }

  transfer(device_id, play) {
    return this.spotify.transferDevice(device_id, play)
  }

  poll() {
    return this.spotifyPlayer.getCurrentState().then(data => {
      logger.log('SpotifyWebDevice.poll', data)
      return this.handleStateChange(data)
    })
  }
}

export default SpotifyWebDevice
