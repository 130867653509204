export default class Store {
  driver

  constructor(driver) {
    this.driver = driver
  }

  has(key) {
    for (let k = 0; k < this.driver.length; k++) {
      if (this.driver.key(k) === key) return true
    }
    return false
  }

  getKeys() {
    const keys = []
    for (let k = 0; k < this.driver.length; k++) {
      keys.push(this.driver.key(k))
    }
    return keys
  }

  getItem(key) {
    let value = this.driver.getItem(key)
    if (value === null || value === undefined) return value
    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }

  setItem(key, value) {
    return this.driver.setItem(key, JSON.stringify(value))
  }

  removeItem(key) {
    return this.driver.removeItem(key)
  }

  clear() {
    return this.driver.clear()
  }
}
