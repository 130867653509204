import React, { useCallback, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { throttle } from 'lodash'
import classNames from 'classnames'

import { withDependencies, dependencies } from 'components/Context'
import { PopupContainer, PopupHeader, PopupContent } from 'components/ui/Popup'
import { TrackArtistAlbumLine } from 'components/common/TrackLine'
import Avatar from 'components/ui/Avatar'
import VolumeControl from 'components/ui/VolumeControl'
import Text from 'components/ui/Text'
import UserAvatar from 'components/common/UserAvatar'
import TimeLine from 'components/common/TimeLine'
import { PlayCircleIcon, PauseCircleIcon, DevicesIcon } from 'components/ui/icons'

function PlayingPopup(props) {
  const { popup, showDevices, togglePlayPause } = props
  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <Text className="l-margins--h">general.now_playing</Text>
      </PopupHeader>
      <PopupContent scrolling={false} className="l-rows">
        <div className="l-flex l-overflow--hidden l-max-height--30 l-display--none l-display--block--height--sm">
          <AlbumCover />
        </div>
        <div className="l-flex l-rows">
          <CurrentTrack className="l-margins--h l-margin--t" />
          <TimeLine className="l-margins--h" />
          <Controls className="l-margins--h" showDevices={showDevices} togglePlayPause={togglePlayPause} />
          <NextTrack className="l-margins--h l-margin--b" />
        </div>
      </PopupContent>
    </PopupContainer>
  )
}
export default withDependencies(dependencies.popup)(observer(PlayingPopup))

const AlbumCover = withDependencies(dependencies.player)(
  observer(({ className, player, ...otherProps }) => {
    return (
      <div
        className={classNames('u-background-900 u-bg--contain l-height--100%', className)}
        style={player.currentTrack ? { backgroundImage: `url(${player.currentTrack.album.imageUrl})` } : {}}
        {...otherProps}
      />
    )
  })
)

const CurrentTrack = withDependencies(
  dependencies.player,
  dependencies.userList
)(
  observer(props => {
    const { player, userList, ...otherProps } = props
    if (player.currentTrack) {
      return (
        <div {...otherProps}>
          <div className="l-columns l-align-items--center l-margin--b">
            <Avatar
              src={player.currentTrack.album.imageUrl}
              rounded={false}
              className="l-flex--b3 l-flex--shrink-0 l-margin--r l-display--block l-display--none--height--sm"
            />
            <h3 className="t-ellipsis l-flex--grow-1 l-margins--v0 l-overflow--hidden">
              {player.currentTrack.name}
              <br />
              <TrackArtistAlbumLine track={player.currentTrack} />
            </h3>
            <div className="l-flex-spacer--1" />
            <UserAvatar
              className="l-flex--b3 l-flex--grow-0 l-flex--shrink-0"
              user={player.currentTrack && userList.get(player.currentTrack.userId)}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div {...otherProps}>
          <h3 className="l-margins--v t-ellipsis">&hellip;</h3>
        </div>
      )
    }
  })
)

const ControlsComponent = props => {
  const { analytics, className, player, showDevices, togglePlayPause, ...otherProps } = props
  const [volume, setVolume] = useState(player.volume)
  const lastVolumeRef = useRef(volume)

  const handleSetVolume = useCallback(event => {
    const newVolume = event.target.value / 100
    setVolume(newVolume)
    setPlayerVolumeThrottled(newVolume)
  }, [])

  const setPlayerVolumeThrottled = useCallback(
    throttle(value => {
      player.setVolume(value)
    }, 500),
    [player]
  )

  const toggleMute = () => {
    if (volume === 0) {
      setVolume(lastVolumeRef.current)
    } else {
      lastVolumeRef.current = volume
      setVolume(0)
    }
  }

  const handlePlayPause = useCallback(
    event => {
      event.stopPropagation()
      togglePlayPause()
    },
    [togglePlayPause]
  )

  const handleDevicesClick = useCallback(
    async event => {
      event.stopPropagation()
      showDevices()
      await analytics.events.showPopup('spotify_device')
    },
    [showDevices]
  )

  return (
    <div className={classNames('l-columns l-align-items--center', className)} {...otherProps}>
      <div className="l-flex--b50% l-flex--grow-1">
        <VolumeControl value={Math.round(volume * 100)} onChange={handleSetVolume} onClick={toggleMute} />
      </div>
      <div className="l-align--center l-flex--b3 l-flex--grow-0">
        <button className="button--icon button--huge button--hollow" onClick={handlePlayPause}>
          {player.isPlaying ? (
            <PauseCircleIcon className="Icon Icon--huge" />
          ) : (
            <PlayCircleIcon className="Icon Icon--huge" />
          )}
        </button>
      </div>
      <div className="l-flex--b50% l-flex--grow-1 l-align--right">
        <button className="button--icon" onClick={handleDevicesClick}>
          <DevicesIcon className="Icon" />
        </button>
      </div>
    </div>
  )
}
const Controls = withDependencies(dependencies.analytics, dependencies.player)(observer(ControlsComponent))

const NextTrack = withDependencies(
  dependencies.masterPlaylist,
  dependencies.player,
  dependencies.userList
)(
  observer(props => {
    const { masterPlaylist, player, userList, ...otherProps } = props
    const nextTrack = player.currentTrack ? masterPlaylist.getNextUnplayedTrack(player.currentTrack) : null
    return (
      nextTrack && (
        <div {...otherProps}>
          <h4 className="l-margin--t0">
            <Text>general.next_up</Text>
          </h4>
          <ul className="List">
            <li className="ListItem l-paddings--h0">
              <div className="ListItem__icon">
                <Avatar src={nextTrack.album.imageUrl} rounded={false} />
              </div>
              <div className="ListItem__content">
                <span className="t-ellipsis">{nextTrack.name}</span>
                <TrackArtistAlbumLine track={nextTrack} className="t-ellipsis" />
              </div>
              <div className="ListItem__icon">
                <UserAvatar user={userList.get(nextTrack.userId)} />
              </div>
            </li>
          </ul>
        </div>
      )
    )
  })
)
