import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function ProgressCircle(props) {
  const { className, style, value, size, thickness, color, ...otherProps } = props
  const svgStyle = Object.assign(
    {
      width: size + 'px',
      height: size + 'px',
      transform: 'rotate(-90deg)',
    },
    style
  )
  const pathStyle = {
    stroke: color,
    strokeLinecap: 'round',
    strokeDasharray: `${Math.PI * value * (size - thickness)}, ${Math.PI * (size - thickness)}`,
  }
  if (size) {
    style.width = size + 'px'
    style.height = size + 'px'
  }
  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      className={classNames('ProgressCircle', className)}
      style={svgStyle}
      {...otherProps}
    >
      <circle
        style={pathStyle}
        cx={size / 2}
        cy={size / 2}
        r={(size - thickness) / 2}
        fill="none"
        strokeWidth={thickness}
        strokeMiterlimit="20"
      />
    </svg>
  )
}
ProgressCircle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
  thickness: PropTypes.number,
  value: PropTypes.number,
}
ProgressCircle.defaultProps = {
  className: '',
  style: {},
  color: 'white',
  size: 48,
  thickness: 4,
}

export default ProgressCircle
