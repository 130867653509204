import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'

import { withDependencies, dependencies } from 'components/Context'
import Loader from 'components/common/Loader'
import Text from 'components/ui/Text'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'

const ImagePopup = props => {
  const { url, alt, svg, title, downloadable, popup } = props
  const [loading, setLoading] = useState(!!url)

  return (
    <PopupContainer>
      <PopupHeader onHide={popup.hide}>
        <span className="l-margins--h">{title}</span>
      </PopupHeader>
      <PopupContent scrolling={true} className="l-paddings--h">
        <div className="l-flex l-flex--centered-child l-height--100% l-paddings">
          {loading && (
            <div>
              <Loader />
            </div>
          )}
          {url && <img src={url} onLoad={() => setLoading(false)} alt={alt} />}
          {svg && <div dangerouslySetInnerHTML={{ __html: svg }} />}
        </div>
      </PopupContent>
      {downloadable && (
        <PopupFooter className="l-columns">
          <a
            className="l-flex l-flex--b50% button button--hollow button--primary"
            tabIndex={5}
            href={url}
            download={downloadable}
            onClick={popup.hide}
          >
            <Text>general.download</Text>
          </a>
          <button className="l-flex l-flex--b50% button--hollow button--secondary" tabIndex={6} onClick={popup.hide}>
            <Text>general.close</Text>
          </button>
        </PopupFooter>
      )}
    </PopupContainer>
  )
}

export default withRouter(withDependencies(dependencies.config, dependencies.popup)(observer(ImagePopup)))
