import React from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Link } from 'react-router-dom'
import classNames from 'classnames'

import { withDependencies, dependencies } from 'components/Context'
import { AnimatedBadge } from 'components/ui/Badge'
import Text from 'components/ui/Text'
import { GroupIcon, MoodIcon, QueueMusicIcon } from 'components/ui/icons'

const Footer = props => {
  const { userList } = props
  return (
    <div className="Footer l-columns l-columns--justify-space-around">
      <FooterLink to="/party/playlist" className="l-flex--b33%" data-test="FooterLink_playlist">
        <QueueMusicIcon className="Icon" /> <Text>page.title.playlist</Text>
      </FooterLink>
      <FooterLink to="/party/party" className="l-flex--b33%" data-test="FooterLink_party">
        <MoodIcon className="Icon" /> <Text>page.title.party</Text>
      </FooterLink>
      <FooterLink to="/party/people" className="l-flex--b33%" data-test="FooterLink_people">
        <AnimatedBadge value={userList.size}>
          <GroupIcon className="Icon" />
        </AnimatedBadge>
        <Text>page.title.people</Text>
      </FooterLink>
    </div>
  )
}

const FooterLink = props => {
  const { to, className, children, ...otherProps } = props
  return (
    <Route
      path={to}
      exact={true}
      children={({ match }) => (
        <Link
          to={to}
          className={classNames(className, 'Footer__link', match && 'Footer__link--active')}
          {...otherProps}
        >
          {children}
        </Link>
      )}
    />
  )
}

export default withDependencies(dependencies.userList)(observer(Footer))
