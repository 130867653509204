import { useEffect, useState } from 'react'

function getRemSize(bodyElement = document.body) {
  const { fontSize } = getComputedStyle(bodyElement)
  return parseInt(fontSize, 10)
}

function useRemSize() {
  const [size, setSize] = useState(getRemSize())
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const { fontSize } = getComputedStyle(entries[0].target)
      setSize(parseInt(fontSize, 10))
    })
    resizeObserver.observe(document.body)
    return () => resizeObserver.disconnect()
  }, [])
  return size
}

export default useRemSize
