import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { withDependencies, dependencies } from 'components/Context'
import Alert from 'components/ui/Alert'
import { ChevronDownIcon, ChevronUpIcon } from 'components/ui/icons'

const cssTransitionTimeouts = {
  appear: 200,
  enter: 0,
  exit: 200,
}
const cssTransitionClassnames = {
  appear: 'Alert--appear',
  appearActive: 'Alert--appear-active',
  appearDone: 'Alert--appear-done',
  enter: 'Alert--enter',
  enterActive: 'Alert--enter-active',
  enterDone: 'Alert--enter-done',
  exit: 'Alert--exit',
  exitActive: 'Alert--exit-active',
  exitDone: 'Alert--exit-done',
}

function Alerts(props) {
  const { alert, ...otherProps } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = () => setIsExpanded(previous => !previous)

  return (
    <div className="Alert__container">
      <TransitionGroup className="Alert__scroller">
        {alert.list
          .filter((alert, index) => isExpanded || index === 0)
          .map(current => (
            <CSSTransition key={current.id} timeout={cssTransitionTimeouts} classNames={cssTransitionClassnames}>
              <Alert alert={current} onClick={current && current.onClick ? current.onClick : null} {...otherProps} />
            </CSSTransition>
          ))}
      </TransitionGroup>
      {alert.list.length > 1 && (
        <button
          className="AlertExpander button--icon button--hollow l-position__relative"
          onClick={() => toggleExpanded()}
        >
          <div className="Badge Badge--white-faded" data-value={alert.list.length} />
          {isExpanded ? <ChevronUpIcon className="icon" /> : <ChevronDownIcon className="icon" />}
        </button>
      )}
    </div>
  )
}

Alerts.propTypes = {
  alert: PropTypes.shape({
    list: PropTypes.array,
    show: PropTypes.func,
    hide: PropTypes.func,
  }),
  className: PropTypes.string,
}
Alerts.defaultProps = {
  className: '',
}

export default withDependencies(dependencies.alert)(observer(Alerts))
