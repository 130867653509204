import React from 'react'

import { PopupContainer, PopupContent, PopupFooter } from 'components/ui/Popup'

const Confirmation = props => {
  const { message, actionLabel, cancelLabel, onActionClick, onCancelClick } = props
  return (
    <PopupContainer>
      <PopupContent>
        <div className="l-margins">{message}</div>
      </PopupContent>
      <PopupFooter className="l-columns">
        <button
          key="confirmation_action"
          className="l-flex l-flex--b50% button--hollow button--primary"
          onClick={onActionClick}
          data-test="ConfirmationActionButton"
        >
          {actionLabel}
        </button>
        <button
          key="confirmation_cancel"
          className="l-flex l-flex--b50% button--hollow button--secondary"
          onClick={onCancelClick}
        >
          {cancelLabel}
        </button>
      </PopupFooter>
    </PopupContainer>
  )
}

export default Confirmation
