import { useEffect } from 'react'

const useBeforeInstallPrompt = onPrompt => {
  const handleBeforeInstallPrompt = event => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault()
    // Provide the event prompt method so it can be triggered later
    onPrompt(event.prompt, event.userChoice)
  }

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
  }, [onPrompt])
}

export default useBeforeInstallPrompt
