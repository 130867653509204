import React, { Fragment, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Field, Form } from 'react-final-form'

import { alerts, serverEvents } from 'config/enums'
import { PARTY_OPTIONS_DEFAULT } from 'config/party'
import logger from 'services/logger'
import { duration } from 'functions/format'
import { withDependencies, dependencies } from 'components/Context'
import { isPartyId, isPartyPassword } from 'functions/validation'
import Text from 'components/ui/Text'
import TextInput from 'components/ui/TextInput'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'

const LAST_PARTY_ID_KEY = 'party.lastPartyId'
const LAST_PARTY_PASSWORD_KEY = 'party.lastPartyPassword'
const LAST_PARTY_OPTIONS_KEY = 'party.lastPartySettings'

const PartyHostPopup = props => {
  const { alert, analytics, app, party, popup, store } = props
  const editingHostedParty = party.isJoined && party.isHosted

  const initialValues = useMemo(() => {
    if (props.party.isHosted) {
      const { partyId, partyPassword, partyOptions } = party
      return {
        partyId,
        partyPassword,
        partyOptions,
      }
    } else {
      return {
        partyId: store.getItem(LAST_PARTY_ID_KEY) || '',
        partyPassword: store.getItem(LAST_PARTY_PASSWORD_KEY) || '',
        partyOptions: store.getItem(LAST_PARTY_OPTIONS_KEY) || PARTY_OPTIONS_DEFAULT,
      }
    }
  }, [party])

  const validate = useCallback(({ partyId, partyPassword }) => {
    const errors = {}
    if (!partyId || !isPartyId(partyId)) errors.partyId = 'partyIdExpectedError'
    if (partyPassword && !isPartyPassword(partyPassword)) errors.partyPassword = 'partyPasswordExpectedError'
    return errors
  }, [])

  const onHostParty = values => {
    logger.log('PartyHostPopup.handleHostParty', values)
    app
      .startParty(values.partyId, values.partyPassword, { ...PARTY_OPTIONS_DEFAULT, ...values.partyOptions })
      .then(() => {
        popup.hide()
      })
      .then(() => {
        store.setItem(LAST_PARTY_ID_KEY, values.partyId)
        store.setItem(LAST_PARTY_PASSWORD_KEY, values.partyPassword)
        store.setItem(LAST_PARTY_OPTIONS_KEY, values.partyOptions)
      })
      .then(() => {
        return analytics.events.hostParty([
          values.partyPassword && 'password',
          values.partyOptions.trackMaxLength > 0 && 'trackMaxLength',
        ])
      })
      .catch(error => {
        logger.error('PartyHostPopup.onHostParty.error', error)
        if (error === serverEvents.userNotFoundError) {
          popup.hide()
          app.logout()
        }
        alert.show(alerts.error, 'alert.' + error)
      })
  }

  const onUpdatePartyOptions = values => {
    logger.log('PartyHostPopup.onUpdatePartyOptions', values)
    try {
      app.updatePartyOptions(values.partyPassword, values.partyOptions)
      popup.hide()
      alert.show('alert.partyOptionsUpdated')
      store.setItem(LAST_PARTY_PASSWORD_KEY, values.partyPassword)
      store.setItem(LAST_PARTY_OPTIONS_KEY, values.partyOptions)
      analytics.events.updatePartyOptions()
    } catch (error) {
      alert.show(alerts.error, 'alert.' + error)
    }
  }

  return (
    <PopupContainer>
      <Form
        onSubmit={editingHostedParty ? onUpdatePartyOptions : onHostParty}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values, submitting, dirty, valid }) => (
          <Fragment>
            <PopupHeader onHide={popup.hide}>
              {editingHostedParty ? (
                <Text className="l-margins--h">general.hosted_party</Text>
              ) : (
                <Text className="l-margins--h">general.host_party</Text>
              )}
            </PopupHeader>
            <PopupContent scrolling={true}>
              <form className="l-rows l-margins" onSubmit={handleSubmit}>
                <h4 className="l-margin--t0">
                  <Text>general.party_login</Text>
                </h4>
                {editingHostedParty ? (
                  <Fragment>
                    <label>
                      <Text>general.party_name</Text>
                    </label>
                    <p className="l-margin--t0">{party.partyId}</p>
                  </Fragment>
                ) : (
                  <Field
                    name="partyId"
                    render={({ input, meta }) => (
                      <TextInput
                        containerClassName="l-margin--b"
                        label={<Text>general.party_name</Text>}
                        {...input}
                        autoFocus={true}
                        tabIndex={2}
                        error={meta.dirty && meta.invalid ? `validation.${meta.error}` : null}
                      />
                    )}
                  />
                )}
                <Field
                  name="partyPassword"
                  render={({ input, meta }) => (
                    <TextInput
                      containerClassName="l-margin--b"
                      label={<Text>general.party_password_optional</Text>}
                      {...input}
                      tabIndex={3}
                      error={meta.dirty && meta.invalid ? `validation.${meta.error}` : null}
                    />
                  )}
                />

                <h4>
                  <Text>general.party_settings</Text>
                </h4>

                <label>
                  <Text>trackFilters.name.trackMaxLength</Text>
                </label>
                <div className="l-columns">
                  <Field
                    name="partyOptions.trackMaxLength"
                    parse={value => parseInt(value, 10)}
                    render={({ input }) => (
                      <input
                        className="l-margin--b"
                        type="range"
                        {...input}
                        min={0}
                        max={20 * 60 * 1000}
                        step={10 * 1000}
                        tabIndex={4}
                      />
                    )}
                  />
                  <div className="l-flex__spacer" />
                  <p className="l-flex--b3 l-margin--t0">
                    {values.partyOptions.trackMaxLength > 0 ? (
                      duration(values.partyOptions.trackMaxLength)
                    ) : (
                      <Text>general.any</Text>
                    )}
                  </p>
                </div>

                <p>
                  <Field
                    name="partyOptions.allowTrackDuplicates"
                    type="checkbox"
                    render={({ input }) => (
                      <input
                        id="partyOptions.allowTrackDuplicates"
                        className="l-margin--r0.5 l-valign--middle"
                        type="checkbox"
                        {...input}
                        tabIndex={5}
                      />
                    )}
                  />
                  <label htmlFor="partyOptions.allowTrackDuplicates">
                    <Text>trackFilters.name.allowTrackDuplicates</Text>
                  </label>
                </p>
                <input type="submit" style={{ visibility: 'hidden' }} />
              </form>
            </PopupContent>
            <PopupFooter className="l-columns">
              <button
                type="submit"
                className="l-flex button--hollow button--primary"
                tabIndex={6}
                disabled={(party.isHosted && !dirty) || !valid || submitting}
                onClick={handleSubmit}
              >
                {editingHostedParty ? <Text>general.update</Text> : <Text>general.host</Text>}
              </button>
              <button
                className="l-flex button--hollow button--secondary"
                tabIndex={7}
                disabled={submitting}
                onClick={popup.hide}
              >
                <Text>general.cancel</Text>
              </button>
            </PopupFooter>
          </Fragment>
        )}
      />
    </PopupContainer>
  )
}

export default withDependencies(
  dependencies.alert,
  dependencies.analytics,
  dependencies.app,
  dependencies.locale,
  dependencies.party,
  dependencies.popup,
  dependencies.store
)(observer(PartyHostPopup))
