import { makeAutoObservable, reaction, toJS } from 'mobx'

import { PARTY_OPTIONS_DEFAULT } from 'config/party'

const PARTY_ID_KEY = 'party.partyId'
const PARTY_PASSWORD_KEY = 'party.partyPassword'
const PARTY_OPTIONS_KEY = 'party.partySettings'
const PARTY_ISJOINED_KEY = 'party.isJoined'
const PARTY_ISHOSTED_KEY = 'party.isHosted'

export default class Party {
  partyId = null
  isHosted = false
  partyPassword = null
  partyOptions = null

  constructor() {
    makeAutoObservable(this)
  }

  persist(store) {
    this.store = store
    const partyId = this.store.getItem(PARTY_ID_KEY)
    const isHosted = this.store.getItem(PARTY_ISHOSTED_KEY)
    const partyPassword = this.store.getItem(PARTY_PASSWORD_KEY)
    const partyOptions = this.store.getItem(PARTY_OPTIONS_KEY)
    if (partyId) {
      this.partyId = partyId
      this.isHosted = isHosted
      this.partyPassword = partyPassword
      this.partyOptions = {
        ...PARTY_OPTIONS_DEFAULT,
        ...partyOptions,
      }
    }
    this.persistDisposers = [
      reaction(
        () => toJS(this.isJoined),
        value => {
          this.store.setItem(PARTY_ISJOINED_KEY, value)
        }
      ),
      reaction(
        () => toJS(this.isHosted),
        value => {
          this.store.setItem(PARTY_ISHOSTED_KEY, value)
        }
      ),
      reaction(
        () => toJS(this.partyOptions),
        value => {
          this.store.setItem(PARTY_OPTIONS_KEY, value)
        }
      ),
    ]
    return this
  }

  get isJoined() {
    return this.partyId !== null
  }

  host(partyId, partyPassword, partyOptions) {
    this.isHosted = true
    this.partyId = partyId
    this.partyPassword = partyPassword
    this.partyOptions = partyOptions
    if (this.store) {
      this.store.setItem(PARTY_ID_KEY, partyId)
      this.store.setItem(PARTY_PASSWORD_KEY, partyPassword)
      this.store.setItem(PARTY_OPTIONS_KEY, partyOptions)
    }
    return this
  }

  join(partyId, partyPassword) {
    this.isHosted = false
    this.partyId = partyId
    this.partyPassword = partyPassword
    if (this.store) {
      this.store.setItem(PARTY_ID_KEY, partyId)
      this.store.setItem(PARTY_PASSWORD_KEY, partyPassword)
    }
    return this
  }

  leave() {
    this.isHosted = false
    this.partyId = null
    this.partyPassword = null
    this.partyOptions = null
    if (this.store) {
      this.store.removeItem(PARTY_ID_KEY)
      this.store.removeItem(PARTY_PASSWORD_KEY)
      this.store.removeItem(PARTY_OPTIONS_KEY)
    }
    return this
  }

  getOption(setting) {
    return this.partyOptions && setting in this.partyOptions ? this.partyOptions[setting] : null
  }

  setOptions(options) {
    const { partyGuestLimit, trackMaxLength, allowTrackDuplicates } = options
    this.partyOptions = {
      ...PARTY_OPTIONS_DEFAULT,
      partyGuestLimit,
      trackMaxLength,
      allowTrackDuplicates,
    }
    return this
  }
}
