import logger from 'services/logger'
import SpotifyWebDevice from './spotifyWebDevice'
import SpotifyOtherDevice from './spotifyOtherDevice'

const SPOTIFY_DEVICE_CATEGORY_KEY = 'spotifyDevice.category'
const SPOTIFY_DEVICE_ID_KEY = 'spotifyDevice.id'

const deviceCategories = {
  web: SpotifyWebDevice,
  other: SpotifyOtherDevice,
}

class SpotifyDeviceHandler {
  constructor(config, store, browser, spotify, auth) {
    this.config = config
    this.store = store
    this.browser = browser
    this.spotify = spotify
    this.auth = auth
  }

  setAuth(auth) {
    this.auth = auth
    return this
  }

  async create(category, deviceId) {
    let selectedCategory = category
    if (category && category in deviceCategories) {
      selectedCategory = category
    } else if (category === null || category === undefined) {
      // Try default device
      // for (const tryCategory of Object.keys(deviceCategories)) {
      //   if (deviceCategories[tryCategory].isAvailable(this.browser)) {
      //     selectedCategory = tryCategory
      //     break;
      //   }
      // }
    }
    if (selectedCategory) {
      this.instance = new deviceCategories[selectedCategory](this.config, this.browser, this.spotify)
      await this.instance.initialize(deviceId, this.auth)
      this.store.setItem(SPOTIFY_DEVICE_CATEGORY_KEY, category)
      this.store.setItem(SPOTIFY_DEVICE_ID_KEY, deviceId)
    }
    return this.instance
  }

  async restore() {
    const category = this.store.getItem(SPOTIFY_DEVICE_CATEGORY_KEY)
    const deviceId = this.store.getItem(SPOTIFY_DEVICE_ID_KEY)
    const availableDevices = await this.list()
    let device = null
    if (availableDevices.find(availableDevice => availableDevice.id === deviceId)) {
      device = await this.create(category, deviceId)
    }
    logger.log('SpotifyDeviceHandler.restore', { category, deviceId, device, devices: availableDevices })
    return device
  }

  list() {
    return this.spotify.getDevices().then(spotifyDevices => {
      let webPlayerFound = false
      let devices = spotifyDevices.map(device => {
        const { id, name, type, is_active } = device
        if (device.name === this.config.spotify.webPlayerName) {
          webPlayerFound = true
        }
        return {
          id,
          name,
          type,
          isActive: is_active,
        }
      })
      // Add web player device if not listed
      if (!webPlayerFound && deviceCategories.web.isAvailable(this.browser)) {
        devices.unshift({
          id: '',
          name: this.config.spotify.webPlayerName,
          type: 'web',
          isActive: false,
        })
      }
      return devices
    })
  }
}

export default SpotifyDeviceHandler
