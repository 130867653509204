import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { popupSizes, trackFilterReasons } from 'config/enums'
import { lockMainScrolling, unlockMainScrolling } from 'functions/mainScrollingElement'
import { withDependencies, dependencies } from 'components/Context'
import Page from 'components/common/Page'
import PlaylistToolbar from 'components/common/PlaylistToolbar'
import { TrackList } from 'components/common/PlaylistDisplay'
import TrackPopup from 'components/common/popups/TrackPopup'
import MusicSearchPopup from 'components/common/popups/MusicSearchPopup'
import PlaylistImportPopup from 'components/common/popups/PlaylistImportPopup'
import { PlaylistEmptyBackdrop } from 'components/common/PageBackdrops'
import {
  CheckIcon,
  CloudDownloadIcon,
  FilterIcon,
  PlaylistAddIcon,
  PlaylistEditIcon,
  SearchIcon,
} from 'components/ui/icons'

const Playlist = props => {
  const { analytics, app, auth, locale, party, playlist, popup, tracksFiltered } = props
  const [isEditing, setIsEditing] = useState(false)
  const [trackMetaData, setTrackMetaData] = useState({})

  useEffect(() => {
    return reaction(getPlaylistMetadata, newTrackMetaData => setTrackMetaData(newTrackMetaData), {
      context: this,
      fireImmediately: true,
      delay: 500,
    })
  }, [])

  const toggleEditing = useCallback((newEditing, selectTrackId) => {
    setIsEditing(newEditing === undefined ? !isEditing : newEditing)
    if (isEditing) {
      lockMainScrolling()
      analytics.events.editPlaylist('toggle')
    } else {
      unlockMainScrolling()
    }
    if (selectTrackId) {
      playlist.unselect().select(selectTrackId)
    }
  })

  const reorderPlaylist = ({ oldIndex, newIndex }) => {
    const trackId = playlist.getTrackAtIndex(oldIndex).trackId
    playlist.reorder(trackId, newIndex)
    analytics.events.editPlaylist('reorder')
  }

  const showTrackPopup = useCallback(
    track => {
      popup.show({
        component: (
          <TrackPopup
            track={track}
            trackFiltered={tracksFiltered.get(track.trackId)}
            isOwnPlaylist={true}
            isHost={party.isHosted}
            playlist={playlist}
          />
        ),
      })
      analytics.events.showPopup('track')
    },
    [analytics, party, playlist, popup, tracksFiltered]
  )

  const showSearch = () => {
    popup.show({
      component: <MusicSearchPopup />,
      size: popupSizes.tall,
    })
    analytics.events.showPopup('music_search')
  }

  const showImport = () => {
    popup.show({
      component: <PlaylistImportPopup />,
      size: popupSizes.tall,
    })
    analytics.events.showPopup('music_import')
  }

  const getPlaylistMetadata = () => {
    const metadata = {}
    playlist.list.forEach(track => {
      const trackMetadata = {
        classNames: [],
        icons: [],
      }
      if (tracksFiltered.has(track.trackId)) {
        switch (tracksFiltered.get(track.trackId)) {
          // Track played info
          case trackFilterReasons.played:
            trackMetadata.classNames.push('u-opacity-50')
            trackMetadata.icons.push(<CheckIcon key="played" className="Icon Icon--primary" />)
            break
          default:
            // Track filtered warning
            trackMetadata.icons.push(<FilterIcon key="filtered" className="Icon Icon--secondary Icon--badge" />)
            break
        }
      }
      metadata[track.trackId] = trackMetadata
    })
    return metadata
  }

  const handleTrackLongPress = useCallback(
    track => {
      toggleEditing(true, track.trackId)
    },
    [toggleEditing]
  )

  const Toolbar = useMemo(() => <PlaylistToolbar visible={isEditing} toggleVisible={toggleEditing} />, [
    isEditing,
    toggleEditing,
  ])

  return (
    <Page contentClassName="l-columns" toolbar={Toolbar} data-test="Page_playlist">
      <div className="l-flex l-columns l-position__relative l-width--100%">
        {!isEditing && (
          <Fragment>
            <div
              className={classNames(
                'animated animated--zoomIn animated--pauseOnFocus button button-group-action__trigger button-action button--secondary',
                app.showPlayerControls && 'button-action--withControls'
              )}
              tabIndex={1}
              data-test="PlaylistActionButton"
            >
              <PlaylistAddIcon className="Icon" />
            </div>
            <div
              className={classNames(
                'button-group-action',
                app.showPlayerControls && 'button-group-action__withControls'
              )}
            >
              <button
                className="button-action button-action--labeled button--secondary"
                data-button-label={locale.translate('general.search_music')}
                onMouseDown={showSearch}
                data-test="PlaylistActionButton_search"
              >
                <SearchIcon className="Icon" />
              </button>
              {auth && auth.canImportExportPlaylist && (
                <button
                  className="button-action button-action--labeled button--secondary"
                  data-button-label={locale.translate('label.import_from_playlist')}
                  onMouseDown={showImport}
                >
                  <CloudDownloadIcon className="Icon" />
                </button>
              )}
              <button
                className="button-action button-action--labeled button--secondary"
                data-button-label={locale.translate('general.edit_playlist')}
                onMouseDown={() => toggleEditing()}
              >
                <PlaylistEditIcon className="Icon" />
              </button>
            </div>
          </Fragment>
        )}

        {playlist.length > 0 ? (
          <TrackList
            hasControls={isEditing}
            hasRightIcon={false}
            metadata={trackMetaData}
            onClick={isEditing ? null : showTrackPopup}
            onLongPress={isEditing ? null : handleTrackLongPress}
            onSortEnd={isEditing ? reorderPlaylist : null}
            playlist={playlist}
          />
        ) : (
          <PlaylistEmptyBackdrop onClick={showSearch} />
        )}
      </div>
    </Page>
  )
}

export default withDependencies(
  dependencies.analytics,
  dependencies.app,
  dependencies.auth,
  dependencies.locale,
  dependencies.party,
  dependencies.playlist,
  dependencies.popup,
  dependencies.tracksFiltered
)(observer(Playlist))
