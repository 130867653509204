import Axios from 'axios'

function handleError(axiosError) {
  if (Axios.isCancel(axiosError)) {
    return 'cancelled'
  }
  throw axiosError?.response?.data?.event || 'unknownError'
}

export { handleError }
