import React, { Fragment, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { alerts } from 'config/enums'
import { withDependencies, dependencies } from 'components/Context'
import Loader from 'components/common/Loader'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import TextInput from 'components/ui/TextInput'
import logger from 'services/logger'
import { Field, Form } from 'react-final-form'

const PlaylistExportPopup = props => {
  const { alert, historyPlaylist, locale, masterPlaylist, party, popup, spotify, userList } = props

  const initialValues = useMemo(
    () => ({
      name: locale.translate('label.exported_playlist_default_name', {
        party_id: party.partyId,
      }),
      description: locale.translate('label.exported_playlist_default_description', {
        appUrl: '{app.url}',
        time: new Intl.DateTimeFormat(navigator.languages[0]).format(new Date()),
        users: Array.from(userList.values())
          .map(user => user.name || user.userId)
          .join(', '),
      }),
    }),
    []
  )

  const validate = useCallback(({ name }) => {
    const errors = {}
    if (!name) errors.name = 'nameExpectedError'
    return errors
  }, [])

  const onExportPlaylist = values => {
    spotify
      .createPlaylist(values.name, values.description)
      .then(({ id }) => {
        const tracks = [...historyPlaylist.toJS, ...masterPlaylist.toJS]
        return spotify.addTracksToPlaylist(id, tracks)
      })
      .then(() => {
        popup.hide()
        alert.show('alert.playlistExported')
      })
      .catch(error => {
        logger.error('PlaylistExportPopup.handleExportPlaylist error', error)
        alert.show(alerts.error, 'alert.playlistExportError')
      })
  }

  return (
    <PopupContainer>
      <Form
        onSubmit={onExportPlaylist}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitting, valid }) => (
          <Fragment>
            <PopupHeader onHide={popup.hide}>
              <Text className="l-margins--h">general.export_playlist</Text>
            </PopupHeader>
            <PopupContent className="l-columns">
              {submitting ? (
                <div className="l-flex l-flex--centered-child">
                  <div>
                    <Loader />
                  </div>
                </div>
              ) : (
                <form className="l-margins" onSubmit={handleSubmit}>
                  <Field
                    name="name"
                    render={({ input, meta }) => (
                      <TextInput
                        containerClassName="l-margin--b"
                        label={<Text>general.playlist_name</Text>}
                        {...input}
                        autoFocus={true}
                        tabIndex={1}
                        error={meta.dirty && meta.invalid ? `validation.${meta.error}` : null}
                      />
                    )}
                  />
                  <Field
                    name="description"
                    render={({ input, meta }) => (
                      <TextInput
                        label={<Text>general.playlist_description</Text>}
                        type="textarea"
                        {...input}
                        rows={2}
                        cols={40}
                        tabIndex={2}
                        error={meta.dirty && meta.invalid ? `validation.${meta.error}` : null}
                      />
                    )}
                  />
                </form>
              )}
            </PopupContent>
            <PopupFooter className="l-columns">
              <button
                className="button--hollow button--primary l-flex l-flex--b50%"
                tabIndex={3}
                disabled={!valid || submitting}
                onClick={handleSubmit}
              >
                <Text>general.export</Text>
              </button>
              <button
                className="button--hollow button--secondary l-flex l-flex--b50%"
                tabIndex={4}
                disabled={submitting}
                onClick={popup.hide}
              >
                <Text>general.close</Text>
              </button>
            </PopupFooter>
          </Fragment>
        )}
      />
    </PopupContainer>
  )
}

export default withDependencies(
  dependencies.alert,
  dependencies.app,
  dependencies.historyPlaylist,
  dependencies.locale,
  dependencies.masterPlaylist,
  dependencies.party,
  dependencies.playlist,
  dependencies.popup,
  dependencies.spotify,
  dependencies.userList
)(observer(PlaylistExportPopup))
