import React, { Fragment, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { withRouter } from 'react-router-dom'
import { Field, Form } from 'react-final-form'

import { alerts } from 'config/enums'
import { isPlaysomeUserId } from 'functions/validation'
import { withDependencies, dependencies } from 'components/Context'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import TextInput from 'components/ui/TextInput'

const LAST_USER_ID_KEY = 'user.lastUserId'

const PlaysomeLoginPopup = props => {
  const { app, config, locale, popup, store, history } = props
  const providerId = 'playsome'

  const initialValues = useMemo(
    () => ({
      userId: store.getItem(LAST_USER_ID_KEY) || '',
    }),
    []
  )

  const validate = useCallback(({ userId }) => {
    const errors = {}
    if (!userId || !isPlaysomeUserId(userId)) errors.userId = 'playsomeUserIdExpectedError'
    return errors
  }, [])

  const onSubmit = ({ userId }) => {
    app
      .authorize(providerId, { userId })
      .then(() => {
        store.setItem(LAST_USER_ID_KEY, userId)
      })
      .then(() => {
        popup.hide()
      })
      .then(() => app.initialize())
      .then(() => history.push(config.routes.loggedIn))
      .catch(error => {
        alert.show(alerts.error, locale.translate('alert.' + error))
        history.push(config.routes.logIn)
      })
  }

  return (
    <PopupContainer data-test="playsomeLoginPopup">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{ visited: true }}
        validate={validate}
        render={({ handleSubmit, submitting, valid }) => (
          <Fragment>
            <PopupHeader onHide={popup.hide}>
              <Text className="l-margins--h">authProvider.loginLabel.playsome</Text>
            </PopupHeader>
            <PopupContent scrolling>
              <form className="l-rows l-margins" onSubmit={handleSubmit}>
                <div className="l-flex">
                  <p className="l-margin--t0">
                    <Text className="t-small">label.login_anonymously_nickname_info</Text>
                  </p>
                  <Field
                    name="userId"
                    render={({ input, meta }) => (
                      <TextInput
                        label={<Text>general.nickname</Text>}
                        {...input}
                        autoFocus={true}
                        tabIndex={1}
                        disabled={submitting}
                        error={meta.invalid ? `validation.${meta.error}` : null}
                      />
                    )}
                  />
                </div>
              </form>
            </PopupContent>
            <PopupFooter className="l-columns">
              <button
                type="submit"
                className="button--hollow button--primary l-flex"
                disabled={!valid || submitting}
                tabIndex={2}
                data-test="playsomeLoginButton"
              >
                <Text>general.log_in</Text>
              </button>
              <button
                className="button--hollow button--secondary l-flex"
                tabIndex={3}
                onClick={popup.hide}
                disabled={submitting}
              >
                <Text>general.cancel</Text>
              </button>
            </PopupFooter>
          </Fragment>
        )}
      />
    </PopupContainer>
  )
}

const playsome = withRouter(
  withDependencies(
    dependencies.app,
    dependencies.auth,
    dependencies.config,
    dependencies.locale,
    dependencies.popup,
    dependencies.store
  )(observer(PlaysomeLoginPopup))
)

export { playsome }
