function initials(string, count = 2) {
  let parts = string.trim().split(/[\s-_']+/g)
  if (parts.length > 1) {
    parts = parts.slice(0, count).map(part => part.substr(0, 1))
  } else {
    parts = parts.map(part => part.substring(0, count))
  }
  return parts.join('')
}

function cutoff(string, limit, suffix, atWordBreak) {
  let [str, lim, suf, atWord] = [string.toString(), parseInt(limit, 10), suffix.toString(), atWordBreak === 'true']
  return str.length <= lim ? str : str.substr(0, atWord ? str.substr(0, lim).lastIndexOf(' ') : lim) + suf
}

function wrapWith(prefix, suffix) {
  return text => prefix + text + (suffix === undefined ? prefix : suffix)
}

const lowercase = text => text.toLocaleLowerCase()

const sentenceCase = text => {
  return text
    .split('. ')
    .map(sentence => sentence.substr(0, 1).toLocaleUpperCase() + sentence.substr(1))
    .join('. ')
}

// Replace whitespace with non breaking space
function nonBreakingSpaces(string) {
  return string.toString().replace(/\s/g, '\u00a0')
}

// Double newlines to paragraphs, single newlines to br
function textToHtml(text) {
  return '<p>' + text.replace('\n\n', '</p><p>').replace('\n', '<br />') + '</p>'
}

/**
 * Reduces a string to an integer by summing character codepoints
 *
 * @param {string} str
 * @param {number} [mod]
 * @param {number} [codePointOffset]
 * @returns {number}
 */
function stringToNumber(str, mod = 1, codePointOffset = 96) {
  let n = 0
  for (let pos = 0; pos < str.length; pos++) {
    if (pos % 2 === 0) {
      n += str.codePointAt(pos) - codePointOffset
    } else {
      n *= str.codePointAt(pos) - codePointOffset
    }
  }
  return n % mod
}

function stringToHSL(str, saturation = 100, lightness = 50, alpha = 1) {
  let hue = 0
  if (typeof str === 'string') {
    hue = str.split('').reduce((sum, char) => sum + char.codePointAt(0), 0) % 360
  }
  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`
}

function duration(milliseconds) {
  let remaining = milliseconds
  let duration = []
  // Hours
  if (remaining >= 3600000) {
    duration.push(Math.floor(remaining / 3600000))
    remaining %= 3600000
  }
  // Minutes
  if (remaining >= 60000) {
    duration.push(Math.floor(remaining / 60000))
    remaining %= 60000
  } else {
    duration.push(0)
  }
  // Seconds
  duration.push(Math.floor(remaining / 1000))
  return duration.map(d => (d < 10 ? '0' + d : '' + d)).join(':')
}

function fixedLengthNumber(num, precision = 3) {
  if (!Number.isFinite(num) || precision <= 0) return ''
  if (num.toString().length <= precision) return num
  const SUFFIXES = ['', 'k', 'M', 'G']
  let power = Math.floor(Math.log10(num) / 3)
  return Number(num / Math.pow(10, power * 3)).toPrecision(precision) + SUFFIXES[power]
}

export {
  cutoff,
  duration,
  fixedLengthNumber,
  initials,
  lowercase,
  nonBreakingSpaces,
  sentenceCase,
  stringToHSL,
  stringToNumber,
  textToHtml,
  wrapWith,
}
