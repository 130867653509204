import { toJS } from 'mobx'
import Axios from 'axios'

import logger from 'services/logger'

export default class Spotify {
  static providerId = 'spotify'
  static searchTypes = ['album', 'artist', 'track']

  auth
  providerUserId
  userId
  settings = {
    playlistsCacheTime: 60000,
  }

  constructor(config, store, cache) {
    this.config = config
    this.spotifyConfig = this.config.spotify
    this.store = store
    this.cache = cache

    this.axios = Axios.create()
    this.axios.interceptors.response.use(
      response => response,
      error => {
        logger.log('Spotify.Axios.intercept', error.request.status, error)
        // Intercept errors
        if (error.request) {
          switch (error.request.status) {
            // 401 Unauthorized, attempt to refresh token and retry original request
            case 401:
              return this.auth
                .refresh()
                .then(() => {
                  return Axios({
                    ...error.config,
                    headers: this.getRestAuthHeaders(error.config.headers),
                  })
                })
                .catch(newError => {
                  logger.log('Spotify.Axios.intercept error', newError)
                  return Promise.reject('tokenExpiredError')
                })
          }
        }
      }
    )
  }

  setAuth(auth) {
    // Use existing Spotify auth
    if (auth.providerId === Spotify.providerId && auth.isLoggedIn) {
      const { providerUserId, userId } = auth.getIdentity()
      this.auth = auth
      this.setUser(providerUserId, userId)
      // Set up basic Spotify auth
    } else {
      this.auth = new BasicSpotifyAuth(this.config)
      this.auth.authorize()
    }
    return this
  }

  clearAuth() {
    this.auth = null
    return this
  }

  setUser(providerUserId, userId) {
    this.providerUserId = providerUserId
    this.userId = userId
    return this
  }

  clearUser() {
    this.providerUserId = null
    this.userId = null
    return this
  }

  getRestAuthHeaders(headers = {}) {
    if (this.auth) {
      const { access_token } = this.auth.getAuthData()
      headers['Authorization'] = 'Bearer ' + access_token
    }
    return headers
  }

  getDevices() {
    return this.axios
      .get(this.spotifyConfig.url.devices, {
        headers: this.getRestAuthHeaders(),
      })
      .then(response => response.data)
      .then(data => {
        logger.log('spotify.getDevices', data.devices)
        return data.devices
      })
  }

  getCurrentlyPlaying() {
    return this.axios
      .get(this.spotifyConfig.url.player, {
        headers: this.getRestAuthHeaders(),
      })
      .then(response => response && response.data)
  }

  generateTrackId(providerTrackId) {
    return this.userId + ':' + 'spotify' + ':' + providerTrackId + ':' + Date.now()
  }

  mapTracks(providerTrackList) {
    return (
      providerTrackList
        // Filter out local tracks without provider id
        .filter(track => !!track.id)
        .map(track => {
          return {
            providerTrackId: track.id,
            providerId: 'spotify',
            trackId: this.generateTrackId(track.id),
            uri: track.uri,
            name: track.name,
            duration: track['duration_ms'],
            album: {
              id: track.album.id,
              uri: track.album.uri,
              name: track.album.name,
              thumbUrl: track.album.images[2] ? track.album.images[2].url : null,
              imageUrl: track.album.images[1] ? track.album.images[1].url : null,
            },
            artists: track.artists.map(artist => {
              const { id, uri, name } = artist
              return { id, uri, name }
            }),
            userId: this.userId,
          }
        })
    )
  }

  search(query, type, limit = 20, offset = 0) {
    if (type && Spotify.searchTypes.indexOf(type) > -1) {
      query = type + ':' + query
    }
    return this.axios
      .get(this.spotifyConfig.url.search, {
        headers: this.getRestAuthHeaders(),
        params: {
          q: query,
          type: 'track',
          limit,
          offset,
        },
      })
      .then(response => {
        return response.data
      })
      .then(data => {
        const {
          tracks: { items, limit, offset, total },
        } = data
        return {
          tracks: items ? this.mapTracks(items) : [],
          limit,
          offset,
          total,
        }
      })
      .catch(error => {
        logger.error(error)
      })
  }

  getPlaylists(offset = 0, limit = 50) {
    const cacheKey = `playlists:${limit}:${offset}`
    const cached = this.cache.get(cacheKey)
    if (cached) return Promise.resolve(cached)
    return this.axios
      .get(this.spotifyConfig.url.playlists, {
        headers: this.getRestAuthHeaders(),
        params: {
          limit,
          offset,
        },
      })
      .then(response => response.data)
      .then(data => {
        const result = {
          total: data.total,
          hasMoreTracks: !!data.next,
          items: data.items
            ? data.items.map(playlist => {
                return {
                  providerPlaylistId: playlist.id,
                  ownerId: playlist.owner.id,
                  name: playlist.name,
                  trackCount: playlist.tracks.total,
                  thumbUrl: playlist.images.length > 0 ? playlist.images.pop().url : null,
                }
              })
            : [],
        }
        this.cache.put(cacheKey, this.settings.playlistsCacheTime, result)
        return result
      })
  }

  getPlaylistTracks(providerPlaylistId, playlistOwnerId) {
    let url = this.spotifyConfig.url.playlistTracks
      .replace('{user_id}', encodeURIComponent(playlistOwnerId === undefined ? this.providerUserId : playlistOwnerId))
      .replace('{playlist_id}', encodeURIComponent(providerPlaylistId))
    return this.axios
      .get(url, {
        headers: this.getRestAuthHeaders(),
      })
      .then(response => response.data)
      .then(data => {
        return this.mapTracks(data.items.map(item => item.track))
      })
  }

  createPlaylist(name, description) {
    const url = this.spotifyConfig.url.createPlaylist.replace('{user_id}', encodeURIComponent(this.providerUserId))
    return this.axios
      .post(
        url,
        {
          name,
          description,
        },
        {
          headers: this.getRestAuthHeaders(),
        }
      )
      .then(response => response.data)
  }

  addTracksToPlaylist(playlistId, tracks) {
    const url = this.spotifyConfig.url.addTracksToPlaylist.replace('{playlist_id}', encodeURIComponent(playlistId))
    return this.axios
      .post(
        url,
        {
          uris: tracks.map(track => 'spotify:track:' + track.providerTrackId),
        },
        {
          headers: this.getRestAuthHeaders(),
        }
      )
      .then(response => response.data)
  }

  setVolume(device_id, volume_percent) {
    return this.axios(this.spotifyConfig.url.setVolume, {
      method: 'PUT',
      headers: this.getRestAuthHeaders(),
      params: {
        device_id,
        volume_percent,
      },
    }).then(response => response.data)
  }

  playTrack(device_id, track) {
    let data = {}
    // Playing without specifying track resumes current track
    if (track) {
      data = {
        uris: [track.uri],
      }
    }
    logger.log('spotify.playTrack', toJS(track), data, this.spotifyConfig.url.playTrack)
    return this.axios(this.spotifyConfig.url.playTrack, {
      method: 'PUT',
      headers: this.getRestAuthHeaders(),
      params: {
        device_id,
      },
      data,
    }).then(response => response && response.data)
  }

  pauseTrack(device_id) {
    return this.axios(this.spotifyConfig.url.pauseTrack, {
      method: 'PUT',
      headers: this.getRestAuthHeaders(),
      params: {
        device_id,
      },
    }).then(response => response.data)
  }

  seekTrack(position_ms) {
    return this.axios(this.spotifyConfig.url.seekTrack, {
      method: 'PUT',
      headers: this.getRestAuthHeaders(),
      params: {
        position_ms,
      },
    })
  }

  transferDevice(device_id, play = true) {
    logger.log('Spotify.transferDevice', device_id, play)
    return this.axios(this.spotifyConfig.url.player, {
      method: 'PUT',
      headers: this.getRestAuthHeaders(),
      data: {
        device_ids: [device_id],
        play,
      },
    })
  }
}

class BasicSpotifyAuth {
  authData

  constructor(config) {
    this.config = config
  }

  getAuthData() {
    return this.authData || null
  }

  authorize() {
    return Axios.post(this.config.auth.providers.spotify.url.auth)
      .then(response => response.data.data)
      .then(data => {
        if (data.auth && 'access_token' in data.auth) {
          this.authData = data.auth
          return this.auth
        } else {
          return Promise.reject('spotifyBasicAuthError')
        }
      })
  }

  refresh() {
    return this.authorize()
  }
}
