export default class Share {
  constructor(config, browser) {
    this.config = config
    this.browser = browser
    this.platform = this.browser.isMobile ? 'mobile' : 'desktop'
  }

  getProviders() {
    return (
      Object.entries(this.config.share.providers)
        // eslint-disable-next-line no-unused-vars
        .filter(([_, providerData]) => this.platform in providerData && providerData[this.platform])
        .map(([providerId]) => providerId)
    )
  }

  share(providerId, url = '', message = '', title = '') {
    if (providerId in this.config.share.providers) {
      if (this.platform in this.config.share.providers[providerId]) {
        let shareUrl
        if (typeof this.config.share.providers[providerId][this.platform].url === 'function') {
          shareUrl = this.config.share.providers[providerId][this.platform].url(url, message, title)
        } else {
          shareUrl = this.config.share.providers[providerId][this.platform].url
            .replace('{url}', encodeURIComponent(url))
            .replace('{message}', encodeURIComponent(message))
            .replace('{title}', encodeURIComponent(title))
        }
        switch (this.config.share.providers[providerId][this.platform].mode) {
          case 'popup':
            window.open(this.config.share.url + '?url=' + encodeURIComponent(shareUrl), 'playsome_share')
            break
          case 'link':
          default:
            window.open(shareUrl, '_blank')
            break
        }
        return true
      }
    }
    return false
  }

  shareNative(url, message, title) {
    if (this.browser.hasShare) {
      return window.navigator.share({
        url,
        message,
        title,
      })
    } else {
      throw new Error('nativeShareMissingError')
    }
  }
}
