import { useState, useEffect } from 'react'

function useBackgroundImageLoader(src) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    const image = new Image()
    const handleLoaded = () => {
      setIsLoaded(true)
      setError(false)
    }
    const handleError = () => {
      setIsLoaded(false)
      setError(true)
    }
    image.addEventListener('load', handleLoaded)
    image.addEventListener('error', handleError)
    image.src = src
    return () => {
      image.removeEventListener('load', handleLoaded)
      image.removeEventListener('error', handleError)
    }
  }, [src])
  return [isLoaded, error]
}

export default useBackgroundImageLoader
