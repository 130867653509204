import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { DependencyContext } from 'components/Context'

const Text = props => {
  const { children, id, className, style, filter, html, wrap, ...params } = props
  const { locale } = useContext(DependencyContext)
  let textProps
  // Translate
  const key = children.toString()
  let translation = locale.translate(key, params)
  // Filter translation if filters provided
  if (filter) {
    if (Array.isArray(filter)) {
      translation = filter.reduce((trans, fn) => fn(trans), translation)
    } else {
      translation = filter(translation)
    }
  }
  // Pass on props to text node
  textProps = {
    id,
    className,
    style,
  }
  // If html output set, add html prop
  if (html) {
    textProps.dangerouslySetInnerHTML = {
      __html: translation,
    }
  }

  return React.createElement(wrap, textProps, html ? null : translation)
}

Text.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  filter: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  html: PropTypes.bool,
  id: PropTypes.string,
  style: PropTypes.object,
  wrap: PropTypes.string,
}
Text.defaultProps = {
  html: false,
  wrap: 'span',
}

export default Text
