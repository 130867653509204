import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Axios from 'axios'

const HtmlLoader = props => {
  const { path, onMount, transformHTML, transformDOM, LoaderComponent, ErrorComponent, ...otherProps } = props
  const [html, setHtml] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const htmlElementRef = useRef()

  useEffect(() => {
    const url = path.startsWith('/') ? path : '/' + path
    setIsLoading(true)
    Axios.get(url)
      .then(result => result.data)
      .then(loadedHtml => (typeof transformHTML === 'function' ? transformHTML(loadedHtml) : loadedHtml))
      .then(loadedHtml => {
        setHtml(loadedHtml)
        setIsLoading(false)
        setError(false)
        return loadedHtml
      })
      .catch(() => {
        setHtml(null)
        setIsLoading(false)
        setError(true)
      })
  }, [path, transformHTML])

  useEffect(() => {
    if (typeof transformDOM === 'function' && htmlElementRef.current) transformDOM(htmlElementRef.current)
  }, [html, transformDOM])

  const onHtmlElementChange = useCallback(
    element => {
      htmlElementRef.current = element
      if (element !== null) {
        if (typeof onMount === 'function') onMount(element)
        if (typeof transformDOM === 'function') transformDOM(element)
      }
    },
    [onMount, transformDOM]
  )

  if (isLoading) {
    return <LoaderComponent />
  } else if (error) {
    return <ErrorComponent />
  } else if (html) {
    return <div dangerouslySetInnerHTML={{ __html: html }} ref={onHtmlElementChange} {...otherProps} />
  } else {
    return null
  }
}

HtmlLoader.propTypes = {
  path: PropTypes.string.isRequired,
  onMount: PropTypes.func,
  transformHTML: PropTypes.func,
  transformDOM: PropTypes.func,
  LoaderComponent: PropTypes.func,
  ErrorComponent: PropTypes.func,
}

export default HtmlLoader
