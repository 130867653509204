import React, { Fragment, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { withRouter } from 'react-router-dom'
import { Field, Form } from 'react-final-form'

import { alertLevels } from 'config/enums'
import { textToHtml } from 'functions/format'
import { isEmail } from 'functions/validation'
import { withDependencies, dependencies } from 'components/Context'
import { PopupContainer, PopupHeader, PopupContent, PopupFooter } from 'components/ui/Popup'
import Text from 'components/ui/Text'
import TextInput from 'components/ui/TextInput'

const FeedbackPopup = props => {
  const { alert, apiClient, auth, config, locale, popup } = props

  const initialValues = useMemo(
    () => ({
      feedback: '',
      email: '',
    }),
    []
  )

  const validate = useCallback(({ feedback, email }) => {
    const errors = {}
    if (!feedback || (feedback && feedback.trim() === '')) errors.feedback = 'valueRequiredError'
    if (email && !isEmail(email)) errors.email = 'emailExpectedError'
    return errors
  }, [])

  const onSubmit = ({ feedback, email }) => {
    const [postFeedback, cancel] = apiClient.postFeedback.cancelable()
    postFeedback({
      feedback,
      email,
      playsomeToken: auth.playsomeToken,
      userId: auth.identity.userId,
      userAgent: navigator.userAgent,
      path: window.location.pathname,
      timestamp: Date.now(),
    })
      .then(() => {
        alert.show({
          level: alertLevels.INFO,
          message: locale.translate('alert.feedback_thanks'),
          timeout: config.alert.defaultTimeoutShort,
        })
        popup.hide()
      })
      .catch(error => {
        alert.show({
          level: alertLevels.ERROR,
          message: locale.translate('alert.' + error),
          timeout: config.alert.defaultTimeout,
        })
        popup.hide()
      })
    return cancel
  }

  return (
    <PopupContainer>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitting, valid }) => (
          <Fragment>
            <PopupHeader onHide={popup.hide}>
              <Text className="l-margins--h">general.feedback_and_bugs</Text>
            </PopupHeader>
            <PopupContent scrolling className="l-paddings--h">
              <p>
                <Text filter={textToHtml} html>
                  label.feedback_message
                </Text>
              </p>
              <p>
                <Text html>label.feedback_alternative</Text>
              </p>
              <form id="feedbackForm" onSubmit={handleSubmit}>
                <Field
                  name="feedback"
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      type="textarea"
                      className="l-height--16"
                      containerClassName="l-margin--b"
                      label={<Text>general.message</Text>}
                      tabIndex={1}
                      disabled={submitting}
                      error={(meta.dirty || meta.touched) && meta.invalid ? `validation.${meta.error}` : null}
                    />
                  )}
                />
                <Field
                  name="email"
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      type="text"
                      label={<Text>general.email_optional</Text>}
                      tabIndex={2}
                      disabled={submitting}
                      error={meta.visited && meta.invalid ? `validation.${meta.error}` : null}
                    />
                  )}
                />
              </form>
            </PopupContent>
            <PopupFooter className="l-columns">
              <button
                type="submit"
                form="feedbackForm"
                className="button--hollow button--primary l-flex"
                tabIndex={3}
                disabled={!valid || submitting}
              >
                <Text>general.submit</Text>
              </button>
              <button
                className="button--hollow button--secondary l-flex"
                tabIndex={4}
                disabled={submitting}
                onClick={popup.hide}
              >
                <Text>general.cancel</Text>
              </button>
            </PopupFooter>
          </Fragment>
        )}
      />
    </PopupContainer>
  )
}

export default withRouter(
  withDependencies(
    dependencies.alert,
    dependencies.apiClient,
    dependencies.auth,
    dependencies.config,
    dependencies.locale,
    dependencies.popup,
    dependencies.store
  )(observer(FeedbackPopup))
)
