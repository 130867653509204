import React from 'react'
import PropTypes from 'prop-types'

import PlaysomeIcon from 'assets/img/icons/playsome-user.svg?jsx'
import { FacebookIcon, GoogleIcon, SpotifyIcon } from 'components/ui/icons'
import * as providerColors from 'styles/export/providerColors'

const ProviderIcon = props => {
  const { providerId, colored, ...otherProps } = props
  const style = colored
    ? {
        fill: providerColors[providerId] || 'white',
      }
    : null
  switch (providerId) {
    case 'spotify':
      return <SpotifyIcon style={style} {...otherProps} />
    case 'google':
      return <GoogleIcon style={style} {...otherProps} />
    case 'facebook':
      return <FacebookIcon style={style} {...otherProps} />
    case 'playsome':
      return <PlaysomeIcon style={style} {...otherProps} />
    default:
      return null
  }
}
ProviderIcon.propTypes = {
  colored: PropTypes.bool,
  providerId: PropTypes.string.isRequired,
}
ProviderIcon.defaultProps = {
  colored: true,
}

export default ProviderIcon
