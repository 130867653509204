import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const PrettyUrl = props => {
  let { children: url, showProtocol, slashClassname, ...otherProps } = props
  if (!showProtocol) {
    url = url.replace(/^(http|https):\/\//i, '')
  }
  url = url.split(/\/+/)
  return (
    <span {...otherProps}>
      {url.map((part, index) => (
        <Fragment key={index}>
          <span>{part}</span>
          {index < url.length - 1 && <span className={slashClassname}>/</span>}
        </Fragment>
      ))}
    </span>
  )
}

PrettyUrl.propTypes = {
  children: PropTypes.string.isRequired,
  showProtocol: PropTypes.bool,
  slashClassname: PropTypes.string,
}
PrettyUrl.defaultProps = {
  showProtocol: false,
}

export default PrettyUrl
