import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { fixedLengthNumber } from 'functions/format'

const Badge = props => {
  const { value, wrap, primary, secondary, children } = props
  const valueDisplay = fixedLengthNumber(value, 3)
  const className = classNames(
    'Badge',
    Math.abs(value) > 99 ? 'Badge--sm' : null,
    primary ? 'Badge--primary' : null,
    secondary ? 'Badge--secondary' : null
  )
  return value
    ? React.createElement(
        wrap,
        {
          'data-value': valueDisplay,
          className,
        },
        children
      )
    : children
}
Badge.propTypes = {
  value: PropTypes.number,
  wrap: PropTypes.node,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  updated: PropTypes.bool,
  children: PropTypes.element,
}
Badge.defaultProps = {
  wrap: 'span',
  updated: true,
}

class AnimatedBadge extends React.Component {
  timeout

  static getDerivedStateFromProps(props, state) {
    return {
      value: props.value,
      lastValue: state.value,
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      lastValue: props.value,
    }
  }

  revertState = () => {
    const { value, lastValue } = this.state
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (lastValue !== value) {
      this.timeout = setTimeout(
        () =>
          this.setState(state => {
            return { lastValue: state.value }
          }),
        this.props.timeout
      )
    }
  }

  componentDidMount() {
    this.revertState()
  }

  componentDidUpdate() {
    this.revertState()
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    let { value, lastValue } = this.state
    let { ...otherProps } = this.props
    const primary = value > lastValue
    const secondary = value < lastValue
    return <Badge value={value} primary={primary} secondary={secondary} {...otherProps} />
  }
}
AnimatedBadge.propTypes = {
  ...Badge.propTypes,
  timeout: PropTypes.number,
}
AnimatedBadge.defaultProps = {
  ...Badge.defaultProps,
  timeout: 1000,
}

export { Badge, AnimatedBadge }
