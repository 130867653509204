class WakeLock {
  logger
  wakeLock

  constructor(logger) {
    this.logger = logger
  }

  get active() {
    return !!this.wakeLock
  }

  async enable() {
    try {
      if (!this.wakeLock) {
        this.wakeLock = await navigator.wakeLock.request('screen')
        this.wakeLock.addEventListener('release', this.onRelease)
        this.logger.log('WakeLock.enable')
      }
    } catch (error) {
      this.logger.error('WakeLock.enable.error', error)
      this.onRelease()
    }
    this.logger.log('WakeLock.enable', { wakeLock: this.wakeLock })
    return this
  }

  async release() {
    if (this.wakeLock) {
      await this.wakeLock.release()
    }
    this.logger.log('WakeLock.release', { wakeLock: this.wakeLock })
    return this
  }

  onRelease = () => {
    if (this.wakeLock) this.wakeLock.removeEventListener('release', this.onRelease)
    this.wakeLock = null
    this.logger.log('WakeLock.onRelease', { wakeLock: this.wakeLock })
    return this
  }
}

export default WakeLock
