import React from 'react'
import { observer } from 'mobx-react-lite'

import { withDependencies, dependencies } from 'components/Context'
import { PopupContainer, PopupContent } from 'components/ui/Popup'
import ProviderIcon from 'components/common/ProviderIcon'
import Text from 'components/ui/Text'

const LoginSelectPopup = props => {
  const { guestOnlyProviders, showAnonymousLogin, onLoginClick, config, popup } = props
  let authProviderIds = Array.from(Object.keys(config.auth.providers))
  if (guestOnlyProviders) {
    authProviderIds = authProviderIds.filter(providerId => providerId !== config.auth.hostProvider)
  }
  if (!showAnonymousLogin) {
    authProviderIds = authProviderIds.filter(providerId => providerId !== config.auth.anonymousGuestProvider)
  }

  const handleLoginClick = providerId => {
    popup.hide()
    onLoginClick(providerId)
  }

  return (
    <PopupContainer>
      <PopupContent scrolling={true} className="l-paddings--h" data-test="loginSelectPopup">
        {authProviderIds.map((providerId, index) => (
          <button
            key={'loginButton_' + providerId}
            onClick={e => {
              e.preventDefault()
              handleLoginClick(providerId)
            }}
            className="button--primary l-margins--v l-width--100% l-display--block"
            autoFocus={index === 0}
            data-test={`loginSelect_${providerId}`}
          >
            <Text>{`authProvider.title.${providerId}`}</Text>
            &nbsp;
            <ProviderIcon providerId={providerId} colored={false} className="Icon" />
          </button>
        ))}
      </PopupContent>
    </PopupContainer>
  )
}

export default withDependencies(dependencies.config, dependencies.popup)(observer(LoginSelectPopup))
